import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

const StyledSvg = styled.svg<{ useOutline?: boolean }>`
  ${({ useOutline }) =>
    useOutline &&
    `
	border: 1px solid black;
	`}
`;

interface Props {
  idx: number;
  fmt?: (i: number) => string;
  useOutline?: boolean;
}

function IndexIcon({ idx, fmt = i => String(i) + '.', ...rest }: Props) {
  const theme = useTheme();
  return (
    <StyledSvg height="24px" width="24px" viewBox="0 0 100 100" {...rest}>
      <text x="0" y="75" fontSize="64px" fontWeight="700" fontFamily={theme.fonts.code}>
        {fmt(idx)}
      </text>
    </StyledSvg>
  );
}

export default IndexIcon;
