import styled from '@emotion/styled';

import styles from './styles';

const StyledNavLabel = styled.p(styles.navLabel);

interface Props {
  children?: string;
}

function NavLabel({ children }: Props) {
  return <StyledNavLabel>{children}</StyledNavLabel>;
}

export default NavLabel;
