import { createContext, useContext, useMemo } from 'react';

import BbauthClient, { makeRefreshClient } from '@atlanta-hawks/bbauth-client-js';
import BbopsClient from '@atlanta-hawks/bbops-client-js';

interface Context {
  bbops: BbopsClient;
  bbauth: BbauthClient;
}

const context = createContext<Context>(null!);

interface Props {
  children?: React.ReactNode;
}

export default function ApiProvider({ children }: Props) {
  const bbauthClient = useMemo(() => new BbauthClient(process.env.REACT_APP_BBAUTH_BASE_URL || '', 'scouting-portal'), []);

  const bbopsClient = useMemo(
    () => new BbopsClient(`${process.env.REACT_APP_BBOPS_BASE_URL}/api`, makeRefreshClient(bbauthClient)),
    [bbauthClient],
  );

  return (
    <context.Provider
      value={{
        bbops: bbopsClient,
        bbauth: bbauthClient,
      }}
    >
      {children}
    </context.Provider>
  );
}

export function useApi() {
  return useContext(context);
}
