import React, { forwardRef, useId } from 'react';

import styled from '@emotion/styled';

const StyledLabel = styled.label<Pick<Props, 'tight'>>`
  font-family: ${({ theme }) => theme.fonts.body.shortForm};
  font-size: ${({ theme, tight }) => theme.fontSizes[tight ? 'base' : 'deka']};
  font-weight: 700;
  margin-bottom: ${({ tight }) => (tight ? '0' : '0.309rem')};
  margin-right: ${({ tight }) => (tight ? '0.5rem' : '0')};
`;

const StyledSelect = styled.select<Pick<Props, 'minWidth' | 'tight'>>`
  padding: ${({ tight }) => (tight ? '0.1rem 0.309rem' : '0.309rem')};
  width: ${({ minWidth }) => (minWidth ? 'unset' : '100%')};
  font-family: ${({ theme }) => theme.fonts.body.shortForm};
  font-size: ${({ theme, tight }) => theme.fontSizes[tight ? 'centi' : 'base']};
`;

interface Props {
  children?: React.ReactNode;
  disabled?: boolean;
  label?: React.ReactNode;
  value?: string;
  onBlur?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  placeholder?: string;
  tight?: boolean;
  minWidth?: boolean;
}

const Dropdown = forwardRef<HTMLSelectElement, Props>(({ children: childrenProp, label, placeholder, tight, ...rest }, ref) => {
  const id = useId();

  let children = React.Children.toArray(childrenProp);
  if (placeholder) {
    children = [
      <option key="" value="">
        {placeholder}
      </option>,
      ...children,
    ];
  }

  return (
    <>
      {label && (
        <StyledLabel htmlFor={id} tight={tight}>
          {label}
        </StyledLabel>
      )}
      <StyledSelect ref={ref} {...rest} tight={tight}>
        {children}
      </StyledSelect>
    </>
  );
});

export default Dropdown;
