import { useState } from 'react';
import Modal from 'react-modal';

import { scouting } from '@atlanta-hawks/bbops-client-js';
import styled from '@emotion/styled';
import { HawksError } from '@hawks-ui/errors';

import { Button } from '../../../components/buttons';
import { Subheading, Text } from '../../../components/typography';
import { useScouting } from '../../../contexts/scouting';

import Table from './manage_player_pool_table';
import { SpinnerButton } from '../../../components/spinner';

const StyledToolbar = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors.site.navbar.border};
  border-bottom: 1px solid ${({ theme }) => theme.colors.site.navbar.border};
`;

const StyledMeta = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-gap: 0.5rem;
  align-items: baseline;
`;

const StyledMetaLabel = styled(Text)`
  font-weight: 700;
  white-space: nowrap;
`;

const StyledButton = styled(Button)`
  margin-bottom: 0;
`;

const StyledRefreshTitle = styled(Subheading)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.site.navbar.border};
  margin-bottom: 1rem;
  padding-bottom: 1rem;
`;

const StyledRefreshLabel = styled(Text)<{ offset?: boolean }>`
  font-weight: 700;
  white-space: nowrap;
  ${({ offset }) => offset && { paddingTop: '0.5rem' }}
`;

const StyledRefreshResult = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  column-gap: 0.5rem;
`;

const StyledRefreshPlayers = styled.div`
  padding-top: 0.5rem;
  border-top: 1px solid ${({ theme }) => theme.colors.site.navbar.border};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 100%;
  max-height: 300px;
  overflow-x: auto;
`;

const StyledRefreshData = styled.pre`
  padding-top: 0.5rem;
  border-top: 1px solid ${({ theme }) => theme.colors.site.navbar.border};
  max-width: 100%;
  max-height: 300px;
  overflow-x: auto;
`;

const StyledRefreshPlayer = styled(Text)`
  margin-right: 0.5rem;
`;

const nameSort = (p1: scouting.RefreshPlayer, p2: scouting.RefreshPlayer): number => {
  const n1 = p1.name.toLowerCase();
  const n2 = p2.name.toLowerCase();
  if (n1 > n2) {
    return 1;
  } else if (n1 < n2) {
    return -1;
  }

  return p1.dxId - p2.dxId;
};

function ManagePlayerPool() {
  const { api, playersById, season } = useScouting();

  const [refreshResult, setRefreshResult] = useState<scouting.RefreshPlayersResult>();
  const [refreshErr, setRefreshErr] = useState<HawksError>();

  const counts = Object.values(playersById).reduce(
    (acc, player) => ({
      total: acc.total + (!player.ineligible ? 1 : 0),
      ineligible: acc.ineligible + (player.ineligible ? 1 : 0),
    }),
    { total: 0, ineligible: 0 },
  );

  if (!season) {
    return null;
  }

  return (
    <>
      <StyledToolbar>
        <StyledMeta>
          <StyledMetaLabel nomargin>Current season:</StyledMetaLabel>
          <Text nomargin>{season.seasonYear}</Text>
          <StyledMetaLabel nomargin>Total players:</StyledMetaLabel>
          <Text nomargin>{counts.total}</Text>
          <StyledMetaLabel nomargin>Ineligible players::</StyledMetaLabel>
          <Text nomargin>{counts.ineligible}</Text>
        </StyledMeta>
        <SpinnerButton
          onClick={() =>
            api
              .admin()
              .players()
              .refresh(true)
              .then(setRefreshResult)
              .catch(e => {
                console.log('an error occurred', e);
                setRefreshErr(HawksError.make(e));
              })
          }
        >
          <StyledButton shade="reds">Refresh player pool</StyledButton>
        </SpinnerButton>
      </StyledToolbar>
      <Table players={Object.values(playersById)} seasonYear={season.seasonYear} />
      <Modal
        isOpen={!!refreshResult || !!refreshErr}
        onRequestClose={() => {
          setRefreshResult(undefined);
          setRefreshErr(undefined);
        }}
      >
        <>
          <StyledRefreshTitle>
            {refreshResult ? 'Successfully refreshed player pool!' : 'Uh oh! An error occurred...'}
          </StyledRefreshTitle>
          <StyledRefreshResult>
            {refreshResult && (
              <>
                <StyledRefreshLabel>Scouting tiers:</StyledRefreshLabel>
                <Text>{refreshResult.playerPoolSize}</Text>
                <StyledRefreshLabel>Offline ranks:</StyledRefreshLabel>
                <Text>{refreshResult.offlineRankPlayers}</Text>
                <StyledRefreshLabel>Player pool:</StyledRefreshLabel>
                <Text>{refreshResult.draftTierPlayers}</Text>
                <StyledRefreshLabel offset>Ineligible players:</StyledRefreshLabel>
                <StyledRefreshPlayers>
                  {refreshResult.inelgiblePlayers.sort(nameSort).map(p => (
                    <StyledRefreshPlayer key={p.dxId}>{p.name}</StyledRefreshPlayer>
                  ))}
                </StyledRefreshPlayers>
                <StyledRefreshLabel offset>Ineligible offline ranks:</StyledRefreshLabel>
                <StyledRefreshPlayers>
                  {Object.entries(refreshResult.ineligibleOfflineRanks)
                    .map(([dxId, name]) => ({ dxId: parseInt(dxId, 10), name }))
                    .sort(nameSort)
                    .map(p => (
                      <StyledRefreshPlayer key={p.dxId}>{p.name}</StyledRefreshPlayer>
                    ))}
                </StyledRefreshPlayers>
                {!!refreshResult.unknownDraftTiers.length && (
                  <>
                    <StyledRefreshLabel offset>Missing offline ranks:</StyledRefreshLabel>
                    <StyledRefreshPlayers>
                      {refreshResult.unknownDraftTiers.sort(nameSort).map(p => (
                        <StyledRefreshPlayer key={p.dxId}>{p.name}</StyledRefreshPlayer>
                      ))}
                    </StyledRefreshPlayers>
                  </>
                )}
                {!!Object.keys(refreshResult.unknownOfflineRanks).length && (
                  <>
                    <StyledRefreshLabel offset>Missing offline ranks:</StyledRefreshLabel>
                    <StyledRefreshPlayers>
                      {Object.entries(refreshResult.unknownOfflineRanks)
                        .map(([dxId, name]) => ({ dxId: parseInt(dxId, 10), name }))
                        .sort(nameSort)
                        .map(p => (
                          <StyledRefreshPlayer key={p.dxId}>{p.name}</StyledRefreshPlayer>
                        ))}
                    </StyledRefreshPlayers>
                  </>
                )}
                {!!Object.keys(refreshResult.duplicateDxid).length && (
                  <>
                    <StyledRefreshLabel offset>Duplicate dx ids:</StyledRefreshLabel>
                    <StyledRefreshData>
                      <code>{JSON.stringify(refreshResult.duplicateDxid, null, 2)}</code>
                    </StyledRefreshData>
                  </>
                )}
              </>
            )}
            {refreshErr && (
              <>
                <StyledRefreshLabel>Status:</StyledRefreshLabel>
                <Text>{refreshErr.help.status}</Text>
                <StyledRefreshLabel>Code:</StyledRefreshLabel>
                <Text>{refreshErr.code}</Text>
                <StyledRefreshLabel>Message:</StyledRefreshLabel>
                <Text>{refreshErr.message}</Text>
                <StyledRefreshLabel>Cause:</StyledRefreshLabel>
                <Text>{String(refreshErr.data.cause)}</Text>
                <StyledRefreshLabel>Xref:</StyledRefreshLabel>
                <Text>{refreshErr.help.xref}</Text>
              </>
            )}
          </StyledRefreshResult>
        </>
      </Modal>
    </>
  );
}

export default ManagePlayerPool;
