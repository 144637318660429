import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';

import { Theme } from '../../styles';

type Shade = keyof Theme['colors']['builtins'];

const StyledButton = styled('button', { shouldForwardProp: isPropValid })<
  Pick<Props, 'nomargin' | 'tight'> & Required<Pick<Props, 'shade'>>
>`
  min-height: 28px;
  min-width: ${({ tight }) => (tight ? '0' : '80px')};
  margin-bottom: ${({ nomargin, tight }) => (nomargin || tight ? '0' : '0.5rem')};
  padding: ${({ tight }) => (tight ? '0' : '0.309rem 0.618rem')};
  background: none;
  border: none;
  cursor: pointer;
  color: ${({ theme, shade }) => theme.colors.builtins[shade].base};
  font-family: ${({ theme }) => theme.fonts.body.shortForm};
  font-size: ${({ theme }) => theme.fontSizes.base};
  font-weight: 700;

  &:disabled,
  &:disabled:hover {
    color: ${({ theme, shade }) => theme.colors.builtins[shade].faded};
    cursor: auto;
    text-decoration: none;
  }

  &:hover {
    color: ${({ theme, shade }) => theme.colors.builtins[shade].faded};
    text-decoration: underline;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

export interface Props {
  children?: React.ReactNode;
  disabled?: boolean;
  nomargin?: boolean;
  onClick?: () => void;
  shade?: Shade;
  tight?: boolean;
}

function TextButton({ children, shade = 'text', ...rest }: Props) {
  return (
    <StyledButton shade={shade} {...rest}>
      {children}
    </StyledButton>
  );
}

export default TextButton;
