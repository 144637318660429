import styled from '@emotion/styled';

import { Text } from '../../../components/typography';

const StyledSubmitDealinesEmptyState = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.builtins.blues.base};
  border-radius: 8px;

  p {
    color: ${({ theme }) => theme.colors.builtins.blues.base};
    font-weight: 700;
  }
`;

function DeadlineManagerEmptyState() {
  return (
    <StyledSubmitDealinesEmptyState>
      <Text>This season currently has no submit deadlines set. Use the controls above to add new submit deadlines.</Text>
      <Text>On submit deadline days, you will be able to collect and publish rankings from this page.</Text>
    </StyledSubmitDealinesEmptyState>
  );
}

export default DeadlineManagerEmptyState;
