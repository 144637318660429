import { useEffect, useState } from 'react';

import { scouting } from '@atlanta-hawks/bbops-client-js';
import styled from '@emotion/styled';

import { useScouting } from '../../../contexts/scouting';
import { useSession } from '../../../contexts/session';

import AddForm from './deadline_manager_add_form';
import EmptyState from './deadline_manager_empty_state';
import StateTable from './deadline_manager_state_table';

const StyledSubmitDeadlines = styled.div`
  margin: 0 auto;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media only screen and (max-width: 576px) {
    width: 100%;
  }

  @media only screen and (min-width: 576px) {
    width: 80%;
  }

  @media only screen and (min-width: 875px) {
    width: 700px;
  }
`;

function DeadlineManager() {
  const { user } = useSession();
  const { api, season } = useScouting();

  const [allRanks, setAllRanks] = useState<scouting.Ranks[]>([]);
  const refreshAllRanks = () => api.admin().ranks().getAll().then(setAllRanks);
  useEffect(() => {
    if (!user) {
      return;
    }

    (async () => await refreshAllRanks())();
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!season) {
    return null;
  }

  return (
    <>
      <StyledSubmitDeadlines>
        <AddForm season={season} />
        {!season.submitDeadlines.length && <EmptyState />}
        {!!season.submitDeadlines.length && (
          <StateTable
            allRanks={allRanks.filter(
              ranks => (!ranks.submitHistory.length ? ranks.current : ranks.submitHistory[0].players).length > 0,
            )}
            season={season}
          />
        )}
      </StyledSubmitDeadlines>
    </>
  );
}

export default DeadlineManager;
