import React from 'react';

import styled from '@emotion/styled';

import DnaSpinner from './dna_spinner';

const Container = styled.div`
  margin: 4rem auto;
  border-radius: 12px;
  max-width: 400px;
  box-shadow:
    0 10px 16px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

const Header = styled.div`
  display: flex;
  padding: 12px;
  background: #f8f8f8;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderImage = styled.img`
  border-top-left-radius: 12px;
  width: 48px;
`;

const HeaderTitle = styled.h2`
  flex: 1;
  font-size: ${({ theme }) => theme.fontSizes.peta};
  font-weight: 700;
  text-align: center;
`;

const HeaderAnchor = styled.div`
  width: 48px;
`;

const Body = styled.div`
  padding: 24px;
  border-bottom-right-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

function Loader() {
  return (
    <Container>
      <Header>
        <HeaderImage src="/logo192.png" />
        <HeaderTitle>Starting Session</HeaderTitle>
        <HeaderAnchor />
      </Header>
      <Body>
        <p>Please wait a few moments while we collect your user details.</p>
        <DnaSpinner />
      </Body>
    </Container>
  );
}

export default Loader;
