const urls = {
  admin: {
    url: '/admin',
    path: 'admin/*',
    pages: {
      checkRanks: {
        url: '/admin/check_ranks',
        path: 'check_ranks/*',
        pages: {
          forScout: {
            url: (id: string) => '/admin/check_ranks/' + id,
            path: ':id',
          },
        },
      },
      collectAndPublish: {
        url: '/admin/collect_and_publish',
        path: 'collect_and_publish',
      },
      viewScouts: {
        url: '/admin/view_scouts',
        path: 'view_scouts',
      },
      managePlayerPool: {
        url: '/admin/manage_player_pool',
        path: 'manage_player_pool',
      },
    },
  },

  auth: {
    url: '/auth',
    path: 'auth',
  },

  ranks: {
    url: '/ranks',
    path: 'ranks',
  },

  lobby: {
    url: '/',
  },

  results: {
    url: '/results',
    path: 'results',
  },

  restricted: {
    url: '/restricted',
    path: 'restricted',
  },
};

export default urls;
