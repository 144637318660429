import { CSSObject } from '@emotion/react';

export interface ColorScale {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

const theme = {
  colors: {
    site: {
      navbar: {
        bg: '#f8f8f8',
        border: '#e7e7e7',
      },
      red: { base: 'rgb(224, 58, 62)', bold: 'rgb(201, 7, 42)' },
      yellow: { base: 'rgb(253, 185, 39)', bold: 'rgb(253, 186, 34)' },
    },
    modal: {
      overlay: {
        background: 'rgba(229, 229, 229, 0.618)',
      },
    },
    builtins: {
      blues: { base: '#4d85bd', faded: 'rgba(77, 133, 189, 0.618)', faint: 'rgba(77, 133, 189, 0.025)' },
      greens: { base: '#7caa2d', faded: 'rgba(124, 170, 45, 0.618)', faint: 'rgba(124, 170, 45, 0.025)' },
      reds: { base: '#ed484c', faded: 'rgba(239, 95, 99, 0.618)', faint: 'rgba(239, 95, 99, 0.025)' },
      yellows: { base: '#f5b326', faded: 'rgba(245, 127, 38, 0.618)', faint: 'rgba(245, 127, 38, 0.025)' },
      oranges: { base: '#cb6318', faded: 'rgba(203, 99, 24, 0.618)' },
      grays: {
        base: '#555',
        faded: 'rgba(85, 85, 85, 0.618)',
        darker: '#26282a',
        lighter: '#777',
        lighterer: '#b4b4b4',
        lightest: '#e5e5e5',
        lightest_faded: 'rgba(229, 229, 229, 0.618)',
      },
      text: { base: '#000', faded: 'rgba(0, 0, 0, 0.618)', offwhite: '#f5f5f5' },
      whites: { base: '#ffffff', faded: 'rgba(0, 0, 0, 0.8)' },
    },
    team: {
      current: {
        torchRed: '#c8102e',
        legacyYellow: '#fdb927',
        infinityBlack: '#000000',
        graniteGray: '#9ea2a2',
        white: '#ffffff',
      },
      legacy: {
        2007_2020: {
          hawksRed: '#e03a3e',
          voltGreen: '#c1d32f',
          charcoal: '#26282a',
        },
        1995_2007: {
          hawksRed: '#c8102e',
          hawksYellow: '#ffcd00',
          hawksGold: '#87674f',
          hawksBlack: '#000000',
        },
      },
      currentScales: {
        blue: {
          '50': '#f5f7fc',
          '100': '#d6e1f5',
          '200': '#b3c6ec',
          '300': '#88a6e1',
          '400': '#7094db',
          '500': '#4f7bd3',
          '600': '#3265ca',
          '700': '#2851a2',
          '800': '#224489',
          '900': '#193263',
        },
        charcoal: {
          '50': '#f7f7f7',
          '100': '#e0e0e0',
          '200': '#c5c6c6',
          '300': '#a5a6a7',
          '400': '#939495',
          '500': '#7c7d7e',
          '600': '#68696b',
          '700': '#535556',
          '800': '#464749',
          '900': '#323435',
        },
        hawksGold: {
          '50': '#f9f7f6',
          '100': '#e6dfda',
          '200': '#d0c3ba',
          '300': '#b6a294',
          '400': '#a7907e',
          '500': '#947762',
          '600': '#82634c',
          '700': '#684f3d',
          '800': '#584333',
          '900': '#3f3025',
        },
        hawksRed: {
          '50': '#fdf5f6',
          '100': '#f9d9d9',
          '200': '#f4b6b8',
          '300': '#ed8a8c',
          '400': '#e86e71',
          '500': '#e14246',
          '600': '#c33336',
          '700': '#9d292c',
          '800': '#862325',
          '900': '#61191b',
        },
        hawksYellow: {
          '50': '#fff8d9',
          '100': '#ffdf5a',
          '200': '#efc000',
          '300': '#c9a100',
          '400': '#b39000',
          '500': '#977a00',
          '600': '#806700',
          '700': '#675200',
          '800': '#574600',
          '900': '#3f3200',
        },
        torchRed: {
          '50': '#fdf6f7',
          '100': '#f6d9de',
          '200': '#efb7c0',
          '300': '#e58d9b',
          '400': '#df7385',
          '500': '#d64d63',
          '600': '#cc203c',
          '700': '#aa0e27',
          '800': '#910c21',
          '900': '#6a0918',
        },
        legacyYellow: {
          '50': '#fff7e5',
          '100': '#fedc94',
          '200': '#fdba2a',
          '300': '#d59c21',
          '400': '#bf8b1d',
          '500': '#a17519',
          '600': '#886315',
          '700': '#6d5011',
          '800': '#5c430e',
          '900': '#42300a',
        },
        graniteGray: {
          '50': '#f7f7f7',
          '100': '#dfe0e0',
          '200': '#c4c6c6',
          '300': '#a3a7a7',
          '400': '#919595',
          '500': '#7a7d7d',
          '600': '#676a6a',
          '700': '#535555',
          '800': '#464848',
          '900': '#323434',
        },
        voltGreen: {
          '50': '#f7f9e3',
          '100': '#dde78e',
          '200': '#bed02e',
          '300': '#9fae27',
          '400': '#8f9c23',
          '500': '#78831d',
          '600': '#656f19',
          '700': '#515914',
          '800': '#454b11',
          '900': '#32360c',
        },
      },
    },
  },
  fonts: {
    body: {
      longForm: "'Noto Serif',serif",
      shortForm: 'Questrial,sans-serif',
    },
    code: "'DM Mono',monospace",
    heading: 'Graduate,display',
    subheading: 'Arimo,sans-serif',
  },
  fontSizes: {
    pico: '8px',
    nano: '9px',
    micro: '10px',
    milli: '11px',
    centi: '12px',
    base: '13px',
    deka: '14px',
    kilo: '16px',
    mega: '18px',
    giga: '20px',
    tera: '24px',
    peta: '28px',
  },
  zindex: {
    hamburger: 10,
  },
};

export type Theme = typeof theme;

export type StyledArg<T extends any = {}> = (arg: { theme: Theme } & T) => undefined | false | CSSObject;

export default theme;
