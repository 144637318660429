export const datePicker = {
  format: (d: Date) => {
    const thisYear = d.getFullYear();
    const thisMonth = String(d.getMonth() + 1).padStart(2, '0');
    const thisDate = String(d.getDate()).padStart(2, '0');
    return `${thisYear}-${thisMonth}-${thisDate}`;
  },
  parse: (val: string) => {
    const [year, month, date] = val.split('-');
    return new Date(Date.parse(`${month}/${date}/${year}`));
  },
};

interface DoWaitActions<T> {
  onStart?: () => void;
  fn: () => Promise<T>;
  onDone?: () => void;
}

export const doWait = async <T extends any = any>(
  { fn, onStart = () => {}, onDone = () => {} }: DoWaitActions<T>,
  minWaitMillis = 618,
) => {
  onStart();
  const now = new Date().valueOf();
  try {
    const out = await fn();

    const waitFor = Math.max(0, -(new Date().valueOf() - now) + minWaitMillis);
    await new Promise(res => setTimeout(res, waitFor));

    return out;
  } finally {
    onDone();
  }
};

export const isDate = (d1?: Date) => ({
  after: (d2: Date) => {
    if (!d1) {
      return false;
    }
    if (d2.getFullYear() < d1.getFullYear()) {
      return true;
    } else if (d2.getFullYear() === d1.getFullYear()) {
      if (d2.getMonth() < d1.getMonth()) {
        return true;
      } else if (d2.getMonth() === d1.getMonth()) {
        if (d2.getDate() < d1.getDate()) {
          return true;
        }
      }
    }
    return false;
  },
  before: (d2: Date) => {
    if (!d1) {
      return false;
    }
    if (d2.getFullYear() > d1.getFullYear()) {
      return true;
    } else if (d2.getFullYear() === d1.getFullYear()) {
      if (d2.getMonth() > d1.getMonth()) {
        return true;
      } else if (d2.getMonth() === d1.getMonth()) {
        if (d2.getDate() > d1.getDate()) {
          return true;
        }
      }
    }
    return false;
  },
  same: (d2: Date) => {
    if (!d1) {
      return false;
    }
    if (d1.getFullYear() !== d2.getFullYear()) {
      return false;
    }
    if (d1.getMonth() !== d2.getMonth()) {
      return false;
    }
    if (d1.getDate() !== d2.getDate()) {
      return false;
    }
    return true;
  },
});

const RANDOM_CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export const makeRandom = (len: number) => {
  const out = [];

  let pos = 0;
  while (pos < len) {
    out.push(RANDOM_CHARS.charAt(Math.floor(Math.random() * RANDOM_CHARS.length)));
    pos++;
  }

  return out.join('');
};
