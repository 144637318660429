import { useState } from 'react';
import { FaDownload } from 'react-icons/fa6';
import Modal from 'react-modal';

import { scouting } from '@atlanta-hawks/bbops-client-js';
import styled from '@emotion/styled';

import { Button, OutlineButton } from '../../../components/buttons';
import Table, { TableHeader } from '../../../components/table';
import { Subheading, Text } from '../../../components/typography';
import { scoutDisplay } from '../../../contexts/scouting';

import TableRow from './collect_and_publish_table_row';
import SaveForm from './collect_and_publish_save_form';
import { ScoutRanks, TodayIs } from './types';

const StyledWorkspaceTitle = styled.div`
  padding: 0 1rem 1rem 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.site.navbar.border};
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const StyledWorkspaceControls = styled.div`
  display: flex;
  align-items: center;
`;

const StyledUncontrolledLabel = styled.label`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSizes.centi};
`;

const StyledConfirmation = styled(Text)`
  font-weight: 700;
`;

const StyledButtonGroup = styled.div`
  display: flex;
  & > :not(:first-of-type) {
    margin-left: 0.5rem;
  }
`;

const StyledCollectButton = styled(Button)`
  margin-left: 0.5rem;
  margin-bottom: 0;
`;

const StyledDownloadLabels = styled.label`
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.site.navbar.border};
  & > :not(:first-of-type) {
    margin-top: 0.5rem;
  }
`;

const StyledDownloadLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes.base};
`;

interface Props {
  allRanks: ScoutRanks[];
  collectPastRanks: () => Promise<unknown>;
  currentSeason: number;
  deletePastRanks: (id: string) => Promise<unknown>;
  playersById: Record<string, scouting.Player>;
  refreshRanks: () => Promise<void>;
  savePastRanks: (req: scouting.SavePastRanksRequest) => Promise<unknown>;
  season: number;
  submitDeadline: scouting.SeasonSubmitDeadline;
  todayIs?: TodayIs;
}

function CollectAndPublishTable({
  allRanks,
  collectPastRanks,
  currentSeason,
  deletePastRanks,
  playersById,
  refreshRanks,
  savePastRanks,
  season,
  submitDeadline,
  todayIs,
}: Props) {
  const [showUncollected, setShowUncollected] = useState(false);
  const [showIneligible, setShowIneligible] = useState(true);

  const [pastRanksToDelete, setPastRanksToDelete] = useState<scouting.PastRanks>();
  const [pastRanksToSave, setPastRanksToSave] = useState<scouting.Ranks>();

  const [showDownload, setShowDownload] = useState(false);
  const [excludedScouts, setExcludedScouts] = useState<string[]>([]);

  const makeReportQuery = () => {
    if (!excludedScouts.length) {
      return '';
    }
    return '&' + excludedScouts.map(scout => 'exclude=' + encodeURIComponent(scout)).join('&');
  };

  return (
    <>
      <StyledWorkspaceTitle>
        <Subheading>{`Collected Ranks: ${submitDeadline.date.toLocaleDateString()}`}</Subheading>
        <StyledWorkspaceControls>
          <StyledUncontrolledLabel>
            <input
              type="checkbox"
              disabled={!submitDeadline}
              checked={showUncollected}
              onChange={e => setShowUncollected(e.target.checked)}
            />
            Show uncollected
          </StyledUncontrolledLabel>
          <StyledUncontrolledLabel>
            <input
              type="checkbox"
              disabled={!submitDeadline}
              checked={showIneligible}
              onChange={e => setShowIneligible(e.target.checked)}
            />
            Show ineligible
          </StyledUncontrolledLabel>
          <StyledCollectButton
            shade="reds"
            disabled={!todayIs || todayIs === 'after'}
            onClick={() => collectPastRanks().then(refreshRanks)}
          >
            Collect all ranks
          </StyledCollectButton>
          <StyledCollectButton shade="blues" onClick={() => setShowDownload(true)}>
            Download&nbsp;&nbsp;
            <FaDownload />
          </StyledCollectButton>
        </StyledWorkspaceControls>
      </StyledWorkspaceTitle>
      <Table
        header={
          <tr>
            <TableHeader />
            <TableHeader />
            <TableHeader>Scout</TableHeader>
            <TableHeader>Total</TableHeader>
            <TableHeader>PG</TableHeader>
            <TableHeader>SG</TableHeader>
            <TableHeader>SF</TableHeader>
            <TableHeader>PF</TableHeader>
            <TableHeader>C</TableHeader>
            <TableHeader>Submitted</TableHeader>
            <TableHeader>Collected on</TableHeader>
            <TableHeader />
          </tr>
        }
      >
        {allRanks
          .filter(({ past }) => !!past || showUncollected)
          .filter(({ past }) => showIneligible || (past?.players.length || -1) >= submitDeadline.size)
          .map(ranks => (
            <TableRow
              key={ranks.active.id}
              playersById={playersById}
              ranks={ranks}
              showDeletePastRanks={() => setPastRanksToDelete(ranks.past)}
              showSavePastRanks={() => setPastRanksToSave(ranks.active)}
              submitDeadline={submitDeadline}
              tableSize={12}
            />
          ))}
      </Table>
      <Modal isOpen={!!pastRanksToDelete} onRequestClose={() => setPastRanksToDelete(undefined)}>
        {pastRanksToDelete && (
          <>
            <Text>{`You have selected to delete the collected rankings for ${scoutDisplay(pastRanksToDelete.email)}.`}</Text>
            <Text>{`This will be in effect for the submission period #${submitDeadline.period} of the ${season} season.`}</Text>
            <StyledConfirmation>Are you sure you want to delete this?</StyledConfirmation>
            <StyledButtonGroup>
              <Button
                shade="reds"
                onClick={() =>
                  deletePastRanks(pastRanksToDelete.id)
                    .then(refreshRanks)
                    .then(() => setPastRanksToDelete(undefined))
                }
              >
                Delete
              </Button>
              <OutlineButton onClick={() => setPastRanksToDelete(undefined)}>Cancel</OutlineButton>
            </StyledButtonGroup>
          </>
        )}
      </Modal>
      <Modal isOpen={!!pastRanksToSave} onRequestClose={() => setPastRanksToSave(undefined)}>
        {pastRanksToSave && (
          <SaveForm
            closeModal={() => setPastRanksToSave(undefined)}
            ranks={pastRanksToSave}
            savePastRanks={req => savePastRanks(req).then(refreshRanks)}
            season={season}
            submitDeadline={submitDeadline}
          />
        )}
      </Modal>
      <Modal isOpen={showDownload} onRequestClose={() => setShowDownload(false)}>
        <Text>Select any scouts you wish to exclude from the report.</Text>
        <StyledDownloadLabels>
          {allRanks.map(({ active }) => (
            <StyledDownloadLabel>
              <input
                key={active.email}
                type="checkbox"
                name="downloadScout"
                value={active.email}
                checked={!!excludedScouts.find(email => email === active.email)}
                onChange={e => {
                  const idx = excludedScouts.indexOf(e.target.value);
                  if (e.target.checked && idx === -1) {
                    setExcludedScouts(prev => [e.target.value, ...prev]);
                  }
                  if (!e.target.checked && idx !== -1) {
                    setExcludedScouts(prev => prev.splice(idx, 1));
                  }
                }}
              />
              {active.email}
            </StyledDownloadLabel>
          ))}
        </StyledDownloadLabels>
        <Text>
          You can access the downloadable report{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href={`${process.env.REACT_APP_BBOPS_BASE_URL}/api/scouting/${currentSeason}/reports/past_rankings?period=${submitDeadline.period}${makeReportQuery()}`}
          >
            here.
          </a>
        </Text>
        <Text>
          Note: if you receive a "must authenticate" error, please first refresh the Scouting Tool and then try again.
        </Text>
      </Modal>
    </>
  );
}

export default CollectAndPublishTable;
