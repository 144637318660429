import React from 'react';
import { useSearchParams, Navigate } from 'react-router-dom';

import Loader from '../../components/loader';
import { useSession } from '../../contexts/session';
import urls from '../../urls';

function Auth() {
  const [searchParams] = useSearchParams();
  const error = searchParams.get('error');
  const errorDescription = searchParams.get('error_description');

  const { active, loading } = useSession();

  if (!loading) {
    if (error || errorDescription) {
      console.error('failed on auth callback', {
        error,
        errorDescription,
        locationHref: window.location.href,
      });
    }

    return <Navigate to={active ? urls.ranks.url : urls.lobby.url} replace />;
  }

  return <Loader />;
}

export default Auth;
