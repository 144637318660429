import styled from '@emotion/styled';

import { Text } from '../typography';

const StyledText = styled(Text)`
  margin-bottom: 0;
  color: white;
`;

function PlayerWithdrawn() {
  return <StyledText>Player has withdrawn from draft</StyledText>;
}

export default PlayerWithdrawn;
