import { FaExclamationCircle, FaEye, FaTimes } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';

import { scouting } from '@atlanta-hawks/bbops-client-js';
import styled from '@emotion/styled';

import { IconButton, TextButton } from '../../../components/buttons';
import { TableData } from '../../../components/table';
import { datePicker, isDate } from '../../../core';
import { useScouting } from '../../../contexts/scouting';

const StyledTableData = styled(TableData)<{ doFlex?: boolean; ok?: boolean }>`
  color: ${({ theme, ok }) => theme.colors.builtins[ok ? 'reds' : 'yellows'].base};
  font-weight: 700;
  ${({ doFlex }) =>
    doFlex && {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
`;

const StyledExclamationCircle = styled(FaExclamationCircle)`
  color: ${({ theme }) => theme.colors.builtins.yellows.base};
  margin-right: 0.309rem;
`;

interface Props {
  allRanks: scouting.Ranks[];
  submitDeadline: scouting.SeasonSubmitDeadline;
  removeSubmitDeadline: () => Promise<void>;
  showConfigureModal: () => void;
  showCollectModal: () => void;
  showPublishModal: () => void;
}

function StateTableRow({
  allRanks,
  submitDeadline,
  removeSubmitDeadline,
  showConfigureModal,
  showCollectModal,
  showPublishModal,
}: Props) {
  const { today } = useScouting();
  const checkToday = isDate(today);

  let todayIs: 'before' | 'same' | 'after' = 'after';
  if (checkToday.same(submitDeadline.date)) {
    todayIs = 'same';
  } else if (checkToday.before(submitDeadline.date)) {
    todayIs = 'before';
  }

  const readyRanks = allRanks.filter(
    ranks => (!ranks.submitHistory.length ? ranks.current : ranks.submitHistory[0].players).length >= submitDeadline.size,
  );

  const tooltipId = `admin-state-table-row-${submitDeadline.period}`;

  return (
    <>
      <tr>
        <TableData fit="min">
          {!submitDeadline.collected && (
            <IconButton tight shade="grays" onClick={removeSubmitDeadline}>
              <FaTimes />
            </IconButton>
          )}
          {submitDeadline.collected && (
            <IconButton tight shade="grays" onClick={showCollectModal}>
              <FaEye />
            </IconButton>
          )}
        </TableData>
        <TableData>{`#${submitDeadline.period}`}</TableData>
        <TableData>{datePicker.format(submitDeadline.date)}</TableData>
        <TableData>
          <TextButton shade="reds" tight onClick={showConfigureModal} disabled={todayIs === 'after'}>
            {submitDeadline.size}
          </TextButton>
        </TableData>
        {todayIs === 'before' && (
          <>
            <TableData>{!allRanks.length ? '--' : `${readyRanks.length} / ${allRanks.length} ready`}</TableData>
            <TableData>--</TableData>
          </>
        )}
        {todayIs === 'same' && (
          <>
            <StyledTableData doFlex>
              {!allRanks.length && (
                <StyledExclamationCircle
                  data-tooltip-id={tooltipId}
                  data-tooltip-content="No scouts have started ranking players yet."
                />
              )}
              {!readyRanks.length && (
                <StyledExclamationCircle
                  data-tooltip-id={tooltipId}
                  data-tooltip-content="No scouts have ranked enough players to meet this period's requirements yet.."
                />
              )}
              <TextButton shade="blues" tight disabled={!readyRanks.length} onClick={showCollectModal}>
                Collect...
              </TextButton>
            </StyledTableData>
            <TableData>
              {!submitDeadline.collected ? (
                '--'
              ) : (
                <TextButton shade="reds" tight onClick={showPublishModal}>
                  Publish...
                </TextButton>
              )}
            </TableData>
          </>
        )}
        {todayIs === 'after' && !submitDeadline.collected && (
          <>
            <StyledTableData>No</StyledTableData>
            <StyledTableData>No</StyledTableData>
          </>
        )}
        {todayIs === 'after' && submitDeadline.collected && (
          <>
            <StyledTableData ok>Yes</StyledTableData>
            <TableData>
              <TextButton shade="reds" tight onClick={showPublishModal}>
                Publish...
              </TextButton>
            </TableData>
          </>
        )}
      </tr>
      <Tooltip id={tooltipId} place="bottom" />
    </>
  );
}

export default StateTableRow;
