import { useId, useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import Modal from 'react-modal';

import styled from '@emotion/styled';

import { Heading, Subheading } from '../typography';

const StyledControlPaneRow = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const StyledHeading = styled(Heading)`
  padding-top: 0.5em;
  color: white;
`;

const StyledViewPane = styled.div`
  margin-top: 0.5rem;
  margin-left: 1rem;
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledViewTitle = styled(Subheading)`
  color: ${({ theme }) => theme.colors.builtins.text.offwhite};
`;

const StyledViewOptions = styled.div`
  display: flex;
`;

const StyledViewOption = styled.label`
  color: ${({ theme }) => theme.colors.builtins.text.offwhite};
  font-family: ${({ theme }) => theme.fonts.body.shortForm};
  font-size: ${({ theme }) => theme.fontSizes.base};
`;

const StyledCondensedCheckbox = styled.label`
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.body.shortForm};
  font-size: ${({ theme }) => theme.fontSizes.centi};
  cursor: pointer;
  color: white;

  & input {
    margin-right: 0.309rem;
    cursor: inherit;
  }
`;

const StyledButton = styled.button`
  background: none;
  border: none;
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
`;

const StyledOutcomes = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.5rem;
  row-gap: 1rem;
  align-items: center;
`;

const StyledOutcomeHeader = styled.p`
  font-weight: 700;
`;

export type ViewDetails = 'info' | 'outcomes';

const isViewDetails = (val: string): val is ViewDetails => {
  switch (val) {
    case 'info':
      return true;
    case 'outcomes':
      return true;
  }
  return false;
};

interface Props {
  children?: React.ReactNode;
  condensed: boolean;
  setCondensed: (condensed: boolean) => void;
  setViewDetails: (viewDetails: ViewDetails) => void;
  viewDetails: ViewDetails;
}

function PlayerListTitle({ children, condensed, setCondensed, setViewDetails, viewDetails }: Props) {
  const [showPlayerOutcomes, setShowPlayerOutcomes] = useState(false);
  const name = useId();

  const handleViewChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isViewDetails(e.target.value)) {
      return;
    }
    setViewDetails(e.target.value);
  };

  return (
    <>
      <StyledControlPaneRow>
        <StyledHeading as="h2" nomargin>
          {children}
        </StyledHeading>
        <StyledViewPane>
          <StyledViewTitle as="h6" nomargin>
            View details:
          </StyledViewTitle>
          <StyledViewOptions>
            <StyledViewOption>
              <input type="radio" name={name} value="info" checked={viewDetails === 'info'} onChange={handleViewChange} />
              Player info
            </StyledViewOption>
            <StyledViewOption>
              <input
                type="radio"
                name={name}
                value="outcomes"
                checked={viewDetails === 'outcomes'}
                onChange={handleViewChange}
              />
              Player outcomes
              <StyledButton
                onClick={() => {
                  setShowPlayerOutcomes(true);
                  setViewDetails('outcomes');
                }}
              >
                <FaInfoCircle />
              </StyledButton>
            </StyledViewOption>
          </StyledViewOptions>
        </StyledViewPane>
        <StyledCondensedCheckbox>
          <input type="checkbox" checked={condensed} onChange={e => setCondensed(e.target.checked)} /> Condensed view
        </StyledCondensedCheckbox>
      </StyledControlPaneRow>
      <Modal isOpen={showPlayerOutcomes} onRequestClose={() => setShowPlayerOutcomes(false)}>
        <StyledOutcomes>
          <StyledOutcomeHeader>MVP</StyledOutcomeHeader>
          <p>Top 3-5 NBA player</p>
          <StyledOutcomeHeader>Franchise</StyledOutcomeHeader>
          <p>Best player on a championship level team ("Batman")</p>
          <StyledOutcomeHeader>Core</StyledOutcomeHeader>
          <p>All-Star caliber player that defers to the franchise player ("Robin")</p>
          <StyledOutcomeHeader>Top Starter</StyledOutcomeHeader>
          <p>Key piece on a team that would start on most teams in the league</p>
          <StyledOutcomeHeader>Starter/Key Reserve</StyledOutcomeHeader>
          <p>
            Would start on a few teams across the league but is capable of playing quality minutes in high pressure situations
          </p>
          <StyledOutcomeHeader>Rotation</StyledOutcomeHeader>
          <p>Good compliment to the player that he is backing up</p>
          <StyledOutcomeHeader>Roster</StyledOutcomeHeader>
          <p>
            Still in the developmental stages of his game; unidentified or emerging NBA skill -- may be a few years away from
            being a consistent contributor to a winning team
          </p>
          <StyledOutcomeHeader>G-League</StyledOutcomeHeader>
          <p>Player worth monitoring yet lacks NBA skills to contribute to an NBA roster</p>
        </StyledOutcomes>
      </Modal>
    </>
  );
}

export default PlayerListTitle;
