import styled from '@emotion/styled';

import styles from './styles';

interface Props {
  children?: React.ReactNode;
}

const StyledNavbar = styled.nav(styles.navbar);

function Navbar({ children }: Props) {
  return <StyledNavbar>{children}</StyledNavbar>;
}

export default Navbar;
