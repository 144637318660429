import { scouting } from '@atlanta-hawks/bbops-client-js';
import styled from '@emotion/styled';

import { playerAge } from '../../contexts/scouting';

import { Text } from '../typography';

const StyledPlayerInfo = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledPlayerDetailTight = styled.div`
  display: flex;
  align-items: baseline;
  overflow: hidden;
`;

const StyledPlayerDetailStack = styled.div`
  margin: 0 0.618rem 0 0.309rem;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 4px;
`;

const StyledPlayerDetailName = styled(Text)<{ tight?: boolean }>`
  margin-bottom: ${({ tight }) => (tight ? '0.309rem' : '0')};
  font-family: ${({ theme }) => theme.fonts.subheading};
  font-size: ${({ theme }) => theme.fontSizes.centi};
  font-weight: 700;
  margin-right: 0.309rem;
`;

const StyledPlayerDetailValue = styled(Text)`
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: ${({ theme }) => theme.fontSizes.centi};
`;

const PlayerAge = ({ birthdate, seasonYear, tight }: { birthdate?: string; seasonYear: number; tight?: boolean }) => (
  <>
    <StyledPlayerDetailName tight={tight}>Age:</StyledPlayerDetailName>
    <StyledPlayerDetailValue nomargin>
      {!birthdate && 'n/a'}
      {birthdate && playerAge(seasonYear, birthdate)}
    </StyledPlayerDetailValue>
  </>
);

const PlayerHeight = ({ height, tight }: { height: string; tight?: boolean }) => {
  const parts = height.split('-');

  let display = height;
  if (parts.length === 2) {
    display = `${parts[0]}' ${parts[1]}"`;
  }
  return (
    <>
      <StyledPlayerDetailName tight={tight}>Height:</StyledPlayerDetailName>
      <StyledPlayerDetailValue nomargin>{display}</StyledPlayerDetailValue>
    </>
  );
};

const PlayerWeight = ({ weight, tight }: { weight: number; tight?: boolean }) => (
  <>
    <StyledPlayerDetailName tight={tight}>Weight:</StyledPlayerDetailName>
    {!weight && <StyledPlayerDetailValue nomargin>--</StyledPlayerDetailValue>}
    {!!weight && <StyledPlayerDetailValue nomargin>{`${weight} lbs`}</StyledPlayerDetailValue>}
  </>
);

interface Props {
  player: scouting.Player;
  seasonYear: number;
  tight?: boolean;
}

function PlayerInfo({ player, seasonYear, tight }: Props) {
  if (tight) {
    return (
      <>
        <StyledPlayerDetailTight>
          <PlayerAge birthdate={player.dob} seasonYear={seasonYear} tight />
        </StyledPlayerDetailTight>
        <StyledPlayerDetailTight>
          <PlayerHeight height={player.height} tight />
        </StyledPlayerDetailTight>
        <StyledPlayerDetailTight>
          <PlayerWeight weight={player.weight} tight />
        </StyledPlayerDetailTight>
      </>
    );
  }

  return (
    <StyledPlayerInfo>
      <StyledPlayerDetailStack>
        <PlayerAge birthdate={player.dob} seasonYear={seasonYear} />
        <PlayerHeight height={player.height} />
        <PlayerWeight weight={player.weight} />
      </StyledPlayerDetailStack>
    </StyledPlayerInfo>
  );
}

export default PlayerInfo;
