import styled from '@emotion/styled';

import { Text } from '../../../components/typography';

const StyledScoutsRanksEmptyState = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.builtins.grays.base};
  border-radius: 8px;

  p {
    color: ${({ theme }) => theme.colors.builtins.grays.base};
    font-weight: 700;
  }
`;

function ScoutsRanksEmptyState() {
  return (
    <StyledScoutsRanksEmptyState>
      <Text>No one has logged in and began their ranks yet.</Text>
    </StyledScoutsRanksEmptyState>
  );
}

export default ScoutsRanksEmptyState;
