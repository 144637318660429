import { useEffect, useState } from 'react';
import { FaDownload } from 'react-icons/fa6';
import Modal from 'react-modal';
import { Tooltip } from 'react-tooltip';

import { scouting } from '@atlanta-hawks/bbops-client-js';
import styled from '@emotion/styled';

import { Button } from '../../components/buttons';
import Dropdown from '../../components/dropdown';
import Table, { TableData, TableHeader } from '../../components/table';
import { Toggle } from '../../components/toggle';
import { Text } from '../../components/typography';
import { useScouting } from '../../contexts/scouting';

import ResultsEmptyState from './empty_state';
import { RankDelta } from './ranks_viewer';

const StyledToolbar = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.builtins.yellows.base};
  background: ${({ theme }) => theme.colors.site.navbar.bg};
  padding: 0.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.builtins.yellows.base};
  display: flex;
  justify-content: space-between;
`;

const StyledWorkspace = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledDropdownControls = styled.div`
  display: flex;
`;

const StyledDropdownControl = styled.div`
  margin-right: 0.5rem;
`;

const StyledRanksControls = styled.div`
  display: flex;
  align-items: baseline;
  & > * {
    margin-left: 1rem;
  }
`;
const StyledPastRanksControls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

const StyledTable = styled(Table)`
  height: 100%;
`;

const StyledNumberCol = styled.col`
  width: 1px;
  white-space: nowrap;
`;

const StyledDownloadLabels = styled.label`
  padding: 1rem 0;
  display: flex;
  align-items: center;
  & > * {
    margin-right: 0.5rem;
  }
`;

const StyledDownloadLabel = styled.label`
  display: flex;
  align-items: center;
  font-weight: 700;
`;

const StyledIneligibleData = styled(TableData)<{ ineligible?: boolean }>`
  ${({ ineligible }) => ineligible && `text-decoration: line-through;`}
`;

function ResultsPage() {
  const { compositeRanks, compositeRanksByPeriod, currentSeason, playersById, season } = useScouting();

  const [periods, setPeriods] = useState<string[]>([]);
  const [viewPeriod, setViewPeriod] = useState('');

  const [labels, setLabels] = useState<string[]>([]);
  const [viewLabel, setViewLabel] = useState('');

  const [showDownload, setShowDownload] = useState(false);
  const [downloadPeriods, setDownloadPeriods] = useState<Record<string, boolean>>({});
  const [downloadLabels, setDownloadLabels] = useState<Record<string, boolean>>({});

  const applyViewPeriod = (period: string) => {
    setViewPeriod(period);

    const ranks = compositeRanksByPeriod[period] || [];
    ranks.sort((r1, r2) => r1.createdOn.getTime() - r2.createdOn.getTime());

    const labels = (compositeRanksByPeriod[period] || []).map(ranks => ranks.label);

    setLabels(labels);
    setViewLabel(labels.length ? labels[0] : '');
  };

  useEffect(() => {
    const periods = Object.keys(compositeRanksByPeriod);
    periods.sort((p1, p2) => parseInt(p2, 10) - parseInt(p1, 10));

    setPeriods(periods);
    applyViewPeriod(periods.length ? periods[0] : '');
    setDownloadPeriods(periods.reduce((acc, period) => ({ ...acc, [period]: false }), {}));
  }, [compositeRanksByPeriod, season]); // eslint-disable-line react-hooks/exhaustive-deps

  const [viewRanks, setViewRanks] = useState<scouting.CompositeRanks>();
  const [viewPastRanks, setViewPastRanks] = useState<scouting.CompositeRanks[]>([]);
  useEffect(() => {
    if (!viewPeriod || !viewLabel) {
      setViewRanks(undefined);
      setViewPastRanks([]);
      return;
    }

    const ranks = (compositeRanksByPeriod[viewPeriod] || []).find(ranks => ranks.label === viewLabel);
    if (ranks) {
      setViewRanks(ranks);
      setViewPastRanks(compositeRanks.filter(r => r.label === ranks.label && r.period < ranks.period));
    }
  }, [compositeRanks, compositeRanksByPeriod, viewPeriod, viewLabel]);

  const [viewPastPositionRanks, setViewPastPositionRanks] = useState(false);

  useEffect(() => {
    const labelsSeen: Record<string, true> = {};
    const newLabels = compositeRanks
      .filter(cr => !!downloadPeriods[cr.period])
      .map(cr => cr.label)
      .filter(label => {
        if (labelsSeen[label]) {
          return false;
        }
        labelsSeen[label] = true;
        return true;
      })
      .sort();
    setDownloadLabels(prev => newLabels.reduce((acc, label) => ({ ...acc, [label]: label in prev ? prev[label] : false }), {}));
  }, [compositeRanks, downloadPeriods]);

  const makeReportQuery = () => {
    const periods = Object.entries(downloadPeriods)
      .filter(([, value]) => !!value)
      .map(([key]) => key);
    const labels = Object.entries(downloadLabels)
      .filter(([, value]) => !!value)
      .map(([key]) => key);

    let queryParts: string[] = [];
    if (periods.length && periods.length !== Object.keys(downloadPeriods).length) {
      queryParts.push(...periods.map(period => 'period=' + encodeURIComponent(period)));
    }
    if (labels.length && labels.length !== Object.keys(downloadLabels).length) {
      queryParts.push(...labels.map(label => 'label=' + encodeURIComponent(label)));
    }

    if (!queryParts.length) {
      return '';
    }
    return '?' + queryParts.join('&');
  };

  return (
    <>
      <StyledToolbar>
        <StyledDropdownControls>
          <StyledDropdownControl>
            <Dropdown label="View past results" value={viewPeriod} onChange={e => applyViewPeriod(e.target.value)}>
              {!periods.length && (
                <option value="" disabled>
                  None available
                </option>
              )}
              {periods.map((period, i) => {
                return (
                  <option key={`dropdown_period_${period}`} value={period}>
                    {i === 0 && `Latest`}
                    {i !== 0 && `Period #${period}`}
                  </option>
                );
              })}
            </Dropdown>
          </StyledDropdownControl>
          <StyledDropdownControl>
            <Dropdown
              disabled={!viewPeriod}
              label="Rankings Label"
              value={viewLabel}
              onChange={e => setViewLabel(e.target.value)}
            >
              {!labels.length && (
                <option value="" disabled>
                  None available
                </option>
              )}
              {labels.map(label => {
                return (
                  <option key={`dropdown_label_${label}`} value={label}>
                    {label}
                  </option>
                );
              })}
            </Dropdown>
          </StyledDropdownControl>
        </StyledDropdownControls>
        <StyledRanksControls>
          <StyledPastRanksControls>
            {!!viewPastRanks.length && (
              <Toggle
                kicker="Include positional ranks"
                checked={viewPastPositionRanks}
                onChange={setViewPastPositionRanks}
                tight
              />
            )}
          </StyledPastRanksControls>
          <Button shade="blues" onClick={() => setShowDownload(true)}>
            Download&nbsp;&nbsp;
            <FaDownload />
          </Button>
        </StyledRanksControls>
      </StyledToolbar>
      <StyledWorkspace>
        {!viewRanks && <ResultsEmptyState />}
        {!!viewRanks && (
          <StyledTable
            colgroup={
              <colgroup>
                <StyledNumberCol span={3} />
              </colgroup>
            }
            floatHeader
            header={
              <>
                <tr>
                  <TableHeader colSpan={4}>Overall</TableHeader>
                  <TableHeader colSpan={3}>Info</TableHeader>
                  <TableHeader colSpan={4}>Position</TableHeader>
                  {viewPastRanks.map(r => (
                    <>
                      <TableHeader key={r.id + '_overall'} colSpan={2}>
                        {`P${r.period}`}:&nbsp;Overall
                      </TableHeader>
                      {viewPastPositionRanks && (
                        <TableHeader key={r.id + '_position'} colSpan={2}>
                          {`P${r.period}`}:&nbsp;Position
                        </TableHeader>
                      )}
                    </>
                  ))}
                </tr>
                <tr>
                  <TableHeader data-tooltip-id="results-tooltip-bottom-start" data-tooltip-content="Overall Rank">
                    #
                  </TableHeader>
                  <TableHeader data-tooltip-id="results-tooltip-bottom-start" data-tooltip-content="Change in Overall Rank">
                    Δ
                  </TableHeader>
                  <TableHeader data-tooltip-id="results-tooltip-bottom-start" data-tooltip-content="Average Overall Rank">
                    μ
                  </TableHeader>
                  <TableHeader data-tooltip-id="results-tooltip-bottom-start" data-tooltip-content="Std Dev of Overall Rank">
                    σ
                  </TableHeader>
                  <TableHeader>Player</TableHeader>
                  <TableHeader>Team</TableHeader>
                  <TableHeader>Pos</TableHeader>
                  <TableHeader data-tooltip-id="results-tooltip-bottom-end" data-tooltip-content="Positional Rank">
                    #
                  </TableHeader>
                  <TableHeader data-tooltip-id="results-tooltip-bottom-end" data-tooltip-content="Change in Positional Rank">
                    Δ
                  </TableHeader>
                  <TableHeader data-tooltip-id="results-tooltip-bottom-end" data-tooltip-content="Average of Positional Rank">
                    μ
                  </TableHeader>
                  <TableHeader data-tooltip-id="results-tooltip-bottom-end" data-tooltip-content="Std Dev of Positional Rank">
                    σ
                  </TableHeader>
                  {viewPastRanks.map(r => (
                    <>
                      <TableHeader key={r.id + '_overall_place'}>#</TableHeader>
                      <TableHeader key={r.id + '_overall_delta'}>Δ</TableHeader>
                      {viewPastPositionRanks && (
                        <>
                          <TableHeader key={r.id + '_position_place'}>#</TableHeader>
                          <TableHeader key={r.id + '_position_delta'}>Δ</TableHeader>
                        </>
                      )}
                    </>
                  ))}
                </tr>
              </>
            }
            headerAlign="middle"
          >
            {(viewRanks?.players || []).map(rank => {
              const player = playersById[rank.playerId] || {
                name: '',
                position: '--',
                team: '--',
                ineligible: true,
              };
              const currentRanks = (viewRanks?.players || []).find(p => p.playerId === rank.playerId);
              const pastRanks = (
                viewPastRanks.find(r => r.period === (viewRanks?.period || 0) - 1 && r.label === (viewRanks?.label || ''))
                  ?.players || []
              ).find(p => p.playerId === rank.playerId);

              return (
                <tr key={rank.playerId}>
                  <TableData fit="min">{rank.overall.place}</TableData>
                  <TableData fit="min">
                    {pastRanks && (
                      <RankDelta value={(pastRanks?.overall?.placeValue || 0) - (currentRanks?.overall?.placeValue || 0)} />
                    )}
                  </TableData>
                  <TableData fit="min">{rank.overall.score.toFixed(2)}</TableData>
                  <TableData fit="min">{rank.overall.stdDev.toFixed(2)}</TableData>
                  <StyledIneligibleData ineligible={player.ineligible}>{player.name || '--'}</StyledIneligibleData>
                  <StyledIneligibleData ineligible={player.ineligible}>{player.team}</StyledIneligibleData>
                  <StyledIneligibleData ineligible={player.ineligible}>{player.position}</StyledIneligibleData>
                  <TableData fit="min">{rank.position.place}</TableData>
                  <TableData fit="min">
                    {pastRanks && (
                      <RankDelta value={(pastRanks?.position?.placeValue || 0) - (currentRanks?.position?.placeValue || 0)} />
                    )}
                  </TableData>
                  <TableData fit="min">{rank.position.score.toFixed(2)}</TableData>
                  <TableData fit="min">{rank.position.stdDev.toFixed(2)}</TableData>
                  {viewPastRanks.map(ranks => {
                    const currentRanks = ranks.players.find(p => p.playerId === rank.playerId);
                    const pastRanks = (viewPastRanks.find(r => r.period === (ranks.period || 0) - 1)?.players || []).find(
                      p => p.playerId === rank.playerId,
                    );
                    return (
                      <>
                        <TableData key={rank.playerId + '_overall_place'} fit="min">
                          {currentRanks?.overall?.place || '--'}
                        </TableData>
                        <TableData key={rank.playerId + '_overall_delta'} fit="min">
                          {pastRanks && (
                            <RankDelta
                              value={(pastRanks?.overall?.placeValue || 0) - (currentRanks?.overall?.placeValue || 0)}
                            />
                          )}
                        </TableData>
                        {viewPastPositionRanks && (
                          <>
                            <TableData key={rank.playerId + '_position_place'} fit="min">
                              {currentRanks?.position?.place || '--'}
                            </TableData>
                            <TableData key={rank.playerId + '_position_delta'} fit="min">
                              {pastRanks && (
                                <RankDelta
                                  value={(pastRanks?.position?.placeValue || 0) - (currentRanks?.position?.placeValue || 0)}
                                />
                              )}
                            </TableData>
                          </>
                        )}
                      </>
                    );
                  })}
                </tr>
              );
            })}
          </StyledTable>
        )}
      </StyledWorkspace>
      <Tooltip id="results-tooltip-bottom-start" place="top-start" />
      <Tooltip id="results-tooltip-bottom-end" place="top-end" />
      <Modal isOpen={showDownload} onRequestClose={() => setShowDownload(false)}>
        {(!season || !season.submitDeadlines.length) && (
          <Text>There have been no submission periods yet to view composite rankings for.</Text>
        )}
        {season && (
          <>
            <Text>Use the following options to configure the report you would like to download.</Text>
            <StyledDownloadLabels>
              <Text nomargin>Choose period(s):</Text>
              <StyledDownloadLabel>
                <input
                  type="checkbox"
                  name="downloadPeriod"
                  value="all"
                  checked={!!Object.keys(downloadPeriods).length && Object.values(downloadPeriods).every(val => val)}
                  onChange={e =>
                    setDownloadPeriods(prev =>
                      Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: e.target.checked }), {}),
                    )
                  }
                />{' '}
                All periods
              </StyledDownloadLabel>
              {Object.keys(downloadPeriods)
                .sort()
                .map(period => (
                  <StyledDownloadLabel key={`download_period_` + period}>
                    <input
                      type="checkbox"
                      name="downloadPeriod"
                      value={period}
                      checked={!!downloadPeriods[period]}
                      onChange={e => setDownloadPeriods(prev => ({ ...prev, [e.target.value]: e.target.checked }))}
                    />{' '}
                    {`Period #${period}`}
                  </StyledDownloadLabel>
                ))}
            </StyledDownloadLabels>
            <StyledDownloadLabels>
              <Text nomargin>Choose label(s):</Text>
              <StyledDownloadLabel>
                <input
                  type="checkbox"
                  name="downloadLabel"
                  value="all"
                  checked={!!Object.keys(downloadLabels).length && Object.values(downloadLabels).every(val => val)}
                  disabled={!Object.keys(downloadLabels).length}
                  onChange={e =>
                    setDownloadLabels(prev => Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: e.target.checked }), {}))
                  }
                />{' '}
                All labels
              </StyledDownloadLabel>
              {Object.keys(downloadLabels)
                .sort()
                .map(label => (
                  <StyledDownloadLabel key={'download_label_' + label}>
                    <input
                      type="checkbox"
                      name="downloadLabel"
                      value={label}
                      checked={!!downloadLabels[label]}
                      onChange={e => setDownloadLabels(prev => ({ ...prev, [e.target.value]: e.target.checked }))}
                    />{' '}
                    {label}
                  </StyledDownloadLabel>
                ))}
            </StyledDownloadLabels>
            <Text>
              You can access the downloadable report{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href={`${process.env.REACT_APP_BBOPS_BASE_URL}/api/scouting/${currentSeason}/reports/composite_rankings${makeReportQuery()}`}
              >
                here.
              </a>
            </Text>
            <Text>
              Note: if you receive a "must authenticate" error, please first refresh the Scouting Tool and then try again.
            </Text>
          </>
        )}
      </Modal>
    </>
  );
}

export default ResultsPage;
