import styled from '@emotion/styled';

import { Theme } from '../../styles';

const StyledText = styled.p<Pick<Props, 'nomargin'> & Required<Pick<Props, 'size' | 'variant'>>>`
  margin: unset;
  margin-bottom: ${({ nomargin }) => (nomargin ? '0' : '0.5rem')};
  font-family: ${({ theme, variant }) => theme.fonts.body[variant]};
  font-size: ${({ theme, size }) => theme.fontSizes[size]};
`;

type TextSize = keyof Pick<Theme['fontSizes'], 'base' | 'centi' | 'milli' | 'micro'>;
type TextType = 'p' | 'span';

interface Props {
  as?: TextType;
  children?: React.ReactNode;
  nomargin?: boolean;
  size?: TextSize;
  variant?: keyof Theme['fonts']['body'];
}

function Text({ as = 'p', children, size = 'base', variant = 'shortForm', ...rest }: Props) {
  return (
    <StyledText as={as} size={size} variant={variant} {...rest}>
      {children}
    </StyledText>
  );
}

export default Text;
