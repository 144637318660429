import { Route, Routes } from 'react-router-dom';

import styled from '@emotion/styled';

import { PrivateRoute } from '../components/auth';
import Loader from '../components/loader';
import ScoutingProvider from '../contexts/scouting';
import { useSession } from '../contexts/session';
import { AuthPage, LobbyPage, RanksPage, ResultsPage, RestrictedPage } from '../pages';
import AdminPage, {
  AdminLanding,
  CheckRanks,
  CheckRanksForScout,
  CollectAndPublish,
  ManagePlayerPool,
  ViewScouts,
} from '../pages/admin';
import urls from '../urls';

import Footer from './footer';
import Navbar from './navbar';
import ScoutingLinks from './scouting_links';
import TitleBar from './title_bar';

const StyledMain = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-y: auto;
`;

function App() {
  const { loading } = useSession();

  if (loading) {
    return <Loader />;
  }

  return (
    <ScoutingProvider>
      <Navbar />
      <StyledMain>
        <TitleBar />
        <ScoutingLinks />
        <Routes>
          <Route index element={<LobbyPage />} />
          <Route
            path={urls.ranks.path}
            element={
              <PrivateRoute>
                <RanksPage />
              </PrivateRoute>
            }
          />
          <Route
            path={urls.results.path}
            element={
              <PrivateRoute>
                <ResultsPage />
              </PrivateRoute>
            }
          />
          <Route
            path={urls.admin.path}
            element={
              <PrivateRoute accessLevel="admin">
                <AdminPage />
              </PrivateRoute>
            }
          >
            <Route index element={<AdminLanding />} />
            <Route path={urls.admin.pages.collectAndPublish.path} element={<CollectAndPublish />} />
            <Route path={urls.admin.pages.checkRanks.path}>
              <Route index element={<CheckRanks />} />
              <Route path={urls.admin.pages.checkRanks.pages.forScout.path} element={<CheckRanksForScout />} />
            </Route>
            <Route path={urls.admin.pages.managePlayerPool.path} element={<ManagePlayerPool />} />
            <Route path={urls.admin.pages.viewScouts.path} element={<ViewScouts />} />
          </Route>
          <Route path={urls.auth.path} element={<AuthPage />} />
          <Route path={urls.restricted.path} element={<RestrictedPage />} />
        </Routes>
      </StyledMain>
      <Footer />
    </ScoutingProvider>
  );
}

export default App;
