import { forwardRef } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

const StyledPopover = styled.div<Pick<Props, 'position' | 'show'>>(({ position, show }) => [
  css`
    position: absolute;
    box-shadow:
      0 10px 15px -3px rgba(46, 41, 51, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    z-index: 9999;
    min-width: 10rem;
    padding: 0;
    list-style: none;
    background-color: #fff;
    border: 1px solid;
    border-radius: 0.5rem;
    display: ${show ? 'block' : 'none'};
  `,
  position === 'below' &&
    css`
      top: 50%;
      right: 50%;
    `,
  position === 'on_right' &&
    css`
      top: 0;
      left: 100%;
    `,
]);

type PopoverPosition = 'below' | 'on_right';

interface Props {
  children?: React.ReactNode;
  position?: PopoverPosition;
  show: boolean;
}

const Popover = forwardRef<HTMLDivElement, Props>(({ children, position = 'below', show }, ref) => {
  return (
    <StyledPopover position={position} show={show} ref={ref}>
      {children}
    </StyledPopover>
  );
});

export default Popover;
