import { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';

import { scouting } from '@atlanta-hawks/bbops-client-js';
import styled from '@emotion/styled';

import { Button, OutlineButton } from '../../../components/buttons';
import { SpinnerButton } from '../../../components/spinner';
import { Heading, Text } from '../../../components/typography';

const StyledNavDivider = styled.hr`
  height: 1px;
  width: 100%;
  border: 0;
  border-top: 1px solid ${({ theme }) => theme.colors.builtins.grays.lighterer};
`;

const StyledForm = styled.div`
  margin-top: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  row-gap: 1rem;
  column-gap: 0.5rem;
`;

const StyledLabel = styled.label`
  text-align: right;
  font-size: ${({ theme }) => theme.fontSizes.base};
  font-weight: 700;
`;

const StyledButtons = styled.div`
  grid-column: 1 / 3;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > button {
    margin-right: 0.5rem;
  }
`;

export interface ConfigurableSubmitDeadline {
  i: number;
  data: scouting.SeasonSubmitDeadline;
}

interface Props {
  submitDeadline?: ConfigurableSubmitDeadline;
  closeModal: () => void;
  updateSubmitDeadline: (deadline: ConfigurableSubmitDeadline) => Promise<void>;
}

function DeadlineManagerConfigureModal({ closeModal, submitDeadline, updateSubmitDeadline }: Props) {
  const [minRanks, setMinRanks] = useState(10);
  const [minPG, setMinPG] = useState(0);
  const [minSG, setMinSG] = useState(0);
  const [minSF, setMinSF] = useState(0);
  const [minPF, setMinPF] = useState(0);
  const [minC, setMinC] = useState(0);

  const setMinimums = useCallback(() => {
    setMinRanks(submitDeadline?.data?.size || 10);
    setMinPG(submitDeadline?.data?.positions?.pg || 0);
    setMinSG(submitDeadline?.data?.positions?.sg || 0);
    setMinSF(submitDeadline?.data?.positions?.sf || 0);
    setMinPF(submitDeadline?.data?.positions?.pf || 0);
    setMinC(submitDeadline?.data?.positions?.c || 0);
  }, [submitDeadline]);

  useEffect(() => {
    setMinimums();
  }, [setMinimums]);

  const isClean =
    minRanks === submitDeadline?.data?.size &&
    minPG === submitDeadline?.data?.positions?.pg &&
    minSG === submitDeadline?.data?.positions?.sg &&
    minSF === submitDeadline?.data?.positions?.sf &&
    minPF === submitDeadline?.data?.positions?.pf &&
    minC === submitDeadline?.data?.positions?.c;

  return (
    <Modal
      isOpen={!!submitDeadline}
      onRequestClose={closeModal}
      style={{
        content: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
      }}
    >
      <Heading as="h3">Configure Submit Deadline</Heading>
      <Text>The required number of rankings per position defaults to zero, but you may set the minimum levels here.</Text>
      <StyledNavDivider />
      <StyledForm>
        <StyledLabel>Total number of ranks: </StyledLabel>
        <input type="number" value={minRanks} onChange={e => setMinRanks(parseInt(e.target.value, 10))} />
        <StyledLabel>Minimum PG:</StyledLabel>
        <input type="number" value={minPG} onChange={e => setMinPG(parseInt(e.target.value, 10))} />
        <StyledLabel>Minimum SG:</StyledLabel>
        <input type="number" value={minSG} onChange={e => setMinSG(parseInt(e.target.value, 10))} />
        <StyledLabel>Minimum SF:</StyledLabel>
        <input type="number" value={minSF} onChange={e => setMinSF(parseInt(e.target.value, 10))} />
        <StyledLabel>Minimum PF:</StyledLabel>
        <input type="number" value={minPF} onChange={e => setMinPF(parseInt(e.target.value, 10))} />
        <StyledLabel>Minimum C:</StyledLabel>
        <input type="number" value={minC} onChange={e => setMinC(parseInt(e.target.value, 10))} />
        <StyledButtons>
          <OutlineButton shade="yellows" disabled={isClean} onClick={setMinimums}>
            Discard
          </OutlineButton>
          <SpinnerButton
            onClick={() =>
              updateSubmitDeadline({
                i: submitDeadline?.i || 0,
                data: {
                  ...(submitDeadline?.data || { period: 0, date: new Date(), collected: false, published: false }),
                  size: minRanks,
                  positions: { pg: minPG, sg: minSG, sf: minSF, pf: minPF, c: minC },
                },
              })
            }
          >
            <Button shade="reds" disabled={isClean}>
              Save
            </Button>
          </SpinnerButton>
        </StyledButtons>
      </StyledForm>
    </Modal>
  );
}

export default DeadlineManagerConfigureModal;
