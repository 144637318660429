import { Children, forwardRef } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import styles from './styles';

const StyledNavPopover = styled.ul<Pick<Props, 'position' | 'show'>>(({ position, show }) => [
  css`
    position: absolute;
    box-shadow:
      0 10px 15px -3px rgba(46, 41, 51, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    z-index: 9999;
    min-width: 10rem;
    padding: 0;
    list-style: none;
    background-color: #fff;
    border-radius: 0.5rem;
    display: ${show ? 'block' : 'none'};
  `,
  position === 'below' &&
    css`
      top: 50px;
      left: 0;
      right: auto;
    `,
  position === 'on_right' &&
    css`
      top: 0;
      left: 100%;
    `,
]);

const NavPopoverItem = styled.li(styles.navFloatingItem);

interface DefaultProps {
  position: 'below' | 'on_right';
}

interface Props extends Partial<DefaultProps> {
  children?: React.ReactNode;
  show: boolean;
}

const NavPopover = forwardRef<HTMLUListElement, Props>(({ children, position, show }, ref) => {
  return (
    <StyledNavPopover position={position} show={show} ref={ref}>
      {Children.map(children, child => (
        <NavPopoverItem>{child}</NavPopoverItem>
      ))}
    </StyledNavPopover>
  );
});

const defaultProps: DefaultProps = {
  position: 'below',
};
NavPopover.defaultProps = defaultProps;

export default NavPopover;
