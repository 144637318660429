import { useState } from 'react';

import { scouting } from '@atlanta-hawks/bbops-client-js';
import styled from '@emotion/styled';

import { Button } from '../../../components/buttons';
import Spinner from '../../../components/spinner';
import { Text } from '../../../components/typography';
import { useScouting } from '../../../contexts/scouting';
import { doWait } from '../../../core';

const StyledSubmitRankings = styled.div<{ skipCol?: boolean }>`
  ${({ skipCol }) => skipCol && { gridColumn: 3 }}
  margin: 0.5rem 0;
  display: flex;
  flex-direction: column;
`;

const StyledSubmitRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid black;
  margin-bottom: 0.5rem;
`;

const StyledStatus = styled.span`
  display: flex;
  align-items: center;
`;

const StyledStatusText = styled(Text)`
  margin-left: 0.309rem;
  color: ${({ theme }) => theme.colors.builtins.grays.base};
`;

const StyledMetadata = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

const StyledRanksDates = styled.div`
  flex: 1;
  align-self: flex-end;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.309rem;
  row-gap: 0.309rem;
`;

const StyledRanksDate = styled(Text)<{ isLabel?: boolean }>`
  font-size: ${({ theme }) => theme.fontSizes.centi};
  ${({ theme, isLabel }) =>
    isLabel ? { fontFamily: theme.fonts.body.longForm, fontWeight: 700 } : { fontFamily: theme.fonts.code }}
`;

const StyledSubmitHistory = styled.div`
  flex: 1;
`;

const StyledSubmitHistorySelect = styled.select`
  height: 28px;
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes.centi};
`;

interface Props {
  savingCurrent: boolean;
  skipCol?: boolean;
}

function SubmitRankings({ savingCurrent, skipCol }: Props) {
  const { api, ranks, ranksFetch } = useScouting();

  const [submittingCurrent, setSubmittingCurrent] = useState(false);

  const hasRanks = !!(ranks?.submitHistory || []).length;

  const submitRanks = (data: scouting.Rank[]) =>
    doWait({
      onStart: () => setSubmittingCurrent(true),
      onDone: () => setSubmittingCurrent(false),
      fn: () => api.ranks().patch({ type: 'set_submitted', data }).then(ranksFetch),
    });

  return (
    <StyledSubmitRankings skipCol={skipCol}>
      <StyledSubmitRow>
        <StyledStatus>
          <Spinner active={savingCurrent || submittingCurrent} shade="yellows" />
          {savingCurrent && <StyledStatusText>Saving ranks...</StyledStatusText>}
          {submittingCurrent && <StyledStatusText>Submitting ranks...</StyledStatusText>}
        </StyledStatus>
        <Button shade="reds" onClick={() => submitRanks(ranks?.current || [])}>
          Submit ranks
        </Button>
      </StyledSubmitRow>
      <StyledMetadata>
        <StyledRanksDates>
          <StyledRanksDate isLabel nomargin>
            Started on:
          </StyledRanksDate>
          <StyledRanksDate nomargin>{ranks?.createdOn ? formatDate(ranks.createdOn) : '--'}</StyledRanksDate>
          <StyledRanksDate isLabel nomargin>
            Last saved:
          </StyledRanksDate>
          <StyledRanksDate nomargin>{ranks?.updatedOn ? formatDate(ranks.updatedOn) : '--'}</StyledRanksDate>
          <StyledRanksDate isLabel nomargin>
            Last submitted:
          </StyledRanksDate>
          <StyledRanksDate nomargin>
            {!!ranks?.submitHistory.length ? formatDate(ranks.submitHistory[0].date) : '--'}
          </StyledRanksDate>
        </StyledRanksDates>
        <StyledSubmitHistory>
          {false && (
            <StyledSubmitHistorySelect disabled={!hasRanks}>
              <option value="">View submit history</option>
            </StyledSubmitHistorySelect>
          )}
        </StyledSubmitHistory>
      </StyledMetadata>
    </StyledSubmitRankings>
  );
}

export default SubmitRankings;

function formatDate(date: Date) {
  const now = new Date();

  let out = date.toLocaleString();

  if (now.getFullYear() !== date.getFullYear()) {
    return out;
  }

  if (now.getMonth() === date.getMonth()) {
    if (now.getDate() === date.getDate()) {
      return `Today, ${date.toLocaleTimeString()}`;
    }

    if (now.getDate() - 1 === date.getDate()) {
      return `Yesterday, ${date.toLocaleTimeString()}`;
    }
  }

  return `${date.getMonth() + 1}/${date.getDate()}, ${date.toLocaleTimeString()}`;
}
