import { FaSpinner } from 'react-icons/fa';

import isPropValid from '@emotion/is-prop-valid';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import { Theme } from '../../styles';

type Shade = keyof Theme['colors']['builtins'];

const StyledSpinnerContainer = styled.div`
  margin-bottom: 0.5rem;
  text-align: center;
`;

const spinner = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledSpinner = styled(FaSpinner, { shouldForwardProp: isPropValid })<
  Pick<Props, 'active'> & Required<Pick<Props, 'shade'>>
>`
  color: ${({ theme, shade }) => theme.colors.builtins[shade].base};
  animation: ${spinner} infinite 1s linear;
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
`;

interface Props {
  active?: boolean;
  shade?: Shade;
}

function Spinner({ shade = 'text', ...rest }: Props) {
  return (
    <StyledSpinnerContainer>
      <StyledSpinner {...rest} shade={shade} />
    </StyledSpinnerContainer>
  );
}

export default Spinner;
