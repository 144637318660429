import { useState } from 'react';
import { FaCaretDown, FaCaretUp, FaTimes } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

import { scouting } from '@atlanta-hawks/bbops-client-js';
import styled from '@emotion/styled';

import { TextButton } from '../../../components/buttons';
import Menu from '../../../components/menu';
import { TableData } from '../../../components/table';
import { Text } from '../../../components/typography';
import { checkRanks, scoutDisplay } from '../../../contexts/scouting';
import urls from '../../../urls';

const StyledTableRowExpanded = styled.tr`
  background-color: ${({ theme }) => theme.colors.site.navbar.bg};
`;

const StyledSubmittedMenu = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledIconTextButton = styled(TextButton)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  margin: 0.5rem 0.25rem;
  border-top: 1px solid ${({ theme }) => theme.colors.site.navbar.border};
  border-bottom: 1px solid ${({ theme }) => theme.colors.site.navbar.border};
`;

const StyledDeleteIcon = styled(FaTimes)`
  margin-right: 0.5rem;
  color: ${({ theme }) => theme.colors.team.current.torchRed};
`;

const StyledDeleteText = styled(Text)`
  flex: 1;
  margin-bottom: 0;
  text-align: left;
  color: ${({ theme }) => theme.colors.team.current.torchRed};
`;

interface Props {
  playersById: Record<string, scouting.Player>;
  ranks: scouting.Ranks;
  showDeleteRanks: () => void;
  showDeleteSubmitted: (date: string) => void;
}

function CheckRanksTableRow({ playersById, ranks, showDeleteRanks, showDeleteSubmitted }: Props) {
  const [expanded, setExpanded] = useState(false);

  const effRanks = ranks.submitHistory.length ? ranks.submitHistory[0].players : ranks.current;

  const checks = checkRanks(effRanks, playersById);

  return (
    <>
      <tr key={ranks.id}>
        <TableData width="180px">
          <TextButton onClick={() => setExpanded(prev => !prev)}>{expanded ? <FaCaretUp /> : <FaCaretDown />}</TextButton>
        </TableData>
        <TableData width="300px">
          <NavLink to={urls.admin.pages.checkRanks.pages.forScout.url(ranks.id)}>{scoutDisplay(ranks.email)}</NavLink>
        </TableData>
        <TableData>{checks.size}</TableData>
        <TableData>{checks.positions.pg}</TableData>
        <TableData>{checks.positions.sg}</TableData>
        <TableData>{checks.positions.sf}</TableData>
        <TableData>{checks.positions.pf}</TableData>
        <TableData>{checks.positions.c}</TableData>
        <TableData>{ranks.createdOn.toLocaleString()}</TableData>
        <TableData>{ranks.updatedOn.toLocaleString()}</TableData>
        <TableData width="100px">
          <Menu label="...">
            <StyledSubmittedMenu>
              <StyledIconTextButton onClick={showDeleteRanks}>
                <StyledDeleteIcon />
                <StyledDeleteText>Delete</StyledDeleteText>
              </StyledIconTextButton>
            </StyledSubmittedMenu>
          </Menu>
        </TableData>
      </tr>
      {expanded && !ranks.submitHistory.length && (
        <StyledTableRowExpanded>
          <TableData cellAlign="right">Submit history:</TableData>
          <TableData />
          <TableData>--</TableData>
          <TableData>--</TableData>
          <TableData>--</TableData>
          <TableData>--</TableData>
          <TableData>--</TableData>
          <TableData>--</TableData>
          <TableData colSpan={2}>Nothing submitted yet</TableData>
          <TableData />
        </StyledTableRowExpanded>
      )}
      {expanded &&
        ranks.submitHistory.length &&
        ranks.submitHistory.map((submitted, i) => {
          const checks = checkRanks(submitted.players, playersById);
          return (
            <StyledTableRowExpanded key={`${ranks.id}_${submitted.date.toISOString()}`}>
              {i === 0 ? <TableData>Submit history:</TableData> : <TableData />}
              <TableData cellAlign="right">{submitted.date.toLocaleString()}</TableData>
              <TableData>{checks.size}</TableData>
              <TableData>{checks.positions.pg}</TableData>
              <TableData>{checks.positions.sg}</TableData>
              <TableData>{checks.positions.sf}</TableData>
              <TableData>{checks.positions.pf}</TableData>
              <TableData>{checks.positions.c}</TableData>
              <TableData colSpan={2} />
              <TableData>
                <Menu label="...">
                  <StyledSubmittedMenu>
                    <StyledIconTextButton onClick={() => showDeleteSubmitted(submitted.date.toJSON())}>
                      <StyledDeleteIcon />
                      <StyledDeleteText>Delete</StyledDeleteText>
                    </StyledIconTextButton>
                  </StyledSubmittedMenu>
                </Menu>
              </TableData>
            </StyledTableRowExpanded>
          );
        })}
    </>
  );
}

export default CheckRanksTableRow;
