import { useEffect, useRef, useState } from 'react';

import styled from '@emotion/styled';

import { TextButton } from '../buttons';

import Popover from './popover';

const StyledMenu = styled.div`
  position: relative;
`;

interface Props {
  children?: React.ReactNode;
  label: string;
}

function Menu({ children, label }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && event.target instanceof Node && !ref.current.contains(event.target)) {
        setShow(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return (
    <StyledMenu>
      <TextButton onClick={() => setShow(show => !show)}>{label}</TextButton>
      <Popover show={show} ref={ref}>
        {children}
      </Popover>
    </StyledMenu>
  );
}

export default Menu;
