import { useState } from 'react';

import { scouting } from '@atlanta-hawks/bbops-client-js';
import styled from '@emotion/styled';

import Table, { TableHeader } from '../../../components/table';
import { Text } from '../../../components/typography';

import TableRow from './manage_player_pool_table_row';

const StyledControls = styled.div`
  padding: 0 1rem 1rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.site.navbar.border};
`;

const StyledSortControl = styled.div`
  display: flex;
  align-items: center;
  & > p {
    font-weight: 700;
  }
`;

const StyledSortLabel = styled.label`
  display: flex;
  align-items: flex-end;
  margin-left: 0.5rem;
  & > p {
    font-size: ${({ theme }) => theme.fontSizes.deka};
  }
`;

type PlayerSort = 'name' | 'tier';

const isPlayerSort = (v: string): v is PlayerSort => v === 'name' || v === 'tier';

const nameSort = (p1: scouting.Player, p2: scouting.Player): number => {
  const ln1 = p1.lastName.toLowerCase();
  const ln2 = p2.lastName.toLowerCase();
  if (ln1 > ln2) {
    return 1;
  } else if (ln1 < ln2) {
    return -1;
  }

  const fn1 = p1.firstName.toLowerCase();
  const fn2 = p2.firstName.toLowerCase();
  if (fn1 > fn2) {
    return 1;
  } else if (fn1 < fn2) {
    return -1;
  }

  return p1.dxPlayerId - p2.dxPlayerId;
};

const tierSort = (p1: scouting.Player, p2: scouting.Player): number => {
  const t1 = p1.tier || 100;
  const t2 = p2.tier || 100;

  if (t1 === t2) {
    return nameSort(p1, p2);
  }

  return t1 - t2;
};

interface Props {
  players: scouting.Player[];
  seasonYear: number;
}

function ManagePlayerPool({ players, seasonYear }: Props) {
  const [sort, setSort] = useState<PlayerSort>('tier');

  const handleSortChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    if (!isPlayerSort(value)) {
      return;
    }
    setSort(value);
  };

  return (
    <>
      <StyledControls>
        <StyledSortControl>
          <Text nomargin>Sort by:</Text>
          <StyledSortLabel>
            <input type="radio" name="sort" value="tier" checked={sort === 'tier'} onChange={handleSortChange} />
            <Text nomargin>Tier</Text>
          </StyledSortLabel>
          <StyledSortLabel>
            <input type="radio" name="sort" value="name" checked={sort === 'name'} onChange={handleSortChange} />
            <Text nomargin>Name</Text>
          </StyledSortLabel>
        </StyledSortControl>
      </StyledControls>
      <Table
        header={
          <tr>
            <TableHeader>Tier</TableHeader>
            <TableHeader>Name</TableHeader>
            <TableHeader>Pos</TableHeader>
            <TableHeader>Team</TableHeader>
            <TableHeader>Age</TableHeader>
          </tr>
        }
      >
        {players.sort(sort === 'name' ? nameSort : tierSort).map(player => (
          <TableRow key={player.personId} player={player} seasonYear={seasonYear} />
        ))}
      </Table>
    </>
  );
}

export default ManagePlayerPool;
