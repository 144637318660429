import styled from '@emotion/styled';

import styles, { TableCellAlign } from './styles';

interface Props {
  alignment?: 'baseline' | 'middle';
  cellAlign?: TableCellAlign;
  children?: React.ReactNode;
  colSpan?: number;
  fit?: 'min';
}

const StyledTableHeader = styled.th<Pick<Props, 'alignment' | 'cellAlign' | 'fit'>>`
  ${styles.tableCell}
  ${styles.tableCellMin}
  font-size: ${({ theme }) => theme.fontSizes.base};
  font-weight: 700;
  border-bottom: 1px solid black;
  vertical-align: ${({ align }) => align};
`;

function TableHeader({ children, cellAlign = 'center', ...rest }: Props) {
  return (
    <StyledTableHeader cellAlign={cellAlign} {...rest}>
      {children}
    </StyledTableHeader>
  );
}

export default TableHeader;
