import styled from '@emotion/styled';

import { Text } from '../../../components/typography';
import { useScouting } from '../../../contexts/scouting';

const StyledEmptyState = styled.div`
  margin-top: 0.5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem auto;
  border: 4px solid ${({ theme }) => theme.colors.builtins.reds.base};
  border-radius: 8px;
  height: 300px;

  @media only screen and (max-width: 576px) {
    min-width: 98%;
  }

  @media only screen and (min-width: 576px) {
    max-width: 540px;
    width: 540px;
  }
`;

const StyledWelcome = styled(Text)`
  margin-bottom: 2rem;
  font-weight: 700;
`;

const StyledSeason = styled.span`
  color: ${({ theme }) => theme.colors.builtins.reds.base};
  font-weight: 700;
`;

function ResultsEmptyState() {
  const { currentSeason } = useScouting();
  return (
    <StyledEmptyState>
      <StyledWelcome>Welcome to the Composite Draft Rankings board, a part of the Hawks' scouting operation.</StyledWelcome>
      <Text nomargin>
        There are current no composite draft rankings for the <StyledSeason>{currentSeason}</StyledSeason> season. Please check
        again later for any updates.
      </Text>
    </StyledEmptyState>
  );
}

export default ResultsEmptyState;
