import { useState } from 'react';

import { scouting } from '@atlanta-hawks/bbops-client-js';

import Table, { TableHeader } from '../../../components/table';
import { useScouting } from '../../../contexts/scouting';

import CollectModal from './deadline_manager_modal_collect';
import ConfigureModal, { ConfigurableSubmitDeadline } from './deadline_manager_modal_configure';
import PublishModal from './deadline_manager_modal_publish';
import StateTableRow from './deadline_manager_state_table_row';

interface Props {
  allRanks: scouting.Ranks[];
  season: scouting.Season;
}

function SubmitDeadlinesStateTable({ allRanks, season }: Props) {
  const { api, seasonFetch } = useScouting();

  const [submitDeadlineConfigure, setSubmitDeadlineConfigure] = useState<ConfigurableSubmitDeadline>();
  const [submitDeadlineCollect, setSubmitDeadlineCollect] = useState<scouting.SeasonSubmitDeadline>();
  const [submitDeadlinePublish, setSubmitDeadlinePublish] = useState<scouting.SeasonSubmitDeadline>();

  return (
    <>
      <Table
        floatHeader
        header={
          <tr>
            <TableHeader fit="min" />
            <TableHeader>Period</TableHeader>
            <TableHeader>Date</TableHeader>
            <TableHeader>Size</TableHeader>
            <TableHeader>Collected</TableHeader>
            <TableHeader>Published</TableHeader>
          </tr>
        }
      >
        {season.submitDeadlines.map((submitDeadline, i) => (
          <StateTableRow
            key={submitDeadline.date.toISOString()}
            allRanks={allRanks}
            submitDeadline={submitDeadline}
            removeSubmitDeadline={() => {
              const newSubmitDeadlines = [...season.submitDeadlines];
              newSubmitDeadlines.splice(i, 1);
              return api.admin().season().patch({ type: 'set_submit_deadlines', data: newSubmitDeadlines }).then(seasonFetch);
            }}
            showConfigureModal={() => setSubmitDeadlineConfigure({ i, data: submitDeadline })}
            showCollectModal={() => setSubmitDeadlineCollect(submitDeadline)}
            showPublishModal={() => setSubmitDeadlinePublish(submitDeadline)}
          />
        ))}
      </Table>
      <ConfigureModal
        closeModal={() => setSubmitDeadlineConfigure(undefined)}
        submitDeadline={submitDeadlineConfigure}
        updateSubmitDeadline={updated => {
          const newSubmitDeadlines = [...season.submitDeadlines];
          newSubmitDeadlines[updated.i] = updated.data;
          return api
            .admin()
            .season()
            .patch({ type: 'set_submit_deadlines', data: newSubmitDeadlines })
            .then(seasonFetch)
            .then(() => setSubmitDeadlineConfigure(undefined));
        }}
      />
      <CollectModal
        allRanks={allRanks}
        closeModal={() => setSubmitDeadlineCollect(undefined)}
        submitDeadline={submitDeadlineCollect}
      />
      <PublishModal closeModal={() => setSubmitDeadlinePublish(undefined)} submitDeadline={submitDeadlinePublish} />
    </>
  );
}

export default SubmitDeadlinesStateTable;
