import styled from '@emotion/styled';

const StyledNavDivider = styled.hr`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.builtins.grays.lightest};
  border: none;
`;

function NavDivider() {
  return <StyledNavDivider />;
}

export default NavDivider;
