import { useEffect, useState } from 'react';

import { scouting } from '@atlanta-hawks/bbops-client-js';
import styled from '@emotion/styled';

import { Button, OutlineButton } from '../../../components/buttons';
import Dropdown from '../../../components/dropdown';
import { Text } from '../../../components/typography';
import { scoutDisplay } from '../../../contexts/scouting';

const CURRENT = 'current';

const StyledConfirmation = styled(Text)`
  font-weight: 700;
`;

const StyledButtonGroup = styled.div`
  display: flex;
  & > :not(:first-of-type) {
    margin-left: 0.5rem;
  }
`;

const StyledFeedback = styled(Text)`
  margin-top: 0.5rem;
`;

interface Props {
  closeModal: () => void;
  ranks: scouting.Ranks;
  savePastRanks: (req: scouting.SavePastRanksRequest) => Promise<unknown>;
  season: number;
  submitDeadline: scouting.SeasonSubmitDeadline;
}

function CollectAndPublishTableSaveForm({ closeModal, ranks, savePastRanks, season, submitDeadline }: Props) {
  const [toSave, setToSave] = useState('');
  useEffect(() => {
    if (!toSave) {
      setToSave(CURRENT);
    }
  }, [ranks, toSave]);

  return (
    <>
      <Text>{`Select the rankings you wish to collect for ${scoutDisplay(ranks.email)}.`}</Text>
      <Dropdown value={toSave} onChange={e => setToSave(e.target.value)}>
        {[
          { label: 'Current rankings', value: CURRENT },
          ...ranks.submitHistory.map(submitted => ({
            label: 'Submitted: ' + submitted.date.toLocaleString(),
            value: submitted.date.toJSON(),
          })),
        ].map(({ label, value }) => (
          <option key={`${ranks.id}_${label}`} value={value}>
            {label}
          </option>
        ))}
      </Dropdown>
      <StyledFeedback>{`This will be in effect for submission period #${submitDeadline.period} of the ${season} season.`}</StyledFeedback>
      <StyledConfirmation>Are you sure you want to save this?</StyledConfirmation>
      <StyledButtonGroup>
        <Button
          shade="reds"
          onClick={() =>
            savePastRanks({
              seasonYear: season,
              period: submitDeadline.period,
              email: ranks.email,
              current: toSave === 'current',
              submitted: toSave === 'current' ? '' : toSave,
            }).then(closeModal)
          }
        >
          Save
        </Button>
        <OutlineButton onClick={closeModal}>Cancel</OutlineButton>
      </StyledButtonGroup>
    </>
  );
}

export default CollectAndPublishTableSaveForm;
