import styled from '@emotion/styled';

const subheadingSize = (as: SubheadingType) => {
  switch (as) {
    case 'h4':
      return 'mega';
    case 'h5':
      return 'kilo';
    case 'h6':
      return 'deka';
  }
};

const StyledSubheading = styled.h1<Required<Pick<Props, 'as' | 'nomargin'>>>`
  margin: unset;
  margin-bottom: ${({ nomargin }) => (nomargin ? '0' : '0.5rem')};
  font-family: ${({ theme }) => theme.fonts.subheading};
  font-size: ${({ theme, as }) => theme.fontSizes[subheadingSize(as)]};
  color: ${({ theme, as }) => (as === 'h4' ? 'black' : theme.colors.builtins.grays.lighter)};
`;

type SubheadingType = 'h4' | 'h5' | 'h6';

interface Props {
  as?: SubheadingType;
  children?: React.ReactNode;
  nomargin?: boolean;
}

function Subheading({ as = 'h4', children, nomargin = false, ...rest }: Props) {
  return (
    <StyledSubheading as={as} nomargin={nomargin} {...rest}>
      {children}
    </StyledSubheading>
  );
}

export default Subheading;
