import { useEffect, useCallback } from 'react';

export const useDebounce = (effect: () => void, dependencies: any[], delayMillis = 250) => {
  const callback = useCallback(effect, dependencies); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const timeout = setTimeout(callback, delayMillis);
    return () => clearTimeout(timeout);
  }, [callback, delayMillis]);
};
