import styled from '@emotion/styled';

// using `StyledListItem` as a CSS selector in the List styles as well (for a "flush" effect)
import { StyledListItem } from './list_item';

const StyledList = styled.ul<Pick<Props, 'flush'>>`
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  ${({ flush }) =>
    flush && {
      [`${StyledListItem}`]: {
        borderRightWidth: 0,
        borderLeftWidth: 0,
        '&:first-of-type': {
          borderTopWidth: 0,
        },
        '&:last-of-type': {
          borderBottomWidth: 0,
        },
      },
    }}
`;

interface Props {
  children?: React.ReactNode;
  flush: boolean;
  ordered: boolean;
}

function List({ children, ordered, ...rest }: Props) {
  const Component = ordered ? StyledList.withComponent('ol') : StyledList;
  return <Component {...rest}>{children}</Component>;
}

const defaultProps: Partial<Props> = {
  flush: false,
  ordered: false,
};

List.defaultProps = defaultProps;

export default List;
