import { useState, useId } from 'react';

import { scouting } from '@atlanta-hawks/bbops-client-js';
import styled from '@emotion/styled';

import { OutlineButton } from '../../../components/buttons';
import { Text } from '../../../components/typography';
import { useScouting } from '../../../contexts/scouting';
import { useDebounce } from '../../../core';

const INPUT_HEIGHT = 32;

const StyledPlayersFilters = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
`;

const StyledMeta = styled.div`
  margin-top: 0.5rem;
  margin-bottom: auto;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.309rem;
  row-gap: 0.309rem;
`;

const StyledMetaText = styled(Text)<{ isLabel?: boolean }>`
  font-size: ${({ theme }) => theme.fontSizes.deka};
  ${({ isLabel }) => isLabel && { fontWeight: 700 }}
`;

const StyledFilters = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 0.5rem;
`;

const StyledSearchName = styled.input`
  height: ${INPUT_HEIGHT - 4}px;
  font-size: ${({ theme }) => theme.fontSizes.centi};
  flex: 1;
  margin-right: 0.5rem;
`;

const StyledSelect = styled.select<{ wide?: boolean }>`
  margin-left: ${({ wide }) => (wide ? '0.5rem' : '0')};
  flex: ${({ wide }) => (wide ? 2 : 1)};
  height: ${INPUT_HEIGHT}px;
  font-size: ${({ theme }) => theme.fontSizes.centi};
`;

interface Props {
  playerTeams: string[];
  setPlayers: (players: scouting.Player[]) => void;
  resetPlayers: () => void;
}

function PlayersFilters({ playerTeams, resetPlayers, setPlayers }: Props) {
  const id = useId();

  const { currentSeason, currentPeriod, playerPool } = useScouting();

  const [filters, setFilters] = useState({ name: '', position: '', team: '' });

  useDebounce(() => {
    if (!filters.name && !filters.position && !filters.team) {
      return;
    }
    setPlayers(
      playerPool
        .filter(p => {
          if (!filters.name) {
            return true;
          }

          const searchInputNormalized = filters.name.toLowerCase();

          const testNormalized = p.name.toLowerCase();
          if (testNormalized.includes(searchInputNormalized)) {
            return true;
          }

          return false;
        })
        .filter(p => {
          if (!filters.position) {
            return true;
          }

          if (p.position === filters.position) {
            return true;
          }
          return false;
        })
        .filter(p => {
          if (!filters.team) {
            return true;
          }

          if (p.team === filters.team) {
            return true;
          }
          return false;
        }),
    );
  }, [filters.name, filters.position, filters.team]);

  return (
    <StyledPlayersFilters>
      <StyledMeta>
        <StyledMetaText isLabel nomargin>
          Current Season:
        </StyledMetaText>
        <StyledMetaText nomargin>{currentSeason}</StyledMetaText>
        {!!currentPeriod && (
          <>
            <StyledMetaText isLabel nomargin>
              Current Period:
            </StyledMetaText>
            <StyledMetaText nomargin>{`#${currentPeriod}`}</StyledMetaText>
          </>
        )}
      </StyledMeta>
      <StyledFilters>
        <StyledSearchName
          list={id}
          placeholder="Search player by name..."
          value={filters.name}
          onChange={e => setFilters(prev => ({ ...prev, name: e.target.value }))}
        />
        <datalist id={id}>
          {playerPool.map(p => (
            <option key={p.id} value={p.name} />
          ))}
        </datalist>
        <OutlineButton
          shade="grays"
          nomargin
          onClick={() => {
            setFilters({ name: '', position: '', team: '' });
            resetPlayers();
          }}
        >
          Clear filters
        </OutlineButton>
      </StyledFilters>
      <StyledFilters>
        <StyledSelect value={filters.position} onChange={e => setFilters(prev => ({ ...prev, position: e.target.value }))}>
          <option value="">All positions</option>
          <option value="PG">PG</option>
          <option value="SG">SG</option>
          <option value="SF">SF</option>
          <option value="PF">PF</option>
          <option value="C">C</option>
        </StyledSelect>
        <StyledSelect wide value={filters.team} onChange={e => setFilters(prev => ({ ...prev, team: e.target.value }))}>
          <option value="">All teams</option>
          {playerTeams.map(t => (
            <option key={t} value={t}>
              {t}
            </option>
          ))}
        </StyledSelect>
      </StyledFilters>
    </StyledPlayersFilters>
  );
}

export default PlayersFilters;
