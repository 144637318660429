import styled from '@emotion/styled';

import styles, { TableCellAlign } from './styles';

interface Props {
  cellAlign?: TableCellAlign;
  children?: React.ReactNode;
  colSpan?: number;
  fit?: 'min';
  width?: string;
}

const StyledTableData = styled.td<Pick<Props, 'cellAlign' | 'fit' | 'width'>>`
  ${styles.tableCell}
  ${styles.tableCellMin}
  border-top: 1px solid ${({ theme }) => theme.colors.builtins.grays.lightest};
  font-size: ${({ theme }) => theme.fontSizes.centi};
`;

function TableData({ children, ...rest }: Props) {
  return <StyledTableData {...rest}>{children}</StyledTableData>;
}

export default TableData;
