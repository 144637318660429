import { useState } from 'react';
import { DragDropContext, Draggable, DraggableLocation, OnDragEndResponder } from 'react-beautiful-dnd';
import { Virtuoso } from 'react-virtuoso';

import { scouting } from '@atlanta-hawks/bbops-client-js';
import styled from '@emotion/styled';

import { HeightPreservingItem, StrictModeDroppable } from '../dnd';
import { Text } from '../typography';

import PlayerCard from './player_card';
import PlayerListTitle, { ViewDetails } from './player_list_title';
import RanksImporter from './ranks_importer';

const ID_PLAYERS = 'players';
const ID_RANKINGS = 'rankings';

const styleVirtuoso = {
  marginTop: 0,
  padding: 0,
  listStyle: 'none',
  overflowY: 'auto',
  borderRadius: '8px',
  background: 'white',
} as const;

const StyledWorkspaces = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 40px calc(100vh - 365px);
  column-gap: 2rem;
  padding: 0 2rem;
  background: black;
`;

const StyledWorkspace = styled.div`
  margin: 0.5rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const StyledPlayerEmptyState = styled(Text)`
  padding-top: 2rem;
  text-align: center;
`;

const StyledPlayerList = styled.ul<{ dragging?: boolean }>`
  flex: 1;
  background: ${({ dragging, theme }) => (dragging ? theme.colors.builtins.grays.lightest : 'none')};
  opacity: ${({ dragging }) => (dragging ? 0.618 : 1)};
  ${styleVirtuoso}
`;

const StyledPlayerListContainer = styled.div<{ dragging?: boolean }>`
  flex: 1;
  background: ${({ dragging, theme }) => (dragging ? theme.colors.builtins.grays.lightest : 'none')};
  opacity: ${({ dragging }) => (dragging ? 0.618 : 1)};
  height: 100%;
  outline: none;
  position: relative;
  ${styleVirtuoso}
`;

const StyledPlayerListScroller = styled.div<{ dragging?: boolean }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
`;

interface Props {
  disabled?: boolean;
  nextSubmitDeadline?: scouting.SeasonSubmitDeadline;
  players: scouting.Player[];
  setPlayers: (players: scouting.Player[]) => void;
  rankings: scouting.Player[];
  setRankings: (players: scouting.Player[]) => void;
  ranksLabel?: string;
  ranksPlayerMeta?: Record<string, scouting.RankMeta>;
  onRankingsUpdate?: (ranks: scouting.Rank[]) => Promise<void>;
  patchRanks: (req: scouting.RanksPatchRequest) => Promise<void>;
  importRanks: () => Promise<void>;
  seasonYear: number;
}

function RanksWorksheet({
  disabled,
  nextSubmitDeadline,
  players,
  setPlayers,
  rankings,
  setRankings,
  ranksLabel = 'My Rankings',
  ranksPlayerMeta,
  onRankingsUpdate = async () => {},
  patchRanks,
  importRanks,
  seasonYear,
}: Props) {
  const [condensedPlayers, setCondensedPlayers] = useState(true);
  const [condensedRankings, setCondensedRankings] = useState(false);

  const [viewDetailsPlayers, setViewDetailsPlayers] = useState<ViewDetails>('info');
  const [viewDetailsRankings, setViewDetailsRankings] = useState<ViewDetails>('info');

  const onDragEnd: OnDragEndResponder = ({ destination: dst, source: src }) => {
    if (!dst) {
      return;
    }

    if (src.droppableId === dst.droppableId) {
      const items = reorder(src.droppableId === 'rankings' ? rankings : players, src.index, dst.index);

      if (src.droppableId === 'rankings') {
        setRankings(items);
        onRankingsUpdate(items.map(p => ({ playerId: p.personId })));
      } else {
        // setPlayers(items); // don't allow users to re-sort player list
      }
    } else {
      const result = move(
        src.droppableId === 'rankings' ? rankings : players,
        dst.droppableId === 'rankings' ? rankings : players,
        src,
        dst,
      );

      if ('players' in result && result.players.length !== players.length) {
        setPlayers(result.players);
      }
      if ('rankings' in result && result.rankings.length !== rankings.length) {
        setRankings(result.rankings);
        onRankingsUpdate(result.rankings.map(p => ({ playerId: p.personId })));
      }
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <StyledWorkspaces>
        <PlayerListTitle
          key="player_pool"
          condensed={condensedPlayers}
          setCondensed={setCondensedPlayers}
          viewDetails={viewDetailsPlayers}
          setViewDetails={setViewDetailsPlayers}
        >
          Player Pool
        </PlayerListTitle>
        <PlayerListTitle
          key="rankings"
          condensed={condensedRankings}
          setCondensed={setCondensedRankings}
          viewDetails={viewDetailsRankings}
          setViewDetails={setViewDetailsRankings}
        >
          {ranksLabel}
        </PlayerListTitle>
        <StyledWorkspace>
          <StrictModeDroppable
            droppableId={ID_PLAYERS}
            mode="virtual"
            renderClone={(provided, snapshot, rubric) => (
              <PlayerCard
                draggableProvided={provided}
                isDragging={snapshot.isDragging}
                patchRanks={patchRanks}
                player={players[rubric.source.index]}
                playerMeta={ranksPlayerMeta}
                seasonYear={seasonYear}
                tight={condensedPlayers}
                viewDetails={viewDetailsPlayers}
              />
            )}
            isDropDisabled={disabled}
          >
            {provided => (
              <Virtuoso
                components={{
                  EmptyPlaceholder: () => (
                    <StyledPlayerEmptyState>There are no more players left to rank.</StyledPlayerEmptyState>
                  ),
                  Item: HeightPreservingItem,
                }}
                scrollerRef={provided.innerRef as any}
                data={players}
                style={styleVirtuoso}
                itemContent={(index, item) => (
                  <Draggable
                    draggableId={item.personId}
                    index={index}
                    key={`${item.personId}_${index}`}
                    isDragDisabled={disabled}
                  >
                    {(provided, snapshot) => (
                      <PlayerCard
                        draggableProvided={provided}
                        isDragging={snapshot.isDragging}
                        patchRanks={patchRanks}
                        player={item}
                        playerMeta={ranksPlayerMeta}
                        seasonYear={seasonYear}
                        tight={condensedPlayers}
                        viewDetails={viewDetailsPlayers}
                      />
                    )}
                  </Draggable>
                )}
              />
            )}
          </StrictModeDroppable>
        </StyledWorkspace>
        <StyledWorkspace>
          <StrictModeDroppable droppableId={ID_RANKINGS} isDropDisabled={disabled}>
            {({ innerRef, placeholder }, { isDraggingOver }) => (
              <StyledPlayerListContainer ref={innerRef} dragging={isDraggingOver}>
                <StyledPlayerListScroller>
                  {!rankings.length && <RanksImporter importRanks={importRanks} />}
                  {!!rankings.length && (
                    <StyledPlayerList>
                      {rankings.map((player, i) => (
                        <Draggable
                          draggableId={player.personId}
                          index={i}
                          key={`${player.personId}_${i}`}
                          isDragDisabled={disabled}
                        >
                          {(provided, snapshot) => (
                            <PlayerCard
                              draggableProvided={provided}
                              isDragging={snapshot.isDragging}
                              minRanks={nextSubmitDeadline?.size}
                              patchRanks={patchRanks}
                              place={i + 1}
                              player={player}
                              playerMeta={ranksPlayerMeta}
                              seasonYear={seasonYear}
                              tight={condensedRankings}
                              viewDetails={viewDetailsRankings}
                            />
                          )}
                        </Draggable>
                      ))}
                      {placeholder}
                    </StyledPlayerList>
                  )}
                </StyledPlayerListScroller>
              </StyledPlayerListContainer>
            )}
          </StrictModeDroppable>
        </StyledWorkspace>
      </StyledWorkspaces>
    </DragDropContext>
  );
}

export default RanksWorksheet;

const reorder = <T extends any = any>(list: Iterable<T>, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = <T extends any = any>(
  source: T[],
  destination: T[],
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation,
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result: Record<string, T[]> = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
