import { Navigate } from 'react-router-dom';

import styled from '@emotion/styled';

import { useSession } from '../../contexts/session';
import urls from '../../urls';

const StyledLayout = styled.div``;

const StyledHeadline = styled.h2`
  margin: 2rem 0;
  font-size: ${({ theme }) => theme.fontSizes.giga};
  font-weight: 700;
  text-align: center;
`;

const StyledName = styled.span`
  color: ${({ theme }) => theme.colors.builtins.reds.base};
`;

const StyledBlurbHolder = styled.div`
  margin: 0 auto 1rem;
  max-width: 62.8%;
`;

const StyledBlurb = styled.p`
  color: ${({ theme }) => theme.colors.builtins.grays.base};
`;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.builtins.reds.base};
`;

function Lobby() {
  const { active } = useSession();
  if (active) {
    return <Navigate replace to={urls.ranks.url} />;
  }
  return (
    <StyledLayout>
      <StyledHeadline>
        This is the <StyledName>Scout Ranking</StyledName> tool.
      </StyledHeadline>
      <StyledBlurbHolder>
        <StyledBlurb>
          The Scout Ranking tool allows users to filter and sort their prospect rankings for the upcoming draft season. Scouts
          must submit rankings ahead of each submission deadline. All rankings are aggregated into composite sets, allowing
          users to view the Front Office's collective rankings over time.
        </StyledBlurb>
      </StyledBlurbHolder>
      <StyledBlurbHolder>
        <StyledBlurb>
          You may view your rankings <StyledLink href={urls.ranks.url}>here</StyledLink> or use any of the navigation links
          above to access the pages.
        </StyledBlurb>
      </StyledBlurbHolder>
    </StyledLayout>
  );
}

export default Lobby;
