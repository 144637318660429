import { useState } from 'react';

import { scouting } from '@atlanta-hawks/bbops-client-js';
import styled from '@emotion/styled';

import { OutlineButton } from '../../../components/buttons';
import { SpinnerButton } from '../../../components/spinner';
import { useScouting } from '../../../contexts/scouting';
import { datePicker } from '../../../core';

const SCOUTING_SEASON_SIZE_DEFAULT = 10;

const StyledNewSubmitDeadline = styled.div`
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  border-top: 1px solid black;
  display: flex;
  align-items: flex-end;
`;

const StyledNewSubmitDeadlineLabel = styled.label`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: ${({ theme }) => theme.fonts.body.shortForm};
  font-size: ${({ theme }) => theme.fontSizes.base};
  font-weight: 700;
`;

const StyledNewSubmitDeadlineInput = styled.input`
  margin-top: 0.309rem;
  margin-right: 0.618rem;
  padding: 0.309rem 0.618rem;
  width: 140px;
`;

interface Props {
  season: scouting.Season;
}

function DeadlineManagerAddForm({ season }: Props) {
  const { api, currentSeasonEnd, playersById, seasonFetch } = useScouting();

  const [newSubmitDeadline, setNewSubmitDeadline] = useState('');
  const [newSubmitDeadlineSize, setNewSubmitDeadlineSize] = useState('10');

  return (
    <StyledNewSubmitDeadline>
      <StyledNewSubmitDeadlineLabel>
        Date:
        <StyledNewSubmitDeadlineInput
          type="date"
          name="new_submit_deadline_date"
          value={newSubmitDeadline}
          onChange={e => setNewSubmitDeadline(e.target.value)}
          max={currentSeasonEnd}
        />
      </StyledNewSubmitDeadlineLabel>
      <StyledNewSubmitDeadlineLabel>
        Size:
        <StyledNewSubmitDeadlineInput
          type="number"
          name="new_submit_deadline_size"
          value={newSubmitDeadlineSize}
          onChange={e => setNewSubmitDeadlineSize(e.target.value)}
          min="1"
          max={Object.keys(playersById).length}
        />
      </StyledNewSubmitDeadlineLabel>
      <SpinnerButton
        waitForMillis={618}
        onClick={() => {
          const size = parseInt(newSubmitDeadlineSize, 10);
          if (size < 0) {
            return;
          }
          return api
            .admin()
            .season()
            .patch({
              type: 'set_submit_deadlines',
              data: [
                ...season.submitDeadlines,
                {
                  period: 0, // server sets this after sort
                  date: datePicker.parse(newSubmitDeadline),
                  size: size || SCOUTING_SEASON_SIZE_DEFAULT,
                  positions: { pg: 0, sg: 0, sf: 0, pf: 0, c: 0 },
                  collected: false,
                  published: false,
                },
              ],
            })
            .then(seasonFetch);
        }}
      >
        <OutlineButton disabled={!newSubmitDeadline} nomargin>
          Add
        </OutlineButton>
      </SpinnerButton>
    </StyledNewSubmitDeadline>
  );
}

export default DeadlineManagerAddForm;
