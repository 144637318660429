import React from 'react';
import ReactDOM from 'react-dom/client';
import Modal from 'react-modal';
import { BrowserRouter as Router } from 'react-router-dom';

import { Global, ThemeProvider } from '@emotion/react';

import App from './app';
import ApiProvider from './contexts/api';
import SessionProvider from './contexts/session';
import reportWebVitals from './reportWebVitals';
import styles, { theme } from './styles';

import 'react-tooltip/dist/react-tooltip.css';

const ID_ROOT = 'root';

Modal.setAppElement('#' + ID_ROOT);

Modal.defaultStyles.overlay!.backgroundColor = theme.colors.modal.overlay.background;
Modal.defaultStyles.overlay!.position = 'fixed';
Modal.defaultStyles.overlay!.top = '0';
Modal.defaultStyles.overlay!.left = '0';
Modal.defaultStyles.overlay!.width = '100%';
Modal.defaultStyles.overlay!.height = '100%';
Modal.defaultStyles.overlay!.zIndex = 1000;

Modal.defaultStyles.content!.position = 'fixed';
Modal.defaultStyles.content!.top = '50%';
Modal.defaultStyles.content!.left = '50%';
Modal.defaultStyles.content!.transform = 'translate(-50%, -50%)';
Modal.defaultStyles.content!.maxWidth = '100%';
Modal.defaultStyles.content!.maxHeight = '100%';
Modal.defaultStyles.content!.zIndex = 1010;

const root = ReactDOM.createRoot(document.getElementById(ID_ROOT) as HTMLElement);

root.render(
  <Router>
    <ApiProvider>
      <SessionProvider>
        <ThemeProvider theme={theme}>
          <Global styles={[styles.meyersReset, styles.typographyStyles(theme), styles.rootStyles(ID_ROOT)]} />
          <App />
        </ThemeProvider>
      </SessionProvider>
    </ApiProvider>
  </Router>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
