import { useMemo, useState } from 'react';
import { FaCheckCircle, FaExclamationCircle, FaTimesCircle } from 'react-icons/fa';
import Modal from 'react-modal';
import { Tooltip } from 'react-tooltip';

import { scouting } from '@atlanta-hawks/bbops-client-js';
import styled from '@emotion/styled';

import { Button } from '../../../components/buttons';
import List, { ListItem } from '../../../components/list';
import { SpinnerButton } from '../../../components/spinner';
import Table, { TableData, TableHeader } from '../../../components/table';
import { Heading, Text } from '../../../components/typography';
import { checkRanks, useScouting } from '../../../contexts/scouting';
import { isDate } from '../../../core';
import { HawksError } from '@hawks-ui/errors';

const StyledModalText = styled(Text)`
  margin: 0.5rem 0;
`;

const StyledErrText = styled(Text)`
  color: ${({ theme }) => theme.colors.builtins.grays.lighter};
  margin-left: 0.5rem;
`;

const StyledPastRanksSubmitRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const StyledCheckCircle = styled(FaCheckCircle)`
  color: ${({ theme }) => theme.colors.builtins.reds.base};
`;

const StyledTimesCircle = styled(FaTimesCircle)`
  color: ${({ theme }) => theme.colors.builtins.yellows.base};
`;

const StyledTableDataHolder = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledExclamationCircle = styled(FaExclamationCircle)`
  margin-left: 0.25rem;
  color: ${({ theme }) => theme.colors.builtins.yellows.base};
`;

interface Props {
  allRanks: scouting.Ranks[];
  submitDeadline?: scouting.SeasonSubmitDeadline;
  closeModal: () => void;
}

function DeadlineManagerCollectModal({ allRanks, closeModal, submitDeadline }: Props) {
  const { api, pastRanksByPeriod, pastRanksFetch, playersById, seasonFetch, today } = useScouting();

  const checkSubmitDeadlineDate = isDate(submitDeadline?.date);

  let todayIs: 'before' | 'same' | 'after' = 'after';
  if (checkSubmitDeadlineDate.same(today)) {
    todayIs = 'same';
  } else if (checkSubmitDeadlineDate.after(today)) {
    todayIs = 'before';
  }

  const pastRanks = useMemo(() => pastRanksByPeriod[submitDeadline?.period || 0] || [], [pastRanksByPeriod, submitDeadline]);

  const pastRanksChecks: Record<string, ReturnType<typeof checkRanks>> = useMemo(
    () =>
      pastRanks.reduce(
        (acc, ranks) => ({
          ...acc,
          [ranks.id]: checkRanks(
            ranks.players,
            playersById,
            ranks.players.reduce((acc, r) => ({ ...acc, [r.playerId]: r.meta }), {}),
            submitDeadline,
          ),
        }),
        {},
      ),
    [pastRanks, playersById, submitDeadline],
  );
  const [err, setErr] = useState<HawksError>();

  const readyRanks = allRanks.filter(
    ranks =>
      (!ranks.submitHistory.length ? ranks.current : ranks.submitHistory[0].players).length >= (submitDeadline?.size || 0),
  );

  return (
    <Modal
      isOpen={!!submitDeadline}
      onRequestClose={closeModal}
      style={{
        content: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
      }}
    >
      <Heading as="h3">Collect Rankings</Heading>
      {todayIs === 'before' && (
        <Text>You will be able to start collecting rankings once the submit deadline date approaches.</Text>
      )}
      <List flush>
        <ListItem>{`${allRanks.length} scout(s) ranked players`}</ListItem>
        <ListItem>{`${readyRanks.length} scout(s) completed rankings`}</ListItem>
      </List>
      <StyledModalText>
        You can click the button below to collect rankings for this period. A detailed summary of the captured data will display
        below.
      </StyledModalText>
      <StyledPastRanksSubmitRow>
        <SpinnerButton
          waitForMillis={618}
          onClick={() => {
            setErr(undefined);
            api
              .admin()
              .pastRanks()
              .collect(submitDeadline?.period || 0)
              .then(() => Promise.all([seasonFetch(), pastRanksFetch()]))
              .catch(e => {
                const err = HawksError.make(e);
                setErr(err);
              });
          }}
        >
          <Button disabled={!submitDeadline || todayIs !== 'same'} nomargin shade="reds">
            Collect Ranks
          </Button>
        </SpinnerButton>
        {err && <StyledErrText nomargin>{`${err.code}: ${err.message}`}</StyledErrText>}
      </StyledPastRanksSubmitRow>
      <StyledModalText>{`You have collected ${pastRanks.length} ranking(s) for this period:`}</StyledModalText>
      <List flush>
        <ListItem>{`${
          pastRanks.map(ranks => pastRanksChecks[ranks.id]).filter(check => check.checks.size).length
        } meet the required minimum number of ranks`}</ListItem>
        <ListItem>{`${
          pastRanks.map(ranks => pastRanksChecks[ranks.id]).filter(check => check.checks.positions).length
        } meet the required number of positional ranks`}</ListItem>
        <ListItem>{`${
          pastRanks.map(ranks => pastRanksChecks[ranks.id]).filter(check => check.checks.outcomes).length
        } meet the required number of outcomes set`}</ListItem>
      </List>
      {!!pastRanks.length && (
        <Table
          header={
            <tr>
              <TableHeader fit="min" />
              <TableHeader>Scout</TableHeader>
              <TableHeader>Submitted</TableHeader>
              <TableHeader>Players Ranked</TableHeader>
              <TableHeader>Created On</TableHeader>
              <TableHeader>Updated On</TableHeader>
            </tr>
          }
        >
          {pastRanks.map(ranks => {
            const check = pastRanksChecks[ranks.id];
            return (
              <tr key={ranks.id + ranks.updatedOn.toISOString()}>
                <TableData>
                  {check.checks.size && check.checks.positions && check.checks.outcomes ? (
                    <StyledCheckCircle />
                  ) : (
                    <StyledTimesCircle />
                  )}
                </TableData>
                <TableData>{ranks.email}</TableData>
                <TableData>{ranks.submitted ? 'Yes' : 'No'}</TableData>
                <TableData>
                  <StyledTableDataHolder>
                    {ranks.players.length}
                    {!check.checks.positions && (
                      <StyledExclamationCircle
                        data-tooltip-id="collect-modal-tooltip"
                        data-tooltip-content={JSON.stringify(check.positions, null, 2)}
                      />
                    )}
                    {!check.checks.outcomes && (
                      <StyledExclamationCircle
                        data-tooltip-id="collect-modal-tooltip"
                        data-tooltip-content={`Set ${check.outcomes.total} outcome(s)`}
                      />
                    )}
                  </StyledTableDataHolder>
                </TableData>
                <TableData>{ranks.createdOn.toLocaleTimeString()}</TableData>
                <TableData>{ranks.updatedOn.getTime() === 0 ? '--' : ranks.updatedOn.toLocaleTimeString()}</TableData>
              </tr>
            );
          })}
        </Table>
      )}
      <Tooltip id="collect-modal-tooltip" place="right-end" />
    </Modal>
  );
}

export default DeadlineManagerCollectModal;
