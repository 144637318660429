import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';

import { Theme } from '../../styles';

type Shade = keyof Theme['colors']['builtins'];

const StyledButton = styled('button', { shouldForwardProp: isPropValid })<
  Pick<Props, 'nomargin' | 'tight'> & Required<Pick<Props, 'shade'>>
>`
  min-height: 28px;
  min-width: ${({ tight }) => (tight ? '0' : '80px')};
  margin-bottom: ${({ nomargin }) => (nomargin ? '0' : '0.5rem')};
  padding: ${({ tight }) => (tight ? '0' : '0.309rem 0.618rem')};
  background: none;
  border: 2px solid ${({ theme, shade }) => theme.colors.builtins[shade].base};
  border-radius: 8px;
  cursor: pointer;
  color: ${({ theme, shade }) => theme.colors.builtins[shade].base};
  font-family: ${({ theme }) => theme.fonts.body.shortForm};
  font-size: ${({ theme }) => theme.fontSizes.base};
  font-weight: 700;

  &:disabled {
    border-color: ${({ theme, shade }) => theme.colors.builtins[shade].faded};
    color: ${({ theme, shade }) => theme.colors.builtins[shade].faded};
    cursor: auto;
  }

  &:hover,
  &:focus {
    box-shadow: ${({ theme, shade }) => theme.colors.builtins[shade].faded} 0px 2px 4px;

    &:disabled {
      box-shadow: none;
    }
  }
`;

interface Props {
  children?: React.ReactNode;
  disabled?: boolean;
  nomargin?: boolean;
  onClick?: () => void;
  shade?: Shade;
  tight?: boolean;
}

function OutlineButton({ children, shade = 'text', ...rest }: Props) {
  return (
    <StyledButton shade={shade} {...rest}>
      {children}
    </StyledButton>
  );
}

export default OutlineButton;
