import styled from '@emotion/styled';

const StyledButtonGroup = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
`;

interface Props {
  children?: React.ReactNode;
}

function ButtonGroup({ children }: Props) {
  return <StyledButtonGroup>{children}</StyledButtonGroup>;
}

export default ButtonGroup;
