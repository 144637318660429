import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { scouting } from '@atlanta-hawks/bbops-client-js';
import styled from '@emotion/styled';

import { RanksWorksheet } from '../../../components/ranks';
import { Text } from '../../../components/typography';
import { scoutDisplay, useScouting } from '../../../contexts/scouting';
import { useSession } from '../../../contexts/session';
import { Button } from '../../../components/buttons';

const StyledToolbar = styled.div`
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
`;

const StyledMeta = styled.div`
  display: grid;
  grid-template-columns: repeat(2, min-content);
  column-cap: 0.5rem;
`;

const StyledText = styled(Text)<{ bold?: boolean }>`
  white-space: nowrap;
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
`;

function CheckRanksForScout() {
  const { id } = useParams();
  const { api, nextSubmitDeadline, playersById, ranksFetch, season } = useScouting();
  const { user } = useSession();

  const [ranks, setRanks] = useState<scouting.Ranks>();
  const [ranksDiffer, setRanksDiffer] = useState(false);

  const [players, setPlayers] = useState<scouting.Player[]>([]);
  const [rankings, setRankings] = useState<scouting.Player[]>([]);

  const refreshRanks = useCallback(
    () =>
      api
        .admin()
        .ranks()
        .getOne(id || '')
        .then(setRanks),
    [api, id, setRanks],
  );

  useEffect(() => {
    if (!id) {
      return;
    }
    (async () => await refreshRanks())();
  }, [refreshRanks, id]);

  useEffect(() => {
    const currentRankings = (ranks?.current || []).map(r => playersById[r.playerId]);
    const currentRankingsById = currentRankings.reduce<Record<string, scouting.Player>>(
      (acc, r) => ({ ...acc, [r.personId]: r }),
      {},
    );

    setPlayers(
      Object.values(playersById)
        .filter(player => !player.ineligible)
        .filter(player => !currentRankingsById[player.personId]),
    );

    setRankings(currentRankings);
  }, [playersById, ranks]);

  useEffect(() => {
    if (!ranks) {
      return;
    }
    if (ranks.current.length !== rankings.length) {
      setRanksDiffer(true);
      return;
    }
    var i = ranks.current.length;
    while (i--) {
      if (ranks.current[i].playerId !== rankings[i].personId) {
        setRanksDiffer(true);
        return;
      }
    }
    setRanksDiffer(false);
  }, [ranks, rankings]);

  const patchRanks = (req: scouting.RanksPatchRequest) =>
    api
      .admin()
      .ranks()
      .patchOne(id || '', req)
      .then(refreshRanks)
      .then(() => {
        if (user && ranks?.email === user) {
          return ranksFetch();
        }
      });

  const importRanks = () =>
    api
      .admin()
      .ranks()
      .carryoverOne(id || '', { current: true })
      .then(refreshRanks)
      .then(() => {
        if (user && ranks?.email === user) {
          return ranksFetch();
        }
      });

  if (!ranks) {
    return null;
  }

  return (
    <>
      <StyledToolbar>
        <StyledMeta>
          <StyledText>Scouting season:</StyledText>
          <StyledText bold>{ranks.seasonYear}</StyledText>
          <StyledText>Viewing ranks for:</StyledText>
          <StyledText bold>{scoutDisplay(ranks.email)}</StyledText>
        </StyledMeta>
        <div>
          <Button
            shade="reds"
            disabled={!ranksDiffer && !!ranks.submitHistory.length}
            onClick={() => {
              const data = rankings.map(r => ({
                playerId: r.personId,
              }));

              api
                .admin()
                .ranks()
                .patchOne(id || '', { type: 'set_current', data })
                .then(() =>
                  api
                    .admin()
                    .ranks()
                    .patchOne(id || '', { type: 'set_submitted', data }),
                )
                .then(ranksFetch);
            }}
          >
            {ranks.submitHistory.length ? 'Save and Submit' : 'Submit'}
          </Button>
        </div>
      </StyledToolbar>
      <RanksWorksheet
        nextSubmitDeadline={nextSubmitDeadline}
        patchRanks={patchRanks}
        players={players}
        setPlayers={setPlayers}
        rankings={rankings}
        setRankings={setRankings}
        ranksLabel="Their Rankings"
        ranksPlayerMeta={ranks?.playerMeta}
        seasonYear={season?.seasonYear || 0}
        importRanks={importRanks}
      />
    </>
  );
}

export default CheckRanksForScout;
