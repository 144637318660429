import styled from '@emotion/styled';

const SIZE = 48;
const SIZE_SMALL = 24;

const StyledLogo = styled.div<{ tight?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: ${({ tight }) => (tight ? SIZE_SMALL : SIZE)}px;
`;

const StyledHeadshot = styled.img<{ tight?: boolean }>`
  max-height: 100%;
  height: ${({ tight }) => (tight ? SIZE_SMALL : SIZE)}px;
  width: ${({ tight }) => (tight ? SIZE_SMALL : SIZE) * 0.75}px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.builtins.yellows.faded};
`;

const StyledSvg = styled.svg<{ tight?: boolean }>`
  height: ${({ tight }) => (tight ? SIZE_SMALL : SIZE)}px;
  width: ${({ tight }) => (tight ? SIZE_SMALL : SIZE)}px;
`;

interface Props {
  initials: string;
  name: string;
  headshot?: string;
  tight?: boolean;
}

function PlayerCardLogo({ initials, name, headshot = '', tight }: Props) {
  return (
    <StyledLogo tight={tight}>
      {headshot && <StyledHeadshot src={headshot} alt={`${name} headshot`} tight={tight} />}
      {!headshot && (
        <StyledSvg viewBox="0 0 100 100" tight={tight}>
          <circle cx="50" cy="50" r="50" fill="none" stroke="black" />
          <text x="50%" y="50%" textAnchor="middle" dy="0.3em" stroke="black" strokeWidth="2px" fontSize="32px">
            {initials}
          </text>
        </StyledSvg>
      )}
    </StyledLogo>
  );
}

export default PlayerCardLogo;
