import { ChangeEvent, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';

import { scouting } from '@atlanta-hawks/bbops-client-js';
import styled from '@emotion/styled';

import Dropdown from '../dropdown/dropdown';
import { Text } from '../typography';

const StyledPlayerOutcomes = styled.div<{ tight?: boolean; empty?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  background: ${({ empty, theme }) => (empty ? theme.colors.team.currentScales.hawksYellow[50] : 'unset')};
`;

const StyledPlayerOutcome = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledPlayerOutcomeDesc = styled(Text)`
  margin-bottom: 0;
  margin-left: 0.5rem;
  flex: 1;
  font-size: ${({ theme }) => theme.fontSizes.centi};
`;

interface PlayerOutcomeOptionsProps {
  placeholder?: string;
}

const outcomes: scouting.RankMetaOutcome[] = [
  '',
  'MVP',
  'Franchise',
  'Core',
  'Top Starter',
  'Starter/Key Reserve',
  'Rotation',
  'Roster',
  'G-League',
];

const PlayerOutcomeOptions = ({ placeholder }: PlayerOutcomeOptionsProps) => (
  <>
    {outcomes.map(outcome => (
      <option key={outcome} value={outcome} data-tooltip-id="player-outcomes" data-tooltip-content={outcomeDescs[outcome]}>
        {outcome || placeholder}
      </option>
    ))}
  </>
);

export const outcomeDescs: Record<scouting.RankMetaOutcome, string> = {
  '': '',
  MVP: 'Top 3-5 NBA player',
  Franchise: 'Best player on a championship level team ("Batman")',
  Core: 'All-Star caliber player that defers to the franchise player ("Robin")',
  'Top Starter': 'Key piece on a team that would start on most teams in the league',
  'Starter/Key Reserve':
    'Would start on a few teams across the league but is capable of playing quality minutes in high pressure situations',
  Rotation: 'Good compliment to the player that he is backing up',
  Roster:
    'Still in the developmental stages of his game; unidentified or emerging NBA skill -- may be a few years away from being a consistent contributor to a winning team',
  'G-League': 'Player worth monitoring yet lacks NBA skills to contribute to an NBA roster',
};

const outcomeDescsTight: Record<scouting.RankMetaOutcome, string> = {
  '': '',
  MVP: 'Top 3-5 NBA player',
  Franchise: 'Best player on a championship team',
  Core: 'All-Star caliber player',
  'Top Starter': 'Key piece on a team; starter on most teams',
  'Starter/Key Reserve': 'Starter on a few teams; capable of high pressure situations',
  Rotation: 'Good compliment as a backup',
  Roster: 'Still developmental; unidentified or emerging NBA skill -- may be a few years away',
  'G-League': 'Player worth monitoring; lacks skills to contribute now',
};

interface Props {
  outcomes?: scouting.RankMetaOutcomes;
  patchRanks: (req: scouting.RanksPatchRequest) => Promise<unknown>;
  playerId: string;
  showDesc?: boolean;
  showEmpty?: boolean;
  tight?: boolean;
}

function PlayerOutcome({ outcomes: outcomesProps = {}, patchRanks, playerId, showDesc, showEmpty, tight }: Props) {
  const [outcomes, setOutcomes] = useState<scouting.RankMetaOutcomes>({});
  useEffect(() => {
    setOutcomes(outcomesProps);
  }, [outcomesProps]);

  const handleDropdownChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (!scouting.isRankMetaOutcome(e.target.value)) {
      return; // not a valid outcome value
    }
    const nextOutcomes = { ...outcomes, mid: e.target.value };
    setOutcomes(prev => ({ ...prev, ...nextOutcomes }));
    patchRanks({
      type: 'set_player_outcomes',
      data: { playerId, outcomes: nextOutcomes },
    });
  };

  if (tight) {
    return (
      <StyledPlayerOutcomes tight empty={showEmpty && !outcomes.mid}>
        <Dropdown label="Most likely outcome:" value={outcomes.mid || ''} onChange={handleDropdownChange} tight minWidth>
          <PlayerOutcomeOptions />
        </Dropdown>
        {showDesc && <StyledPlayerOutcomeDesc>{outcomeDescsTight[outcomes?.mid || '']}</StyledPlayerOutcomeDesc>}
        <Tooltip id="player-outcomes" place="right-end" />
      </StyledPlayerOutcomes>
    );
  }

  return (
    <StyledPlayerOutcomes empty={showEmpty && !outcomes.mid}>
      <StyledPlayerOutcome>
        <Dropdown
          label="Most likely outcome:"
          value={outcomes.mid || ''}
          onChange={handleDropdownChange}
          tight={tight}
          minWidth
        >
          <PlayerOutcomeOptions />
        </Dropdown>
      </StyledPlayerOutcome>
      {showDesc && <StyledPlayerOutcomeDesc>{outcomeDescs[outcomes?.mid || '']}</StyledPlayerOutcomeDesc>}
      <Tooltip id="player-outcomes" place="right-end" />
    </StyledPlayerOutcomes>
  );
}

export default PlayerOutcome;
