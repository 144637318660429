import styled from '@emotion/styled';

const headingSize = (as: HeadingType) => {
  switch (as) {
    case 'h1':
      return 'peta';
    case 'h2':
      return 'tera';
    case 'h3':
      return 'giga';
  }
};

const StyledHeading = styled.h1<Pick<Props, 'nomargin'> & Required<Pick<Props, 'as'>>>`
  margin: unset;
  margin-bottom: ${({ nomargin }) => (nomargin ? '0' : '0.618rem')};
  font-family: ${({ theme }) => theme.fonts.heading};
  font-size: ${({ theme, as }) => theme.fontSizes[headingSize(as)]};
  font-weight: 700;
  color: ${({ theme, as }) => (as === 'h1' ? 'black' : theme.colors.builtins.grays.base)};
`;

type HeadingType = 'h1' | 'h2' | 'h3';

interface Props {
  as?: HeadingType;
  children?: React.ReactNode;
  nomargin?: boolean;
}

function Heading({ as = 'h1', children, ...rest }: Props) {
  return (
    <StyledHeading as={as} {...rest}>
      {children}
    </StyledHeading>
  );
}

export default Heading;
