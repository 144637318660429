import { StyledArg } from '../../styles';

export type TableCellAlign = 'left' | 'center' | 'right';

const tableCell: StyledArg<{ cellAlign?: TableCellAlign; width?: string }> = ({ theme, cellAlign = 'center', width }) => ({
  padding: '0.309rem 0.309rem',
  fontFamily: theme.fonts.body.longForm,
  textAlign: cellAlign,
  width,
});

const tableCellMin: StyledArg<{ fit?: 'min' }> = ({ fit }) =>
  fit === 'min' && {
    width: '1%',
    whiteSpace: 'nowrap',
  };

const styles = {
  tableCell,
  tableCellMin,
};

export default styles;
