import { useEffect, useState } from 'react';

import { useLocation, Navigate } from 'react-router-dom';
import { useApi } from '../../contexts/api';

import { useSession, ScoutingRole } from '../../contexts/session';
import urls from '../../urls';

import Loader from '../loader';

type AccessLevel = '' | 'admin' | 'super';

interface Props {
  accessLevel?: AccessLevel;
  children: React.ReactElement;
}

const accessCheck = (accessLevel: AccessLevel) => (g: string) => {
  const isSuper = g === ScoutingRole.Super;
  switch (accessLevel) {
    case 'super':
      return isSuper;
    case 'admin':
      return isSuper || g === ScoutingRole.Admin;
  }
  return false;
};

function PrivateRoute({ accessLevel = '', children }: Props) {
  const { bbauth } = useApi();
  const { active, loading: sessionLoading, userRoles } = useSession();
  const location = useLocation();
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    if (sessionLoading) {
      return;
    }

    if (!active) {
      (async () => {
        setPageLoading(true);
        try {
          const res = await bbauth.api.oauth().loginStart();
          window.location.href = res.url;
        } catch (e) {
          console.warn('failed to start login', e);
        } finally {
          setPageLoading(false);
        }
      })();
    }
  }, [active, sessionLoading, bbauth]);

  if (sessionLoading || pageLoading) {
    return <Loader />;
  }

  if (active && (accessLevel === '' || userRoles.some(accessCheck(accessLevel)))) {
    return children;
  }

  return (
    <Navigate
      replace
      to={accessLevel ? urls.restricted.url : urls.lobby.url}
      state={{ from: location.state?.from, accessLevel }}
    />
  );
}

export default PrivateRoute;
