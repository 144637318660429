import { useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';

import { scouting } from '@atlanta-hawks/bbops-client-js';
import styled from '@emotion/styled';

import { HamburgerIcon } from '../icons';
import { Subheading } from '../typography';

import PlayerInfo from './player_info';
import { ViewDetails } from './player_list_title';
import PlayerLogo from './player_logo';
import PlayerOutcome from './player_outcome';
import PlayerWithdrawn from './player_withdrawn';

const StyledPlayerCard = styled.li<Pick<Props, 'isDragging' | 'tight'> & { ineligible?: boolean }>`
  opacity: ${({ isDragging }) => (isDragging ? 0.25 : 1)};
  padding: 0.309rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  cursor: move;
  background: ${({ theme, ineligible }) =>
    ineligible ? 'black' : `linear-gradient(white 72%, ${theme.colors.builtins.grays.lightest})`};
`;

const StyledTier = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.builtins.grays.lighter};
  margin-right: 0.309rem;
`;

const StyledTierLabel = styled(Subheading)`
  font-size: ${({ theme }) => theme.fontSizes.milli};
  margin-bottom: 0.1rem;
`;

const StyledPlace = styled(Subheading)<{ tight?: boolean }>`
  text-align: center;
  flex-basis: ${({ tight }) => (tight ? '20px' : '30px')};
  font-size: ${({ theme, tight }) => theme.fontSizes[tight ? 'base' : 'kilo']};
  margin-right: 0.309rem;
`;

const styleNameWidth = ({ tight }: { tight?: boolean }) => (tight ? '140px' : '180px');

const StyledNameTag = styled.div<{ tight?: boolean }>`
  margin-left: 0.309rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-basis: ${styleNameWidth};
  flex-grow: 0;
  flex-shrink: 0;
`;

const StyledName = styled.a<{ ineligible?: boolean; tight?: boolean }>`
  text-decoration-color: ${({ theme, ineligible }) => (ineligible ? 'white' : theme.colors.builtins.text.base)};
  & h4 {
    font-size: ${({ theme, tight }) => theme.fontSizes[tight ? 'base' : 'kilo']};
  }
`;

const StyledNameText = styled(Subheading)<{ ineligible?: boolean }>`
  ${({ ineligible }) => ineligible && { color: 'white' }}
`;

const StyledTeam = styled(Subheading)<{ tight?: boolean }>`
  font-size: ${({ theme, tight }) => theme.fontSizes[tight ? 'milli' : 'base']};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: ${styleNameWidth};
`;

const StyledPosition = styled(Subheading)`
  font-size: ${({ theme }) => theme.fontSizes.deka};
  flex-basis: 20px;
  text-align: center;
`;

const StyledDetails = styled.div<{ shaded?: boolean; tight?: boolean }>`
  align-self: stretch;
  flex: 1;
  margin: 0 0.618rem;
  padding: ${({ tight }) => (tight ? '0' : '0.309rem')};
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-left: 0.5rem;
  border-left: 4px solid ${({ theme }) => theme.colors.builtins.grays.lightest};
  border-radius: 4px;
`;

const StyledHamburgerIcon = styled(HamburgerIcon)`
  margin-left: 0.5rem;
`;

interface Props {
  draggableProvided: DraggableProvided;
  isDragging: boolean;
  minRanks?: number;
  place?: number;
  player: scouting.Player;
  playerMeta?: Record<string, scouting.RankMeta>;
  patchRanks: (req: scouting.RanksPatchRequest) => Promise<unknown>;
  seasonYear: number;
  tight?: boolean;
  viewDetails: ViewDetails;
  tier?: number;
}

function PlayerCard({
  draggableProvided,
  minRanks = 0,
  place,
  player,
  playerMeta = {},
  patchRanks,
  seasonYear,
  tight,
  viewDetails,
  tier,
  ...rest
}: Props) {
  const prospectPageName = `${player.firstName.toLowerCase()}-${player.lastName.toLowerCase()}_prospect_dashboard.html`;
  const prospectPagePath = `/prospect_player_pages/${player.seasonYear}/${player.dxPlayerId}_${prospectPageName}`;

  const { outcomes } = playerMeta[player.personId] || {};

  const [hovered, setHovered] = useState(false);

  return (
    <StyledPlayerCard
      {...rest}
      {...draggableProvided.draggableProps}
      {...draggableProvided.dragHandleProps}
      ref={draggableProvided.innerRef}
      tight={tight}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      ineligible={player.ineligible}
    >
      {place && <StyledPlace as="h6" nomargin tight={tight}>{`#${place}`}</StyledPlace>}
      {tier && (
        <StyledTier>
          <StyledTierLabel as="h6" nomargin>{`Tier`}</StyledTierLabel>
          <Subheading as="h5" nomargin>
            {tier}
          </Subheading>
        </StyledTier>
      )}
      <PlayerLogo name={player.name} initials={player.initials} headshot={player.headshot} tight={tight} />
      <StyledNameTag tight={tight}>
        <StyledName
          href={process.env.REACT_APP_ANALYTICS_SITE_URL + prospectPagePath}
          target="_blank"
          rel="nofollow noreferrer"
          tight={tight}
          ineligible={player.ineligible}
        >
          <StyledNameText ineligible={player.ineligible} nomargin>
            {player.name}
          </StyledNameText>
        </StyledName>
        <StyledTeam as="h5" nomargin tight={tight}>
          {player.team}
        </StyledTeam>
      </StyledNameTag>
      <StyledPosition as="h5" nomargin>
        {player.position}
      </StyledPosition>
      <StyledDetails shaded={viewDetails === 'info'} tight={tight}>
        {player.ineligible && hovered && <PlayerWithdrawn />}
        {!player.ineligible && viewDetails === 'info' && <PlayerInfo player={player} seasonYear={seasonYear} tight={tight} />}
        {!player.ineligible && viewDetails === 'outcomes' && (
          <PlayerOutcome
            outcomes={outcomes}
            patchRanks={patchRanks}
            playerId={player.personId}
            showDesc={hovered}
            showEmpty={!!place && place <= minRanks}
            tight={tight}
          />
        )}
      </StyledDetails>
      <StyledHamburgerIcon tight={tight} />
    </StyledPlayerCard>
  );
}

export default PlayerCard;
