import { useState } from 'react';
import Modal from 'react-modal';

import { scouting } from '@atlanta-hawks/bbops-client-js';
import styled from '@emotion/styled';

import { Button, OutlineButton } from '../../../components/buttons';
import Table, { TableHeader } from '../../../components/table';
import { Text } from '../../../components/typography';
import { scoutDisplay } from '../../../contexts/scouting';

import TabbleRow from './check_ranks_table_row';

const StyledConfirmation = styled(Text)`
  font-weight: 700;
`;

const StyledButtonGroup = styled.div`
  display: flex;
  & > :not(:first-of-type) {
    margin-left: 0.5rem;
  }
`;

interface Props {
  allRanks: scouting.Ranks[];
  deleteRanks: (id: string) => Promise<unknown>;
  patchRanks: (id: string, req: scouting.RanksPatchRequest) => Promise<unknown>;
  refreshRanks: () => Promise<void>;
  playersById: Record<string, scouting.Player>;
}

function CheckRanksTable({ allRanks, deleteRanks, patchRanks, refreshRanks, playersById }: Props) {
  const [scoutRanksToDelete, setScoutRanksToDelete] = useState<scouting.Ranks>();
  const [submittedRanksToDelete, setSubmittedRanksToDelete] = useState<{ ranks: scouting.Ranks; date: string }>();
  return (
    <>
      <Table
        header={
          <>
            <tr>
              <TableHeader />
              <TableHeader>Scout</TableHeader>
              <TableHeader>Total</TableHeader>
              <TableHeader>PG</TableHeader>
              <TableHeader>SG</TableHeader>
              <TableHeader>SF</TableHeader>
              <TableHeader>PF</TableHeader>
              <TableHeader>C</TableHeader>
              <TableHeader>Created on</TableHeader>
              <TableHeader>Last updated</TableHeader>
              <TableHeader />
            </tr>
          </>
        }
      >
        {allRanks.map(ranks => (
          <TabbleRow
            key={ranks.id}
            ranks={ranks}
            playersById={playersById}
            showDeleteRanks={() => setScoutRanksToDelete(ranks)}
            showDeleteSubmitted={date => setSubmittedRanksToDelete({ ranks, date })}
          />
        ))}
      </Table>
      <Modal isOpen={!!scoutRanksToDelete} onRequestClose={() => setScoutRanksToDelete(undefined)}>
        {scoutRanksToDelete && (
          <>
            <Text>{`You have selected to delete the rankings for ${scoutDisplay(scoutRanksToDelete.email)}.`}</Text>
            <StyledConfirmation>Are you sure you want to delete this?</StyledConfirmation>
            <StyledButtonGroup>
              <Button
                shade="reds"
                onClick={() =>
                  deleteRanks(scoutRanksToDelete.id)
                    .then(refreshRanks)
                    .then(() => setScoutRanksToDelete(undefined))
                }
              >
                Delete
              </Button>
              <OutlineButton onClick={() => setScoutRanksToDelete(undefined)}>Cancel</OutlineButton>
            </StyledButtonGroup>
          </>
        )}
      </Modal>
      <Modal isOpen={!!submittedRanksToDelete} onRequestClose={() => setSubmittedRanksToDelete(undefined)}>
        {submittedRanksToDelete && (
          <>
            <Text>{`You have selected to delete the submitted rankings for ${scoutDisplay(submittedRanksToDelete.ranks.email)} on ${submittedRanksToDelete.date}.`}</Text>
            <StyledConfirmation>Are you sure you want to delete this?</StyledConfirmation>
            <StyledButtonGroup>
              <Button
                shade="reds"
                onClick={() =>
                  patchRanks(submittedRanksToDelete.ranks.id, {
                    type: 'remove_submitted',
                    data: [submittedRanksToDelete.date],
                  })
                    .then(refreshRanks)
                    .then(() => setSubmittedRanksToDelete(undefined))
                }
              >
                Delete
              </Button>
              <OutlineButton onClick={() => setSubmittedRanksToDelete(undefined)}>Cancel</OutlineButton>
            </StyledButtonGroup>
          </>
        )}
      </Modal>
    </>
  );
}

export default CheckRanksTable;
