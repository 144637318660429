import { useEffect, useMemo, useState } from 'react';

import { scouting } from '@atlanta-hawks/bbops-client-js';
import styled from '@emotion/styled';

import { OutlineButton } from '../../../components/buttons';
import Dropdown from '../../../components/dropdown';
import { Text } from '../../../components/typography';

import { TodayIs } from './types';

const StyledToolbar = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors.site.navbar.border};
  border-bottom: 1px solid ${({ theme }) => theme.colors.site.navbar.border};
`;

const StyledMeta = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  column-gap: 0.5rem;
  align-items: baseline;
`;

const StyledMetaLabel = styled(Text)`
  font-weight: 700;
  white-space: nowrap;
`;

const StyledConfig = styled.div`
  display: flex;
  align-items: baseline;
`;

const StyledConfigTitle = styled(Text)`
  font-weight: 700;
`;

const StyledConfigControls = styled.div`
  margin-left: 0.5rem;
  display: flex;
  background: ${({ theme }) => theme.colors.site.navbar.bg};
  border-radius: 8px;
  padding: 0.5rem;
`;

const StyledConfigLabel = styled.label`
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
  width: 60px;
  font-size: ${({ theme }) => theme.fontSizes.centi};
  font-weight: 700;

  & > input {
    background: white;
    border-radius: 6px;
    padding: 0.25rem;
  }
`;

const StyledSaveConfig = styled(OutlineButton)`
  margin-left: 0.5rem;
  align-self: center;
`;

interface Props {
  patchSeason: (req: scouting.SeasonPatchRequest) => Promise<unknown>;
  season: scouting.Season;
  submitDeadline?: scouting.SeasonSubmitDeadline;
  setSubmitDeadline: (submitDeadline?: scouting.SeasonSubmitDeadline) => void;
  todayIs?: TodayIs;
}

function CollectAndPublishToolbar({ patchSeason, season, submitDeadline, setSubmitDeadline, todayIs }: Props) {
  const [configuredMinRanks, setConfiguredMinRanks] = useState(0);
  const [configuredMinPG, setConfiguredMinPG] = useState(0);
  const [configuredMinSG, setConfiguredMinSG] = useState(0);
  const [configuredMinSF, setConfiguredMinSF] = useState(0);
  const [configuredMinPF, setConfiguredMinPF] = useState(0);
  const [configuredMinC, setConfiguredMinC] = useState(0);

  useEffect(() => {
    if (!submitDeadline) {
      return;
    }
    setConfiguredMinRanks(submitDeadline.size);
    setConfiguredMinPG(submitDeadline.positions.pg);
    setConfiguredMinSG(submitDeadline.positions.sg);
    setConfiguredMinSF(submitDeadline.positions.sf);
    setConfiguredMinPF(submitDeadline.positions.pf);
    setConfiguredMinC(submitDeadline.positions.c);
  }, [submitDeadline]);

  const configDiffers = useMemo(
    () =>
      submitDeadline &&
      (configuredMinRanks !== submitDeadline.size ||
        configuredMinPG !== submitDeadline.positions.pg ||
        configuredMinSG !== submitDeadline.positions.sg ||
        configuredMinSF !== submitDeadline.positions.sf ||
        configuredMinPF !== submitDeadline.positions.pf ||
        configuredMinC !== submitDeadline.positions.c),
    [configuredMinRanks, configuredMinPG, configuredMinSG, configuredMinSF, configuredMinPF, configuredMinC, submitDeadline],
  );

  const configureNumber = (set: (n: number) => void, v: string) => {
    if (!v) {
      set(0);
    }
    const n = parseInt(v, 10);
    if (!isNaN(n)) {
      set(n);
    }
  };

  return (
    <StyledToolbar>
      <StyledMeta>
        <StyledMetaLabel>Current season:</StyledMetaLabel>
        <Text>{season.seasonYear}</Text>
        <StyledMetaLabel>Submission period:</StyledMetaLabel>
        <Dropdown
          placeholder="Select submission period"
          value={'' + (submitDeadline?.period || '')}
          onChange={e => setSubmitDeadline(season.submitDeadlines.find(d => '' + d.period === e.target.value))}
        >
          {season.submitDeadlines.map(deadline => (
            <option
              key={'submit_deadline_' + deadline.date.toISOString() + deadline.period}
              value={'' + deadline.period}
            >{`Period #${deadline.period}`}</option>
          ))}
        </Dropdown>
      </StyledMeta>
      {submitDeadline && (
        <StyledConfig>
          <StyledConfigTitle>Submission configuration:</StyledConfigTitle>
          <StyledConfigControls>
            <StyledConfigLabel>
              Min ranks:
              <input
                type="number"
                disabled={!submitDeadline || todayIs === 'after'}
                value={configuredMinRanks}
                min={0}
                onChange={e => configureNumber(setConfiguredMinRanks, e.target.value)}
              />
            </StyledConfigLabel>
            <StyledConfigLabel>
              Min PG:
              <input
                type="number"
                disabled={!submitDeadline || todayIs === 'after'}
                value={configuredMinPG}
                min={0}
                onChange={e => configureNumber(setConfiguredMinPG, e.target.value)}
              />
            </StyledConfigLabel>
            <StyledConfigLabel>
              Min SG:
              <input
                type="number"
                disabled={!submitDeadline || todayIs === 'after'}
                value={configuredMinSG}
                min={0}
                onChange={e => configureNumber(setConfiguredMinSG, e.target.value)}
              />
            </StyledConfigLabel>
            <StyledConfigLabel>
              Min SF:
              <input
                type="number"
                disabled={!submitDeadline || todayIs === 'after'}
                value={configuredMinSF}
                min={0}
                onChange={e => configureNumber(setConfiguredMinSF, e.target.value)}
              />
            </StyledConfigLabel>
            <StyledConfigLabel>
              Min PF:
              <input
                type="number"
                disabled={!submitDeadline || todayIs === 'after'}
                value={configuredMinPF}
                min={0}
                onChange={e => configureNumber(setConfiguredMinPF, e.target.value)}
              />
            </StyledConfigLabel>
            <StyledConfigLabel>
              Min C:
              <input
                type="number"
                disabled={!submitDeadline || todayIs === 'after'}
                value={configuredMinC}
                min={0}
                onChange={e => configureNumber(setConfiguredMinC, e.target.value)}
              />
            </StyledConfigLabel>
          </StyledConfigControls>
          <StyledSaveConfig
            shade="reds"
            disabled={!configDiffers}
            nomargin
            onClick={() => {
              const newSubmitDeadlines = [...season.submitDeadlines];
              newSubmitDeadlines[submitDeadline.period - 1] = {
                ...submitDeadline,
                size: configuredMinRanks,
                positions: {
                  pg: configuredMinPG,
                  sg: configuredMinSG,
                  sf: configuredMinSF,
                  pf: configuredMinPF,
                  c: configuredMinC,
                },
              };
              patchSeason({ type: 'set_submit_deadlines', data: newSubmitDeadlines });
            }}
          >
            Save config
          </StyledSaveConfig>
        </StyledConfig>
      )}
    </StyledToolbar>
  );
}

export default CollectAndPublishToolbar;
