import { createContext, useContext, useState, useEffect, ReactNode, useMemo } from 'react';

import { scouting, server, ScoutingApi } from '@atlanta-hawks/bbops-client-js';
import { HawksError } from '@hawks-ui/errors';
import moment from 'moment';

import { isDate } from '../core';

import { useApi } from './api';
import { useSession } from './session';

interface ContextProps {
  api: ScoutingApi;
  initialized: boolean;

  runtime: server.Runtime;

  today: Date;
  currentSeason: number;
  currentSeasonEnd: string;

  currentPeriod: number;
  nextSubmitDeadline?: scouting.SeasonSubmitDeadline;

  seasons: scouting.Season[];
  season?: scouting.Season;
  setSeason: (season?: scouting.Season) => void;
  seasonFetch: () => Promise<void>;

  playerPool: scouting.Player[];
  playersById: Record<string, scouting.Player>;

  ranks?: scouting.Ranks;
  ranksFetch: () => Promise<void>;

  currentRanksById: Record<string, true>;

  compositeRanks: scouting.CompositeRanks[];
  compositeRanksByPeriod: Record<string, scouting.CompositeRanks[]>;
  compositeRanksFetch: () => Promise<void>;

  pastRanksByPeriod: Record<number, scouting.PastRanks[]>;
  pastRanksFetch: () => Promise<void>;
}

export const checkRankEmpty: ReturnType<typeof checkRanks> = {
  checks: { size: false, ineligible: false, outcomes: false, positions: false },
  positions: { pg: 0, sg: 0, sf: 0, pf: 0, c: 0 },
  outcomes: { total: 0, pg: 0, sg: 0, sf: 0, pf: 0, c: 0 },
  size: 0,
};

export const checkRanks = (
  ranks: scouting.Rank[],
  playersById: Record<string, scouting.Player>,
  ranksMeta: Record<string, scouting.RankMeta> = {},
  submitDeadline?: scouting.SeasonSubmitDeadline,
) => {
  return ranks.reduce(
    (acc, r, i) => {
      const p = playersById[r.playerId];
      if (!p) {
        return acc;
      }

      if (p.ineligible) {
        acc.checks.ineligible = true;
      }

      const m = ranksMeta[r.playerId];

      // increment the total number of ranks
      acc.size++;

      switch (p.position) {
        case 'PG':
          // increment the total number of pgs
          acc.positions.pg++;
          // if an outcome was set
          if (m && m.outcomes.mid) {
            // and if this pg counts towards the required minimum
            if (!submitDeadline?.positions.pg || acc.positions.pg <= submitDeadline?.positions.pg) {
              // increment the number of pg outcomes
              acc.outcomes.pg++;
            }
            // and if this rank counts towards the requirem total minimum
            if (i < (submitDeadline?.size || 0)) {
              // increment the number of total outcomes
              acc.outcomes.total++;
            }
          }
          break;
        case 'SG':
          acc.positions.sg++;
          if (m && m.outcomes.mid) {
            if (!submitDeadline?.positions.sg || acc.positions.sg <= submitDeadline?.positions.sg) {
              acc.outcomes.sg++;
            }
            if (i < (submitDeadline?.size || 0)) {
              acc.outcomes.total++;
            }
          }
          break;
        case 'SF':
          acc.positions.sf++;
          if (m && m.outcomes.mid) {
            if (!submitDeadline?.positions.sf || acc.positions.sf <= submitDeadline?.positions.sf) {
              acc.outcomes.sf++;
            }
            if (i < (submitDeadline?.size || 0)) {
              acc.outcomes.total++;
            }
          }
          break;
        case 'PF':
          acc.positions.pf++;
          if (m && m.outcomes.mid) {
            if (!submitDeadline?.positions.pf || acc.positions.pf <= submitDeadline?.positions.pf) {
              acc.outcomes.pf++;
            }
            if (i < (submitDeadline?.size || 0)) {
              acc.outcomes.total++;
            }
          }
          break;
        case 'C':
          acc.positions.c++;
          if (m && m.outcomes.mid) {
            if (!submitDeadline?.positions.c || acc.positions.c <= submitDeadline?.positions.c) {
              acc.outcomes.c++;
            }
            if (i < (submitDeadline?.size || 0)) {
              acc.outcomes.total++;
            }
          }
          break;
      }

      if (acc.size >= (submitDeadline?.size || 0)) {
        acc.checks.size = true;
      }
      if (
        acc.positions.pg >= (submitDeadline?.positions?.pg || 0) &&
        acc.positions.sg >= (submitDeadline?.positions?.sg || 0) &&
        acc.positions.sf >= (submitDeadline?.positions?.sf || 0) &&
        acc.positions.pf >= (submitDeadline?.positions?.pf || 0) &&
        acc.positions.c >= (submitDeadline?.positions?.c || 0)
      ) {
        acc.checks.positions = true;
      }
      if (
        acc.outcomes.total >= (submitDeadline?.size || 0) &&
        acc.outcomes.pg >= (submitDeadline?.positions?.pg || 0) &&
        acc.outcomes.sg >= (submitDeadline?.positions?.sg || 0) &&
        acc.outcomes.sf >= (submitDeadline?.positions?.sf || 0) &&
        acc.outcomes.pf >= (submitDeadline?.positions?.pf || 0) &&
        acc.outcomes.c >= (submitDeadline?.positions?.c || 0)
      ) {
        acc.checks.outcomes = true;
      }
      return acc;
    },
    {
      checks: { size: false, ineligible: false, outcomes: false, positions: false },
      positions: { pg: 0, sg: 0, sf: 0, pf: 0, c: 0 },
      outcomes: { total: 0, pg: 0, sg: 0, sf: 0, pf: 0, c: 0 },
      size: 0,
    },
  );
};

const Context = createContext<ContextProps>(null!);

interface Props {
  children: ReactNode;
}

function ScoutingProvider({ children }: Props) {
  const { bbops } = useApi();
  const { active: sessionActive, loading: sessionLoading } = useSession();

  const defaultCurrentSeason = useMemo(() => new Date().getFullYear() + 1, []);

  const [initialized, setInitialized] = useState(false);

  const [runtime, setRuntime] = useState<server.Runtime>({
    config: '',
    env: '',
    gitHash: 'abcdefg',
    buildTime: new Date(0).toJSON(),
  });

  const [playerPool, setPlayerPool] = useState<scouting.Player[]>([]);
  const [playersById, setPlayersById] = useState<Record<string, scouting.Player>>({});

  const [seasons, setSeasons] = useState<scouting.Season[]>([]);
  const [season, setSeason] = useState<scouting.Season>();

  const scoutingApi = useMemo(
    () => bbops.api.scouting(season?.seasonYear || defaultCurrentSeason),
    [defaultCurrentSeason, season], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const [ranks, setRanks] = useState<scouting.Ranks>();
  const [currentRanksById, setCurrentRanksById] = useState<Record<string, true>>({});

  const [compositeRanks, setCompositeRanks] = useState<scouting.CompositeRanks[]>([]);
  const [compositeRanksByPeriod, setCompositeRanksByPeriod] = useState<Record<number, scouting.CompositeRanks[]>>({});

  const [pastRanksByPeriod, setPastRanksByPeriod] = useState<Record<number, scouting.PastRanks[]>>({});

  const today = useMemo(() => new Date(), []);
  const checkToday = isDate(today);

  const nextSubmitDeadline = (season?.submitDeadlines || []).find(submitDeadline => {
    if (checkToday.same(submitDeadline.date)) {
      return true;
    }
    if (checkToday.before(submitDeadline.date)) {
      return true;
    }
    return false;
  });

  const currentPeriod = nextSubmitDeadline?.period || 0;

  useEffect(() => {
    (async () => {
      try {
        const runtime = await bbops.api.server().runtime();
        setRuntime(runtime);
      } catch (e) {
        console.warn('failed to get bbops runtime', e);
      }

      if (sessionLoading || !sessionActive) {
        return;
      }

      const seasons = await bbops.api.scoutingSeasons().get();
      setSeasons(seasons);
      setSeason(!!seasons.length ? seasons[0] : undefined);
    })();
  }, [sessionActive, sessionLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    (async () => {
      try {
        if (!season) {
          return;
        }
        const [players, ranks, compositeRanks, pastRanks] = await Promise.all([
          scoutingApi.players().get(),
          scoutingApi
            .ranks()
            .get()
            .catch(e => {
              const err = HawksError.make(e);
              if (err.code !== 'not_found') {
                throw e;
              }
              return scoutingApi.ranks().create();
            }),
          scoutingApi.compositeRanks().get(),
          scoutingApi.pastRanks().get(),
        ]);

        const currentRanksById: Record<string, true> = ranks.current.reduce(
          (acc, rank) => ({ ...acc, [rank.playerId]: true }),
          {},
        );

        setPlayerPool(players.filter(player => !player.ineligible).filter(player => !currentRanksById[player.personId]));
        setPlayersById(players.reduce((acc, player) => ({ ...acc, [player.personId]: player }), {}));

        setRanks(ranks);
        setCurrentRanksById(currentRanksById);

        setCompositeRanks(compositeRanks);
        setCompositeRanksByPeriod(
          compositeRanks.reduce<Record<string, scouting.CompositeRanks[]>>((acc, ranks) => {
            return {
              ...acc,
              [String(ranks.period)]: [...(acc[String(ranks.period)] || []), ranks],
            };
          }, {}),
        );

        setPastRanksByPeriod(
          pastRanks.reduce<Record<number, scouting.PastRanks[]>>((acc, ranks) => {
            return {
              ...acc,
              [ranks.period]: [...(acc[ranks.period] || []), ranks],
            };
          }, {}),
        );
      } catch (err) {
        console.error('failed to initialize scouting provider', err);
      } finally {
        setInitialized(true);
      }
    })();
  }, [season, scoutingApi]);

  const seasonFetch = async () => scoutingApi.season().get().then(setSeason);

  const ranksFetch = async () =>
    scoutingApi
      .ranks()
      .get()
      .then(res => {
        setRanks(res);
        const currentById: Record<string, true> = res.current.reduce((acc, rank) => ({ ...acc, [rank.playerId]: true }), {});
        setPlayerPool(playerPool.filter(player => !currentById[player.personId]));
      });

  const pastRanksFetch = async () =>
    scoutingApi
      .pastRanks()
      .get()
      .then(res => {
        setPastRanksByPeriod(
          res.reduce<Record<number, scouting.PastRanks[]>>((acc, ranks) => {
            return {
              ...acc,
              [ranks.period]: [...(acc[ranks.period] || []), ranks],
            };
          }, {}),
        );
      });

  const compositeRanksFetch = async () =>
    scoutingApi
      .compositeRanks()
      .get()
      .then(res => {
        setCompositeRanksByPeriod(
          res.reduce<Record<string, scouting.CompositeRanks[]>>((acc, ranks) => {
            return {
              ...acc,
              [String(ranks.period)]: [...(acc[String(ranks.period)] || []), ranks],
            };
          }, {}),
        );
      });

  return (
    <Context.Provider
      value={{
        api: scoutingApi,
        initialized,
        runtime,
        today,
        currentSeason: season?.seasonYear || defaultCurrentSeason,
        currentSeasonEnd: `${season?.seasonYear || defaultCurrentSeason}-10-01`,
        currentPeriod,
        currentRanksById,
        nextSubmitDeadline,
        playerPool,
        playersById,
        seasons,
        season,
        setSeason,
        seasonFetch,
        ranks,
        ranksFetch,
        compositeRanks,
        compositeRanksByPeriod,
        compositeRanksFetch,
        pastRanksByPeriod,
        pastRanksFetch,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default ScoutingProvider;

export function useScouting() {
  return useContext(Context);
}

export function scoutDisplay(email: string) {
  return email
    .replace('@hawks.com', '')
    .replace('@hawks404.com', '')
    .split('.')
    .map(s => {
      switch (s.length) {
        case 0:
          return '';
        case 1:
          return s.toUpperCase();
        default:
          return s.substring(0, 1).toUpperCase() + s.substring(1);
      }
    })
    .map(s => s.trim())
    .filter(s => !!s)
    .join(' ');
}

export function playerAge(seasonYear: number, birthdate?: string) {
  if (!birthdate) {
    return '';
  }

  const target = [seasonYear, 9, 1];

  const parsed = moment(birthdate.split('-').map((n, i) => parseInt(n, 10) - (i === 1 ? 1 : 0)));

  const years = moment(target).diff(parsed, 'years');

  const then = [target[0] - years, target[1], target[2]];

  const days = moment(then).diff(parsed, 'days');

  const age = years + days / 365;

  return `${age.toFixed(1)} yrs`;
}
