import { useEffect, useState } from 'react';

import { scouting } from '@atlanta-hawks/bbops-client-js';
import styled from '@emotion/styled';

import { Heading } from '../../../components/typography';
import { useScouting } from '../../../contexts/scouting';
import { useSession } from '../../../contexts/session';

import EmptyState from './empty_state';
import StateTable from './state_table';

const StyledViewScouts = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

function ViewScouts() {
  const { user } = useSession();
  const { api, initialized } = useScouting();

  const [allRanks, setAllRanks] = useState<scouting.Ranks[]>([]);
  useEffect(() => {
    if (!user || !initialized) {
      return;
    }

    (async () => await api.admin().ranks().getAll().then(setAllRanks))();
  }, [api, initialized, user]);

  return (
    <>
      <StyledViewScouts>
        <Heading as="h3" nomargin>
          Scouts Ranks
        </Heading>
        {!allRanks.length && <EmptyState />}
        {!!allRanks.length && <StateTable allRanks={allRanks} />}
      </StyledViewScouts>
    </>
  );
}

export default ViewScouts;
