import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import Dropdown from '../components/dropdown';
import { Heading, Text } from '../components/typography';
import { useSession } from '../contexts/session';
import { useScouting } from '../contexts/scouting';
import urls from '../urls';

const StyledTitleBar = styled.div`
  flex: none;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.builtins.reds.base};
  height: 50px;
  align-items: stretch;
  border-top: 1px solid ${({ theme }) => theme.colors.builtins.reds.base};
`;

const StyledHeadline = styled(Link)`
  background: ${({ theme }) => theme.colors.builtins.reds.base};
  text-decoration: none;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;

  & > h1 {
    color: white;
  }
`;

const StyledBulletin = styled.div`
  flex: 1;
  align-self: stretch;
  display: flex;
  margin-left: 0.5rem;
  align-items: center;
`;

const StyledSeasonPicker = styled.div`
  margin-right: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > p {
    font-weight: 700;
  }
`;

const StyledStatus = styled(Text)<{ first?: boolean; mode?: 'alert' | 'debug' | 'error' | 'success' }>`
  ${({ first }) => !first && { marginLeft: '0.5rem' }}
  color: ${({ theme, mode }) =>
    !mode
      ? 'black'
      : mode === 'alert'
        ? theme.colors.builtins.yellows.base
        : mode === 'debug'
          ? theme.colors.builtins.grays.lighter
          : mode === 'error'
            ? theme.colors.builtins.reds.base
            : mode === 'success'
              ? theme.colors.builtins.greens.base
              : 'black'};
`;

const displayUser = (user: string) => {
  if (!user) {
    return '';
  }
  return (
    ' ' +
    user
      .replace('@hawks.com', '')
      .replace('@hawks404.com', '')
      .split('.')
      .map(s => {
        switch (s.length) {
          case 0:
            return '';
          case 1:
            return s.toUpperCase();
          default:
            return s.substring(0, 1).toUpperCase() + s.substring(1);
        }
      })
      .map(s => s.trim())
      .filter(s => !!s)
      .join(' ')
  );
};

function TitleBar() {
  const { active, user } = useSession();
  const { nextSubmitDeadline, season, seasons, setSeason } = useScouting();

  return (
    <StyledTitleBar>
      <StyledHeadline to={active ? urls.ranks.url : urls.lobby.url}>
        <Heading nomargin>Scout Rankings</Heading>
      </StyledHeadline>
      <StyledBulletin>
        <StyledStatus first nomargin>{`Welcome${displayUser(user)}!`}</StyledStatus>
        {!nextSubmitDeadline && (
          <StyledStatus mode="debug" nomargin>
            No new updates for now.
          </StyledStatus>
        )}
        {nextSubmitDeadline && !nextSubmitDeadline.collected && (
          <StyledStatus mode="alert" nomargin>{`Next submit deadline: ${nextSubmitDeadline.date.toDateString()}`}</StyledStatus>
        )}
        {nextSubmitDeadline && nextSubmitDeadline.collected && (
          <StyledStatus mode="success" nomargin>
            Scout rankings have been collected for this period
          </StyledStatus>
        )}
      </StyledBulletin>
      <StyledSeasonPicker>
        <Text nomargin>Pick season:</Text>
        <Dropdown
          value={`${season?.seasonYear || 0}`}
          onChange={e => {
            const seasonYear = parseInt(e.target.value, 10);
            if (!seasonYear) {
              return;
            }
            setSeason(seasons.find(s => s.seasonYear === seasonYear));
          }}
        >
          {seasons.map(season => (
            <option key={season.id} value={season.seasonYear}>
              {season.seasonYear}
            </option>
          ))}
        </Dropdown>
      </StyledSeasonPicker>
    </StyledTitleBar>
  );
}

export default TitleBar;
