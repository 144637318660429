import { FaExclamation, FaExternalLinkAlt, FaHome, FaLink } from 'react-icons/fa';

import styled from '@emotion/styled';

import { OutlineButton } from '../components/buttons';
import Navbar, { NavAnchor, NavLink, NavList, NavDivider, NavDropdown, NavLabel, NavMenu } from '../components/navbar';
import { useSession } from '../contexts/session';

import logo from './logo.png';

const StyledHeader = styled.header`
  flex: none;
  background-color: ${({ theme }) => theme.colors.site.navbar.bg};
  border-color: ${({ theme }) => theme.colors.site.navbar.border};
  border-width: 0 0 1px;
  height: 50px;
  width: 100%;
`;

const Logo = styled.img`
  max-height: 100%;
  width: 50px;
`;

const LogoutButton = styled(OutlineButton)`
  align-self: center;
  margin-bottom: 0;
`;

function AppHeader() {
  const { logout } = useSession();
  return (
    <StyledHeader>
      <Navbar>
        <NavAnchor noPadding sameTab href={`${process.env.REACT_APP_ANALYTICS_SITE_URL}`}>
          <Logo src={logo} alt="Atlanta Hawks Logo" />
        </NavAnchor>

        <NavList show>
          <NavAnchor center sameTab href={`${process.env.REACT_APP_ANALYTICS_SITE_URL}`}>
            <FaHome />
            &nbsp;&nbsp;Home
          </NavAnchor>

          <NavDropdown label="NBA Stats">
            <NavLabel>Team Stats</NavLabel>
            <NavMenu label="Team Advanced Shots">
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/team_stats_page.html`}>Current</NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/team_stats_page_all_seasons.html`}>Historical</NavLink>
            </NavMenu>
            <NavMenu label="Team Shot Distributions">
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/team_shot_distros.html`}>Current</NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/team_shot_distros_historical.html`}>Historical</NavLink>
            </NavMenu>
            <NavDivider />
            <NavLabel>Player Stats</NavLabel>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/player_adv_stats.html`}>Player Advanced Stats</NavLink>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/player_shot_distros.html`}>Player Shooting</NavLink>
            <NavMenu label="Player ON/OFF Ratings">
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/on_off_ratings.html`}>Current</NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/on_off_ratings_historical.html`}>Historical</NavLink>
            </NavMenu>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/nba_measurement_page.html`}>Player Measurements</NavLink>
            <NavDivider />
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/hawks_lineups_22.html`}>Hawks Lineups 2022-23</NavLink>
            <NavMenu label="Historical Lineups">
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/hawks_lineups_21_playoffs.html`}>
                Hawks Playoff Lineups 2021-22
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/hawks_lineups_21.html`}>Hawks Lineups 2021-22</NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/hawks_lineups_20_playoffs.html`}>
                Hawks Playoff Lineups 2020-21
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/hawks_lineups_20.html`}>Hawks Lineups 2020-21</NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/hawks_lineups.html`}>Hawks Lineups 2019-20</NavLink>
            </NavMenu>
            <NavDivider />
            <NavLabel>Tools</NavLabel>
            <NavMenu label="Last X Games - Stats">
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/last_x_games_21/`}>
                <FaExternalLinkAlt />
                &nbsp;&nbsp;2021-22
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/last_x_games_20/`}>
                <FaExternalLinkAlt />
                &nbsp;&nbsp;2020-21
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/last_x_games_19/`}>
                <FaExternalLinkAlt />
                &nbsp;&nbsp;2019-20
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/last_x_games/`}>
                <FaExternalLinkAlt />
                &nbsp;&nbsp;2018-19
              </NavLink>
            </NavMenu>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/nba_charting_app/`}>
              <FaExternalLinkAlt />
              &nbsp;&nbsp;Shot Chart Tool
            </NavLink>
            <NavDivider />
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/expanded_standings.html`}>
              <FaExclamation />
              &nbsp;&nbsp;Expanded Standings
            </NavLink>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/nba_lg_trends_study.html`}>
              NBA Statistical Trends
            </NavLink>
            <NavLabel>G League</NavLabel>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/skyhawks_lineups_22.html`}>
              Skyhawks Lineups 2022-23
            </NavLink>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/skyhawks_lineups_21.html`}>
              Skyhawks Lineups 2021-22
            </NavLink>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/g_league_on_off_ratings.html`}>
              Player ON/OFF Ratings
            </NavLink>
            <NavMenu label="Team Shot Distributions">
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/g_league_team_shot_distributions_2223.html`}>
                2022-23
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/g_league_team_shot_distributions_2122.html`}>
                2021-22
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/g_league_team_shot_distributions_1920.html`}>
                2019-20
              </NavLink>
            </NavMenu>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/g_league_player_shot_distros.html`}>
              Player Shot Distributions
            </NavLink>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/G_League_Pregame_App/`}>
              <FaExternalLinkAlt />
              &nbsp;&nbsp;Pregame Reports
            </NavLink>
            <NavMenu label="Updates">
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/G_League_Season_Update_Dec_2021.html`}>
                December 2021
              </NavLink>
              {/* FIXME: below url is not found */}
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/G_League_Season_Update_Dec_2022.html`}>
                December 2022
              </NavLink>
            </NavMenu>
          </NavDropdown>

          <NavDropdown label="Front Office">
            <NavLabel>Salary Cap</NavLabel>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/team_asset_page.html`}>Team Asset Page</NavLink>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/nba_salary_page.html`}>NBA Salary Page</NavLink>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/atl_cap_manager_summary.html`}>
              2022 Cap Manager Summary
            </NavLink>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/Reference/nba_salary_book_updated.pdf`}>
              NBA Salary Book
            </NavLink>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/two_way_player_salary_info.html`}>
              Two Way Contract Information
            </NavLink>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/Reference/nba_roster_spot_tracker.pdf`}>
              Roster Spot Tracker
            </NavLink>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/player_value_v_salary_cap_allocation.html`}>
              Player Value vs Salary Cap Allocation
            </NavLink>
            <NavDivider />
            <NavLabel>Hawks Ranks</NavLabel>
            <NavMenu label="Free Agent Tiered List">
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/Free_Agency/2021_Tier_FA_List_07_14_21.pdf`}>
                2021
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/Free_Agency/2020_Tier_FA_List_11_15_20.pdf`}>
                2020
              </NavLink>
            </NavMenu>
            <NavDivider />
            <NavLabel>Analytics Models</NavLabel>
            <NavMenu label="Player Win & Monetary Values">
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/player_win_values.html`}>Current Projections</NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/player_win_values_historical.html`}>
                Historical Values
              </NavLink>
            </NavMenu>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/win_comparison_page.html`}>
              Player Win Value Comparison
            </NavLink>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/salary_projections.html`}>
              Player Salary Projections
            </NavLink>
            <NavLabel>G-League to NBA Projections</NavLabel>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/seeding_distribution_page.html`}>
              Seeding Probabilities
            </NavLink>
            <NavDivider />
            <NavLabel>Player Profile Pages</NavLabel>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/nba_player_pages/`}>
              <FaExternalLinkAlt />
              &nbsp;&nbsp;NBA Players
            </NavLink>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/prospect_player_pages/`}>
              <FaExternalLinkAlt />
              &nbsp;&nbsp;Draft Prospects
            </NavLink>
            <NavDivider />
            <NavLabel>Video</NavLabel>
            <NavAnchor
              href={`https://www.dropbox.com/scl/fo/${process.env.REACT_APP_DROPBOX_ID}/h?dl=0&rlkey=${process.env.REACT_APP_DROPBOX_KEY}`}
            >
              <FaLink />
              &nbsp;&nbsp;FO Dropbox
            </NavAnchor>
            <NavDivider />
            <NavMenu label="Reference">
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/NBA_CBA_101.html`}>NBA CBA 101</NavLink>
            </NavMenu>
          </NavDropdown>

          <NavDropdown label="Coaching">
            <NavMenu label="Offensive Set Efficiency">
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/atl_offensive_sets/`}>
                <FaExternalLinkAlt />
                &nbsp;&nbsp;ATL Set app
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/play_eff_page_2021.html`}>2020-21 Season</NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/play_eff_page_1920.html`}>2019-20 Season</NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/play_eff_page.html`}>2018-19 Season</NavLink>
            </NavMenu>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/paint_touches.html`}>Paint Touches</NavLink>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/coach_stats_rank.html`}>Statistic Rankings</NavLink>
            <NavDivider />
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/pregame_app/`}>
              <FaExternalLinkAlt />
              &nbsp;&nbsp;Pregame Analytics Reports
            </NavLink>
          </NavDropdown>

          <NavDropdown label="Draft" active>
            <NavLink active to="/">
              Scout Rankings
            </NavLink>
            <NavMenu label="Draft Tiers">
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/Draft/Tiers/master_tiers_current.pdf`}>
                2023 - Master
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/Draft/Tiers/draft_ranks_final_2022.pdf`}>2022</NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/Draft/Tiers/draft_2021_tiers.pdf`}>2021</NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/Draft/Tiers/draft_2020_tiers.pdf`}>2020</NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/Draft/Tiers/draft_2019_tiers.pdf`}>2019</NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/Draft/Tiers/draft_2018_tiers.pdf`}>2018</NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/Draft/Tiers/draft_2017_tiers.pdf`}>2017</NavLink>
            </NavMenu>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/searchable_atl_tiered_list.html`}>
              Searchable Draft Tiered List
            </NavLink>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/ncaa_contact_sheet.html`}>NCAA Contact Sheet</NavLink>
            <NavDivider />
            <NavLabel>2023 Analytics Evaluation</NavLabel>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/draft_model_projections_2023.html`}>
              Analytics Draft Model
            </NavLink>
            <NavLabel>3PT% Projections</NavLabel>
            <NavLabel>Prospect - NBA Comparisons</NavLabel>
            <NavLabel>Boom/Bust Model</NavLabel>
            <NavLabel>2022 Draft Information</NavLabel>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/prospect_adv_stats.html`}>Prospect Stats</NavLink>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/prospect_shooting.html`}>Prospect Shooting</NavLink>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/prospect_measurements.html`}>
              Prospect Measurements
            </NavLink>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/prospect_player_pages/`}>
              <FaExternalLinkAlt />
              &nbsp;&nbsp;Prospect Player Pages
            </NavLink>
            <NavDivider />
            <NavLabel>Historical Analytics Evaluation</NavLabel>
            <NavMenu label="2022">
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/draft_model_projections_2022.html`}>
                Analytics Draft Model
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/draft_boom_bust_models_2022.html`}>
                Boom/Bust Model
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/draft_shooting_projections_2022.html`}>
                3PT% Projections
              </NavLink>
            </NavMenu>
            <NavMenu label="2021">
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/draft_model_projections_2021.html`}>
                Analytics Draft Model
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/draft_boom_bust_models_2021.html`}>
                Boom/Bust Model
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/draft_shooting_projections_2021.html`}>
                3PT% Projections
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/draft_prospect_comps_2021.html`}>
                Prospect - NBA Comparisons
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/NBA_Combine_GLg_Showcase_2021_Prospect_Pages.pdf`}>
                2021 NBA Combine/G-League Showcase Player Pages
              </NavLink>
            </NavMenu>
            <NavMenu label="2020">
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/draft_model_projections_2020.html`}>
                Analytics Draft Model
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/draft_boom_bust_models_2020.html`}>
                Boom/Bust Model
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/draft_shooting_projections_2020.html`}>
                3PT% Projections
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/draft_prospect_comps_2020.html`}>
                Prospect - NBA Comparisons
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_HOMECOURT_URL}/`}>
                <FaLink />
                &nbsp;&nbsp;2020 Combine Shooting Drills
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_VEO_URL}/accounts/login/?next=/`}>
                <FaLink />
                &nbsp;&nbsp;2020 Combine Strength & Agility Drills
              </NavLink>
            </NavMenu>
            <NavMenu label="2019">
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/draft_model_projections.html`}>
                Analytics Draft Model
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/draft_boom_bust_models.html`}>Boom/Bust Model</NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/draft_shooting_projections.html`}>
                3PT% Projections
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/draft_prospect_comps.html`}>
                Prospect - NBA Comparisons
              </NavLink>
            </NavMenu>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/draft_model_projections_HIST.html`}>
              Historical Draft Model Projections
            </NavLink>
            <NavDivider />
            <NavLabel>Draft Reference</NavLabel>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/draft_value_chart.html`}>NBA Draft Pick Values</NavLink>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/pick_values_app/`}>
              <FaExternalLinkAlt />
              &nbsp;&nbsp;Pick Value App
            </NavLink>
          </NavDropdown>

          <NavDropdown label="Performance">
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/apt_index.html`}>Home</NavLink>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/apt_load_report.html`}>Load Reports</NavLink>
            <NavDivider />
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/KNX_App/`}>
              <FaExternalLinkAlt />
              &nbsp;&nbsp;Kinexon Summary
            </NavLink>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/DXA_App/`}>
              <FaExternalLinkAlt />
              &nbsp;&nbsp;Body Composition Summary
            </NavLink>
          </NavDropdown>

          <NavDropdown label="Research">
            <NavLabel>Analyses</NavLabel>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/playoff_study.pdf`}>Playoff Study</NavLink>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/dribble_threes.pdf`}>Dribble Threes</NavLink>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/defense_study.html`}>Defense Study</NavLink>
            <NavDivider />
            <NavLabel>2022-23 Updates</NavLabel>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/preseason_summary_2022.html`}>Preseason</NavLink>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/season_update_first_8_202223.html`}>
              First 8 Games
            </NavLink>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/season_update_team_summary_111822.html`}>
              First 15 Games - Team
            </NavLink>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/season_update_player_summary_111822.html`}>
              First 15 Games - Players
            </NavLink>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/season_update_22_12422.html`}>
              First 23 Games
            </NavLink>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/season_update_22_team_summary_122522.html`}>
              Games 24-33 - Team
            </NavLink>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/season_update_22_player_summary_122522.html`}>
              Games 24-33 - Players
            </NavLink>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/season_update_22_team_summary_011023.html`}>
              Games 34 to 40
            </NavLink>
            <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/season_update_22_team_summary_012623.html`}>
              Games 41 to 49
            </NavLink>
            <NavDivider />
            <NavMenu label="2021-22 Updates">
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/preseason_summary_2021.html`}>
                Preseason
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/season_update_first_9_202122.html`}>
                First 9 Games
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/season_update_games_10_18_202122.html`}>
                Games 10 through 18
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/season_update_games_19_29_202122.html`}>
                Games 19 through 29
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/season_update_games_30_39_202122.html`}>
                Mid-season Report
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/season_update_games_40_50_202122.html`}>
                Games 40 to 50
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/season_update_allstar_202122.html`}>
                Games 51 to 58 (All-Star)
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/season_update_59_71_202122.html`}>
                Games 59 to 71
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/season_review_21.html`}>
                Full Season Team Summary (2021-22)
              </NavLink>
              <NavLink
                to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/Atlanta_Hawks_Full_Season_Player_Summaries_2021.pdf`}
              >
                Full Season Player Summaries (2021-22)
              </NavLink>
            </NavMenu>
            <NavMenu label="2020-21 Updates">
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/Preseason_Analytics_Summary_2020_21.pdf`}>
                Preseason
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/Hawks_Update_1_7_21.html`}>
                First 8 Game Update
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}research/Hawks_Update_1_22_21.html`}>Games 9-14</NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/Hawks_Update_1_30_21.html`}>
                Games 15-19
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/Hawks_Update_2_9_21.html`}>
                Games 20-23 (player focused)
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/Hawks_Update_2_22_21.html`}>
                Games 24-30
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/Hawks_Update_3_8_21_ASB.html`}>
                All Star Update
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/Hawks_Update_3_27_21.html`}>
                Games 37-45
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/Hawks_Update_4_16_21.html`}>
                Games through 56 Games
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/Hawks_Update_4_29_21.html`}>
                Games through 57-63
              </NavLink>
              {/* FIXME: below url is not found */}
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/Hawks_Update_Playoffs_R1_2021.html`}>
                Playoffs Round 1
              </NavLink>
            </NavMenu>
            <NavMenu label="2019-20 Updates">
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/Preseason_Analytics_Summary_2019_20.pdf`}>
                Pre-season report
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/first_13_update.html`}>13-game update</NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/Atlanta_Hawks_Update_(12.5.2019).pdf`}>
                Games 14-22
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/ATL_Mid_Season_Update_(1.15.2020).pdf`}>
                Mid-Season Report
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/ATL_ASB_Update_56.pdf`}>
                All Star Update
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/ATL_Season_Summary_(2019-20_Season).pdf`}>
                Full Season Report (2019-20)
              </NavLink>
            </NavMenu>
            <NavMenu label="2018-19 Updates">
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/Preseason_Analytics_Summary.pdf`}>
                Pre-season Report
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/Atlanta_Hawks_Mid_Season_Update.pdf`}>
                Mid-season Report
              </NavLink>
              <NavLink to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/research/Atlanta_Hawks_Season_Summary.pdf`}>
                Full Season Report
              </NavLink>
            </NavMenu>
          </NavDropdown>

          <NavLink center to={`${process.env.REACT_APP_ANALYTICS_SITE_URL}/projected_standings.html`}>
            Projected Standings
          </NavLink>
        </NavList>

        <LogoutButton onClick={logout}>Logout</LogoutButton>
      </Navbar>
    </StyledHeader>
  );
}

export default AppHeader;
