import { useState } from 'react';

import styled from '@emotion/styled';

import { Button } from '../../../components/buttons';
import { Text } from '../../../components/typography';
import { useScouting } from '../../../contexts/scouting';
import { HawksError } from '@hawks-ui/errors';

const StyledEmptyState = styled.div`
  margin-top: 0.5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem auto;
  border: 4px solid ${({ theme }) => theme.colors.builtins.reds.base};
  border-radius: 8px;
  height: 300px;

  @media only screen and (max-width: 576px) {
    min-width: 98%;
  }

  @media only screen and (min-width: 576px) {
    max-width: 540px;
    width: 540px;
  }
`;

const StyledWelcome = styled(Text)`
  margin-bottom: 2rem;
  font-weight: 700;
`;

const StyledSeason = styled.span`
  color: ${({ theme }) => theme.colors.builtins.reds.base};
  font-weight: 700;
`;

const StyledStartButton = styled(Button)`
  margin-top: 2rem;
  padding: 0.618rem;
  font-size: ${({ theme }) => theme.fontSizes.mega};
`;

const StyledErrText = styled(Text)`
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.builtins.reds.base};
  padding: 0 1rem;
`;

function AdminEmptyState() {
  const { api, currentSeason, seasonFetch } = useScouting();
  const [err, setErr] = useState<HawksError>();
  return (
    <StyledEmptyState>
      <StyledWelcome>Welcome to the Admin dashboard for the Hawks' scouting operation.</StyledWelcome>
      <Text nomargin>
        The scouting season for <StyledSeason>{currentSeason}</StyledSeason> has not yet begun. You can start it now:
      </Text>
      <StyledStartButton
        shade="reds"
        onClick={() =>
          api
            .admin()
            .season()
            .create()
            .then(() => seasonFetch())
            .catch(e => {
              const err = HawksError.make(e);
              setErr(err);
            })
        }
      >
        Begin Season
      </StyledStartButton>
      {err && <StyledErrText>{`${err.code}: ${err.message}`}</StyledErrText>}
    </StyledEmptyState>
  );
}

export default AdminEmptyState;
