import { css } from '@emotion/react';
import styled from '@emotion/styled';

import styles from './styles';

const StyledNavButton = styled.button<{ center: boolean; split: boolean }>(({ theme, center, split }) => [
  styles.navItem(theme),
  css`
    display: flex;
    align-items: center;
    justify-content: ${split ? 'space-between' : 'center'};
    border: none;
    background: none;
    cursor: pointer;
    width: 100%;
  `,
  css`
    align-self: ${center ? 'center' : 'self'};
  `,
]);

interface Props {
  align?: 'natural' | 'split';
  center?: boolean;
  children?: React.ReactNode;
  expanded: boolean;
  onClick?: () => void;
}

function NavButton({ align = 'natural', center = false, children, expanded, onClick }: Props) {
  return (
    <StyledNavButton
      type="button"
      aria-haspopup="menu"
      aria-expanded={expanded ? 'true' : 'false'}
      split={align === 'split'}
      center={center}
      onClick={onClick}
    >
      {children}
    </StyledNavButton>
  );
}

export default NavButton;
