import { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';

import { scouting } from '@atlanta-hawks/bbops-client-js';

import { Button } from '../../../components/buttons';
import { Text } from '../../../components/typography';
import { useScouting } from '../../../contexts/scouting';
import { useSession } from '../../../contexts/session';

import Table from './check_ranks_table';

function CheckRanks() {
  const { user } = useSession();
  const { api, initialized, playersById, ranksFetch } = useScouting();

  const [allRanks, setAllRanks] = useState<scouting.Ranks[]>([]);

  const refreshRanks = useCallback(() => api.admin().ranks().getAll().then(setAllRanks), [api, setAllRanks]);

  useEffect(() => {
    if (!initialized || !user) {
      return;
    }
    (async () => await refreshRanks())();
  }, [refreshRanks, initialized, user]);

  const [showModalAddScout, setShowModalAddScout] = useState(false);

  return (
    <>
      <div>
        <Button onClick={() => setShowModalAddScout(true)}>Add scout</Button>
      </div>
      <Table
        allRanks={allRanks}
        deleteRanks={api.admin().ranks().deleteOne}
        patchRanks={api.admin().ranks().patchOne}
        refreshRanks={() => refreshRanks().then(ranksFetch)}
        playersById={playersById}
      />
      <Modal isOpen={showModalAddScout} onRequestClose={() => setShowModalAddScout(false)}>
        <Text>todo(bbeng-216): make form to add scout</Text>
      </Modal>
    </>
  );
}

export default CheckRanks;
