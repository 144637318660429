import { css } from '@emotion/react';

const rootStyles = (id: string) => css`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    height: 100%;
    width: 100%;
  }

  #${id} {
    min-height: 100vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
`;

export default rootStyles;
