import { useEffect, useRef, useState } from 'react';
import { FaCaretDown } from 'react-icons/fa';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import NavButton from './nav_button';
import NavPopover from './nav_popover';

interface Props {
  active?: boolean;
  as?: 'div' | 'li';
  label: string;
  children?: React.ReactNode;
}

const StyledNavDropdown = styled.div<{ active: boolean }>(({ theme, active }) => [
  css`
    position: relative;
    display: flex;
  `,
  active &&
    css`
      background-color: ${theme.colors.site.navbar.border};
    `,
]);

function NavDropdown({ active = false, as = 'div', children, label }: Props) {
  const ref = useRef<HTMLUListElement>(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setShow(false);
      }
    };

    document.addEventListener('keydown', listener, false);
    return () => {
      document.removeEventListener('keydown', listener, false);
    };
  }, []);

  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShow(false);
      }
    };

    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref]);

  return (
    <StyledNavDropdown active={active} as={as}>
      <NavButton expanded={show} onClick={() => setShow(curr => !curr)}>
        {label}&nbsp;
        <FaCaretDown />
      </NavButton>
      <NavPopover show={show} ref={ref}>
        {children}
      </NavPopover>
    </StyledNavDropdown>
  );
}

export default NavDropdown;
