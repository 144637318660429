import styled, { StyledComponent } from '@emotion/styled';

// usually you won't have to type this Styled component,
// but since we're using `ListItem` as a CSS selector
// within its own definition to control top border widths
export const StyledListItem: StyledComponent<any> = styled.li<Pick<Props, 'display' | 'padding'>>`
  display: ${({ display }) => display};
  ${({ display }) =>
    display === 'flex' && {
      alignItems: 'center',
    }}
  position: relative;
  padding: ${({ padding }) => padding};
  border: 1px solid ${({ theme }) => theme.colors.builtins.grays.lightest};
  font-size: ${({ theme }) => theme.fontSizes.base};
  &:first-of-type {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  & + ${() => StyledListItem} {
    border-top-width: 0;
  }
  &:last-of-type {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`;

interface Props {
  children?: React.ReactNode;
  display: string;
  padding: string;
}

function ListItem({ children, ...rest }: Props) {
  return <StyledListItem {...rest}>{children}</StyledListItem>;
}

const defaultProps: Partial<Props> = {
  display: 'block',
  padding: '0.5rem',
};

ListItem.defaultProps = defaultProps;

export default ListItem;
