import { useState } from 'react';
import {
  FaArrowsRotate,
  FaCaretDown,
  FaCaretUp,
  FaCircleDot,
  FaCircleExclamation,
  FaCircleQuestion,
  FaX,
} from 'react-icons/fa6';

import { scouting } from '@atlanta-hawks/bbops-client-js';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { TextButton } from '../../../components/buttons';
import Menu from '../../../components/menu';
import { TableData } from '../../../components/table';
import { Text } from '../../../components/typography';
import { checkRanks, checkRankEmpty, scoutDisplay } from '../../../contexts/scouting';

import { ScoutRanks } from './types';

const StyledTableIcons = styled(TableData)`
  & > * {
    margin: 0 0.125rem;
  }
`;

const StyledSubmittedMenu = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledIconTextButton = styled(TextButton)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  margin: 0.5rem 0.25rem;
  border-top: 1px solid ${({ theme }) => theme.colors.site.navbar.border};
  border-bottom: 1px solid ${({ theme }) => theme.colors.site.navbar.border};
`;

const StyledFaRefresh = styled(FaArrowsRotate)`
  margin-right: 0.5rem;
  color: ${({ theme }) => theme.colors.team.legacy[20072020].voltGreen};
`;

const StyledRefreshText = styled(Text)`
  flex: 1;
  margin-bottom: 0;
  text-align: left;
  color: ${({ theme }) => theme.colors.team.legacy[20072020].voltGreen};
`;

const StyledDeleteIcon = styled(FaX)`
  margin-right: 0.5rem;
  color: ${({ theme }) => theme.colors.team.current.torchRed};
`;

const StyledDeleteText = styled(Text)`
  flex: 1;
  margin-bottom: 0;
  text-align: left;
  color: ${({ theme }) => theme.colors.team.current.torchRed};
`;

const StyledExpandedRow = styled.tr`
  background: ${({ theme }) => theme.colors.site.navbar.bg};
`;

const StyledPlayerList = styled.div`
  max-height: 210px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-x: auto;
  align-items: flex-start;
`;

const StyledPlayerItem = styled(Text)<{ excluded?: boolean }>`
  color: ${({ theme, excluded }) =>
    excluded ? theme.colors.team.current.graniteGray : theme.colors.team.current.infinityBlack};
`;

interface Props {
  playersById: Record<string, scouting.Player>;
  ranks: ScoutRanks;
  showDeletePastRanks: () => void;
  showSavePastRanks: () => void;
  submitDeadline: scouting.SeasonSubmitDeadline;
  tableSize: number;
}

function CollectAndPublishTable({
  playersById,
  ranks,
  showDeletePastRanks,
  showSavePastRanks,
  submitDeadline,
  tableSize,
}: Props) {
  const theme = useTheme();

  const [expanded, setExpanded] = useState(false);

  const pastChecks = !ranks.past
    ? checkRankEmpty
    : checkRanks(
        ranks.past.players,
        playersById,
        ranks.past.players.reduce((acc, r) => ({ ...acc, [r.playerId]: r.meta }), {}),
        submitDeadline,
      );

  return (
    <>
      <tr key={ranks.active.id}>
        <TableData>
          <TextButton onClick={() => setExpanded(prev => !prev)}>{expanded ? <FaCaretUp /> : <FaCaretDown />}</TextButton>
        </TableData>
        <StyledTableIcons>
          {!ranks.past && (
            <FaCircleQuestion
              color={theme.colors.team.current.legacyYellow}
              data-tooltip-id="collect-and-publish-tooltip"
              data-tooltip-content="Ranks were never collected"
            />
          )}
          {ranks.past && ranks.past.players.length < submitDeadline.size && (
            <FaCircleExclamation
              color={theme.colors.team.current.legacyYellow}
              data-tooltip-id="collect-and-publish-tooltip"
              data-tooltip-content="Not enough ranks were collected"
            />
          )}
          {ranks.prevPastSame && (
            <FaCircleDot
              color={theme.colors.builtins.blues.faded}
              data-tooltip-id="collect-and-publish-tooltip"
              data-tooltip-content="Collected ranks are unchanged from previous period"
            />
          )}
        </StyledTableIcons>
        <TableData>{scoutDisplay(ranks.active.email)}</TableData>
        <TableData>{ranks.past ? ranks.past.players.length : '0'}</TableData>
        <TableData>{ranks.past ? pastChecks.positions.pg : ''}</TableData>
        <TableData>{ranks.past ? pastChecks.positions.sg : ''}</TableData>
        <TableData>{ranks.past ? pastChecks.positions.sf : ''}</TableData>
        <TableData>{ranks.past ? pastChecks.positions.pf : ''}</TableData>
        <TableData>{ranks.past ? pastChecks.positions.c : ''}</TableData>
        <TableData>{ranks.past ? (ranks.past.submitted ? 'Y' : 'N') : ''}</TableData>
        <TableData>{ranks.past?.updatedOn?.toLocaleString() || '--'}</TableData>
        <TableData>
          <Menu label="...">
            <StyledSubmittedMenu>
              <StyledIconTextButton onClick={showSavePastRanks}>
                <StyledFaRefresh />
                <StyledRefreshText>Collect</StyledRefreshText>
              </StyledIconTextButton>
              <StyledIconTextButton onClick={showDeletePastRanks} disabled={!ranks.past}>
                <StyledDeleteIcon />
                <StyledDeleteText>Delete</StyledDeleteText>
              </StyledIconTextButton>
            </StyledSubmittedMenu>
          </Menu>
        </TableData>
      </tr>
      {expanded && (
        <StyledExpandedRow>
          <TableData />
          <TableData colSpan={tableSize - 2}>
            {!ranks.past && <Text nomargin>This scout has no collected rankings for this submission period.</Text>}
            {ranks.past && (
              <StyledPlayerList>
                {ranks.past.players.map((r, i) => {
                  const p = playersById[r.playerId];
                  return (
                    <StyledPlayerItem
                      key={`${ranks.active.id}_${r.playerId}`}
                      excluded={i >= submitDeadline.size}
                    >{`#${i + 1} ${p?.name || '--'}${p.position ? ' - ' + p.position : ''}`}</StyledPlayerItem>
                  );
                })}
              </StyledPlayerList>
            )}
          </TableData>
          <TableData />
        </StyledExpandedRow>
      )}
    </>
  );
}

export default CollectAndPublishTable;
