import styled from '@emotion/styled';

import TextButton, { Props as TextButtonProps } from './text_button';

const StyledIconButton = styled(TextButton)`
  padding: 0.309rem 0;
  color: ${({ theme }) => theme.colors.builtins.grays.lightest};

  &:disabled,
  &:disabled:hover {
    color: ${({ theme }) => theme.colors.builtins.grays.lightest};
    cursor: auto;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.builtins.reds.base};
  }
`;

interface Props extends TextButtonProps {}

function IconButton({ shade = 'text', ...rest }: Props) {
  return <StyledIconButton shade={shade} {...rest} />;
}

export default IconButton;
