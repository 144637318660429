import Modal from 'react-modal';

import { scouting } from '@atlanta-hawks/bbops-client-js';
import styled from '@emotion/styled';

import List, { ListItem } from '../../../components/list';
import Table, { TableData, TableHeader } from '../../../components/table';
import { Heading, Text } from '../../../components/typography';
import { checkRanks, useScouting } from '../../../contexts/scouting';
import { isDate } from '../../../core';

import PublishModalForm from './deadline_manager_modal_publish_form';

const StyledModalText = styled(Text)`
  margin: 0.5rem 0;
`;

interface Props {
  closeModal: () => void;
  submitDeadline?: scouting.SeasonSubmitDeadline;
}

function DeadlineManagerPublishModal({ closeModal, submitDeadline }: Props) {
  const { compositeRanksByPeriod, pastRanksByPeriod, playersById, today } = useScouting();

  const checkSubmitDeadlineDate = isDate(submitDeadline?.date);

  let todayIs: 'before' | 'same' | 'after' = 'after';
  if (checkSubmitDeadlineDate.same(today)) {
    todayIs = 'same';
  } else if (checkSubmitDeadlineDate.after(today)) {
    todayIs = 'before';
  }

  const existingLabels = Object.keys(
    Object.values(compositeRanksByPeriod)
      .flatMap(arr => arr)
      .map(r => r.label)
      .reduce((acc, label) => ({ ...acc, [label]: true }), {}),
  ).sort();

  const compositeRanks = compositeRanksByPeriod[submitDeadline?.period || 0] || [];
  const pastRanks = pastRanksByPeriod[submitDeadline?.period || 0] || [];
  const readyRanks = pastRanks
    .map(ranks =>
      checkRanks(
        ranks.players,
        playersById,
        ranks.players.reduce((acc, r) => ({ ...acc, [r.playerId]: r.meta }), {}),
        submitDeadline,
      ),
    )
    .filter(check => check.checks.size);
  const idealRanks = pastRanks
    .map(ranks =>
      checkRanks(
        ranks.players,
        playersById,
        ranks.players.reduce((acc, r) => ({ ...acc, [r.playerId]: r.meta }), {}),
        submitDeadline,
      ),
    )
    .filter(check => check.checks.size && check.checks.positions && check.checks.outcomes).length;

  return (
    <Modal
      isOpen={!!submitDeadline}
      onRequestClose={closeModal}
      style={{
        content: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
      }}
    >
      <Heading as="h3">Publish Rankings</Heading>
      {todayIs === 'before' && (
        <Text>
          You will be able to start publishing rankings once the submit deadline date approaches and rankings have been
          collected.
        </Text>
      )}
      <List flush>
        <ListItem>{`${pastRanks.length} scout(s) rankings collected`}</ListItem>
        <ListItem>{`${readyRanks.length} scout(s) rankings meet the minimum number of ranks`}</ListItem>
        <ListItem>{`${idealRanks} scout(s) rankings meet all of the publish requirements`}</ListItem>
      </List>
      <StyledModalText>
        You must first enter a publishing label and select which users to include. Then you can click the button bolow to
        compile rankings for this period+label combo. A detailed summary of the captured data will display below.
      </StyledModalText>
      <PublishModalForm existingLabels={existingLabels} pastRanks={pastRanks} submitDeadline={submitDeadline} />
      {!compositeRanks.length && <StyledModalText>You have not yet published rankings for this period yet.</StyledModalText>}
      {!!compositeRanks.length && (
        <Table
          header={
            <tr>
              <TableHeader>Label</TableHeader>
              <TableHeader>Players Ranked</TableHeader>
              <TableHeader>Created On</TableHeader>
              <TableHeader>Updated On</TableHeader>
            </tr>
          }
        >
          {compositeRanks.map(ranks => (
            <tr key={ranks.id + ranks.updatedOn.toISOString()}>
              <TableData>{ranks.label}</TableData>
              <TableData>{ranks.players.length}</TableData>
              <TableData>{ranks.createdOn.toLocaleTimeString()}</TableData>
              <TableData>{ranks.updatedOn.getTime() === 0 ? '--' : ranks.updatedOn.toLocaleTimeString()}</TableData>
            </tr>
          ))}
        </Table>
      )}
    </Modal>
  );
}

export default DeadlineManagerPublishModal;
