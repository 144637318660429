import { useEffect, useState } from 'react';

import { scouting } from '@atlanta-hawks/bbops-client-js';
import styled from '@emotion/styled';

import { RanksWorksheet } from '../../components/ranks';
import { useScouting } from '../../contexts/scouting';
import { doWait } from '../../core';

import EmptyState from './empty_state';
import { FilterPlayers, RanksChecklist, SubmitRanks } from './toolbar';

const StyledToolbar = styled.div`
  background-color: ${({ theme }) => theme.colors.site.navbar.bg};
  flex: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 150px;
  column-gap: 0.5rem;
  padding: 0 0.5rem;
`;

function RanksPage() {
  const { api, initialized, playersById, playerPool, ranks, ranksFetch, season, nextSubmitDeadline } = useScouting();

  const [savingCurrent, setSavingCurrent] = useState(false);

  const setCurrentRanks = (data: scouting.Rank[]) =>
    doWait({
      onStart: () => setSavingCurrent(true),
      onDone: () => setSavingCurrent(false),
      fn: () => api.ranks().patch({ type: 'set_current', data }).then(ranksFetch),
    });

  const [players, setPlayers] = useState<scouting.Player[]>([]);
  const [rankings, setRankings] = useState<scouting.Player[]>([]);
  const [teams, setTeams] = useState<string[]>([]);

  useEffect(() => {
    if (!initialized) {
      return;
    }
    setPlayers(playerPool);
    setRankings((ranks?.current || []).map(rank => playersById[rank.playerId]));

    const teamSet = playerPool.map(p => p.team).reduce((acc, team) => ({ ...acc, [team]: true }), {});
    setTeams(
      Object.keys(teamSet)
        .filter(t => !!t)
        .sort(),
    );
  }, [initialized, playersById, playerPool, ranks]);

  if (!season?.seasonYear) {
    return <EmptyState />;
  }

  return (
    <>
      <StyledToolbar>
        <FilterPlayers setPlayers={setPlayers} playerTeams={teams} resetPlayers={() => setPlayers(playerPool)} />
        <RanksChecklist />
        <SubmitRanks savingCurrent={savingCurrent} skipCol={!season} />
      </StyledToolbar>
      <RanksWorksheet
        disabled={!season.active}
        nextSubmitDeadline={nextSubmitDeadline}
        onRankingsUpdate={setCurrentRanks}
        patchRanks={req => api.ranks().patch(req).then(ranksFetch)}
        players={players}
        setPlayers={setPlayers}
        rankings={rankings}
        setRankings={setRankings}
        ranksLabel="My Rankings"
        ranksPlayerMeta={ranks?.playerMeta}
        importRanks={() => api.ranks().carryover({ current: true }).then(ranksFetch)}
        seasonYear={season.seasonYear}
      />
    </>
  );
}

export default RanksPage;
