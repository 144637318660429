import styled from '@emotion/styled';

const StyledHamburger = styled.span<{ tight?: boolean }>`
  align-items: flex-end;
  cursor: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: ${({ tight }) => (tight ? '1rem' : '1.618rem')};
  height: ${({ tight }) => (tight ? '1rem' : '1.618rem')};
  background: transparent;
  border: none;
  padding: 0;
  z-index: ${({ theme }) => theme.zindex.hamburger};

  &:focus {
    outline: none;
  }

  div {
    width: ${({ tight }) => (tight ? '1rem' : '1.618rem')};
    height: ${({ tight }) => (tight ? '0.11rem' : '0.2rem')};
    background: ${({ theme }) => theme.colors.builtins.grays.lighterer};
    border-radius: 10px;
    transition: all 0.3s linear;
    transform-origin: 1px;
  }
`;

interface Props {
  tight?: boolean;
}

function Hamburger({ tight }: Props) {
  return (
    <StyledHamburger tight={tight}>
      <div />
      <div />
      <div />
    </StyledHamburger>
  );
}

export default Hamburger;
