import styled from '@emotion/styled';

interface Props {
  children?: React.ReactNode;
  colgroup?: React.ReactNode;
  header?: React.ReactNode;
  headerAlign?: 'baseline' | 'middle';
  floatHeader?: boolean;
}

const StyledTable = styled.table<Pick<Props, 'floatHeader'> & Required<Pick<Props, 'headerAlign'>>>`
  width: 100%;

  & thead {
    ${({ floatHeader }) => floatHeader && { position: 'sticky' }}
    inset-block-start: 0;
    background: ${({ theme }) => theme.colors.site.navbar.bg};
  }

  tr {
    border-bottom: 1px solid ${({ theme }) => theme.colors.site.navbar.bg};
    & > * {
      padding: 0.618rem 0.309rem;
    }
  }

  th {
    font-size: ${({ theme }) => theme.fontSizes.base};
    font-family: ${({ theme }) => theme.fonts.body.longForm};
    font-weight: 700;
    min-width: 36px;
    border-bottom: 1px solid black;
    vertical-align: ${({ headerAlign }) => headerAlign};
  }

  td {
    border-top: 1px solid ${({ theme }) => theme.colors.builtins.grays.lightest};
    font-size: ${({ theme }) => theme.fontSizes.centi};
    font-family: ${({ theme }) => theme.fonts.body.shortForm};
  }
`;

function Table({ children, colgroup, header, headerAlign = 'baseline', ...rest }: Props) {
  return (
    <StyledTable headerAlign={headerAlign} {...rest}>
      {colgroup}
      <thead>{header}</thead>
      <tbody>{children}</tbody>
    </StyledTable>
  );
}

export default Table;
