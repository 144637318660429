import { css } from '@emotion/react';

import { Theme } from '../theme';

const typographyStyles = (theme: Theme) => css`
  body {
    margin: 0;
    font-family: ${theme.fonts.body.shortForm};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: ${theme.fonts.code};
  }
`;

export default typographyStyles;
