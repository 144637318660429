import { cloneElement, isValidElement, useState } from 'react';

import styled from '@emotion/styled';

import { doWait } from '../../core';

import { TextButton } from '../buttons';
import Spinner from './spinner';

const StyledContainer = styled.div<Pick<Props, 'tight'>>`
  min-width: ${({ tight }) => (tight ? '0' : '80px')};
`;

interface Props {
  children?: React.ReactNode;
  onClick?: () => any;
  spinner?: JSX.Element;
  tight?: boolean;
  waitForMillis?: number;
}

function SpinnerButton({
  children = <TextButton>Click</TextButton>,
  onClick = () => {},
  spinner = <Spinner active shade="yellows" />,
  waitForMillis,
}: Props) {
  const [active, setActive] = useState(false);
  return (
    <StyledContainer>
      {active && spinner}
      {!active &&
        (isValidElement(children)
          ? cloneElement<any>(children, {
              onClick: () => {
                setActive(true);

                let prom: Promise<any>;
                if (!waitForMillis) {
                  prom = Promise.resolve(onClick());
                } else {
                  prom = doWait({ fn: onClick }, waitForMillis);
                }

                prom.finally(() => setActive(false));
              },
            })
          : children)}
    </StyledContainer>
  );
}

export default SpinnerButton;
