import { NavLink, Outlet } from 'react-router-dom';

import styled from '@emotion/styled';

import { Heading } from '../../components/typography';
import { useScouting } from '../../contexts/scouting';
import urls from '../../urls';

const StyledPage = styled.div`
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 100%;
  width: 100%;
`;

const StyledHeading = styled(Heading)`
  text-align: center;
  color: inherit;
`;

const StyledTabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

const StyledNavLink = styled(NavLink, { shouldForwardProp: prop => prop !== 'undecorated' })<{ undecorated?: boolean }>`
  padding: 5px;
  color: ${({ theme }) => theme.colors.builtins.text.base};
  ${({ undecorated }) => undecorated && { textDecoration: 'none' }}
`;

const activeStyle = ({ isActive }: { isActive: boolean }): React.CSSProperties => (isActive ? { color: 'red' } : {});

function AdminPage() {
  const { initialized, season } = useScouting();

  if (!initialized) {
    return null;
  }

  return (
    <StyledPage>
      <StyledNavLink to={urls.admin.url} style={activeStyle} end undecorated>
        <StyledHeading as="h2">Admin Dashboard</StyledHeading>
      </StyledNavLink>
      <StyledTabs>
        {!!season && (
          <StyledNavLink to={urls.admin.pages.collectAndPublish.url} style={activeStyle}>
            Collect & Publish
          </StyledNavLink>
        )}
        {!!season && false && (
          <StyledNavLink to={urls.admin.pages.viewScouts.url} style={activeStyle}>
            View Scouts
          </StyledNavLink>
        )}
        {!!season && (
          <StyledNavLink to={urls.admin.pages.checkRanks.url} style={activeStyle}>
            Ranks Checker
          </StyledNavLink>
        )}
        {!!season && (
          <StyledNavLink to={urls.admin.pages.managePlayerPool.url} style={activeStyle}>
            Manage Player Pool
          </StyledNavLink>
        )}
      </StyledTabs>
      <Outlet />
    </StyledPage>
  );
}

export default AdminPage;
