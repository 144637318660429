import styled from '@emotion/styled';

import { Heading, Subheading, Text } from '../../components/typography';

const StyledBox = styled.div`
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
`;

const StyledHeading = styled(Heading)`
  color: ${({ theme }) => theme.colors.team.current.torchRed};
`;

const StyledSubheading = styled(Subheading)`
  color: ${({ theme }) => theme.colors.team.current.legacyYellow};
  margin-bottom: 0;
`;

const StyledText = styled(Text)`
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors.site.navbar.border};
`;

function RanksEmptyState() {
  return (
    <StyledBox>
      <StyledHeading>Uh oh!</StyledHeading>
      <StyledSubheading>No scouting seasons available</StyledSubheading>
      <StyledText>
        The system returned no data for any available scouting seasons to create rankings for. Please contact your administrator
        if you believe this page is showing in error
      </StyledText>
    </StyledBox>
  );
}

export default RanksEmptyState;
