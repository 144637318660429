import styled from '@emotion/styled';

import { Theme } from '../../styles';

const StyledPre = styled.pre<Pick<Props, 'maxHeight'>>`
  max-height: ${({ maxHeight }) => maxHeight};
  max-width: 100%;
  width: 61.8%;
  overflow-x: auto;
  overflow-y: ${({ maxHeight }) => (!!maxHeight ? 'auto' : 'unset')};
  background: ${({ theme }) => theme.colors.builtins.grays.darker};

  code {
    color: white;
    line-height: 1.618;
  }
`;

const StyledCode = styled.code<Pick<Props, 'size' | 'variant'>>`
  margin: unset;
  font-family: ${({ theme }) => theme.fonts.code};
  font-size: ${({ theme, size }) => theme.fontSizes[size]};
  letter-spacing: 1px;
`;

type CodeSize = keyof Pick<Theme['fontSizes'], 'base' | 'milli'>;

type CodeVariant = 'block' | 'inline';

interface Props {
  block?: boolean;
  children?: React.ReactNode;
  maxHeight?: string;
  size: CodeSize;
  variant: CodeVariant;
}

function Code({ block, children, maxHeight, ...rest }: Props) {
  const renderCode = () => <StyledCode {...rest}>{children}</StyledCode>;

  if (block) {
    return <StyledPre maxHeight={maxHeight}>{renderCode()}</StyledPre>;
  }
  return renderCode();
}

Code.defaultProps = {
  size: 'base',
  variant: 'inline',
};

export default Code;
