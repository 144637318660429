import React, { createContext, useContext, useState, useEffect } from 'react';

import { useApi } from './api';
import { HawksError } from '@hawks-ui/errors';

export const ScoutingRole = {
  Admin: 'scouting_admin',
  Super: 'scouting_super',
};

interface ContextProps {
  active: boolean;
  err?: HawksError;
  loading: boolean;
  logout: () => Promise<void>;
  user: string;
  userRoles: string[];
}

const Context = createContext<ContextProps>(null!);

function SessionProvider({ children }: React.PropsWithChildren) {
  const { bbauth } = useApi();

  const [err, setErr] = useState<HawksError>();
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState('');
  const [userRoles, setUserRoles] = useState<string[]>([]);

  const logout = async () => {
    setLoading(true);
    setActive(false);

    try {
      const res = await bbauth.api.oauth().logout();
      window.location.href = res.url;
    } catch (e) {
      console.error('failed to logout', e);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const { isLoggedIn } = await bbauth.api.oauth().loginEnd(window.location.href);
        setActive(isLoggedIn);

        if (isLoggedIn) {
          try {
            const claims = await bbauth.api.oauth().claims();
            setUser(claims.email || claims.sub);

            try {
              const permission = await bbauth.api.gateway().permission();
              setUserRoles(permission.roles);
            } catch (e) {
              console.warn('failed to get user permission', e);
              const err = HawksError.make(e);
              if (err.code === 'not_authenticated') {
                setActive(false);
              }
            }
          } catch (e) {
            console.warn('failed to get user claims', e);
          }
        }
      } catch (e) {
        setErr(HawksError.make(e));
      } finally {
        window.history.replaceState({}, window.document.title, window.location.pathname);
        setLoading(false);
      }
    })();
  }, [bbauth]);

  return (
    <Context.Provider
      value={{
        active,
        err,
        loading,
        logout,
        user,
        userRoles,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default SessionProvider;

export function useSession() {
  return useContext(Context);
}
