import { FaCheckCircle, FaExclamationCircle, FaLock } from 'react-icons/fa';

import { scouting } from '@atlanta-hawks/bbops-client-js';
import styled from '@emotion/styled';

import { Heading, Text } from '../../../components/typography';
import { checkRanks, useScouting } from '../../../contexts/scouting';
import { useMemo } from 'react';

const STATUS_COLLECTED = 'Collected';
const STATUS_INCOMPLETE = 'Incomplete';
const STATUS_INELIGIBLE = 'Ineligible';
const STATUS_MISSING = 'Missing';
const STATUS_OK = 'OK';

const StyledRankingsChecklist = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const StyledHeadline = styled.div`
  height: 42px;
  flex: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledKicker = styled(Text)`
  flex: none;
  background: ${({ theme }) => theme.colors.builtins.grays.darker};
  color: white;
  padding: 0.309rem;
  border-radius: 4px;
`;

const StyledStatus = styled(Heading)<{ status?: 'greens' | 'yellows' }>`
  flex: 1;
  padding-top: 0.5rem;
  padding-${({ status }) => (status ? 'left' : 'right')}: 0.25rem;
  color: ${({ theme, status }) => (status ? theme.colors.builtins[status].base : 'black')};
  text-align: left;
  font-family: ${({ theme }) => theme.fonts.subheading};
  ${({ status }) => !status && { textAlign: 'right' }}
  white-space: nowrap;
`;

const StyledTable = styled.table`
  height: 100%;
  font-family: ${({ theme }) => theme.fonts.body.longForm};
  font-size: ${({ theme }) => theme.fontSizes.centi};

  th {
    font-weight: 700;
    text-decoration: underline;
    padding-bottom: 0.5rem;
  }
`;

const StyledRowHeader = styled.td`
  text-align: right;
`;

const StyledIconOK = styled(FaCheckCircle)`
  height: 28px;
  width: 28px;
  color: ${({ theme }) => theme.colors.builtins.greens.base};
`;

const StyledIconIncomplete = styled(FaExclamationCircle)`
  height: 28px;
  width: 28px;
  color: ${({ theme }) => theme.colors.builtins.yellows.base};
`;

const StyledIconCollected = styled(FaLock)`
  height: 28px;
  width: 28px;
  color: ${({ theme }) => theme.colors.builtins.grays.lighterer};
`;

const StyledRowValue = styled.td<{ ok?: boolean; subtle?: boolean }>`
  color: ${({ theme, ok, subtle }) =>
    ok && !subtle
      ? theme.colors.team.current.white
      : theme.colors.builtins[subtle ? 'reds' : ok === false ? 'yellows' : 'text'].base};
  ${({ theme, ok, subtle }) => ok && !subtle && { backgroundColor: theme.colors.builtins.greens.base, fontWeight: 700 }}
  font-family: ${({ theme }) => theme.fonts.code};
  text-align: center;
`;

interface StyledCountValueProps {
  count: number;
  required: number;
}

function StyledCountValue({ count, required }: StyledCountValueProps) {
  return (
    <StyledRowValue ok={count >= required} subtle={!required}>
      {count}
    </StyledRowValue>
  );
}

function RankingsChecklist() {
  const { playersById, season, ranks, nextSubmitDeadline } = useScouting();

  const ranksSize = nextSubmitDeadline?.size || 0;
  const minPG = nextSubmitDeadline?.positions?.pg || 0;
  const minSG = nextSubmitDeadline?.positions?.sg || 0;
  const minSF = nextSubmitDeadline?.positions?.sf || 0;
  const minPF = nextSubmitDeadline?.positions?.pf || 0;
  const minC = nextSubmitDeadline?.positions?.c || 0;

  const currentRanks: scouting.Rank[] = useMemo(() => ranks?.current || [], [ranks]);
  const currentRanksCheck = checkRanks(currentRanks, playersById, ranks?.playerMeta, nextSubmitDeadline);

  const submittedRanks: scouting.Rank[] = useMemo(
    () => ((ranks?.submitHistory || []).length ? ranks!.submitHistory[0].players : []),
    [ranks],
  );
  const submittedRanksCheck = checkRanks(submittedRanks, playersById, ranks?.playerMeta, nextSubmitDeadline);

  const effRanksCheck = submittedRanks.length > 0 ? submittedRanksCheck : currentRanksCheck;

  let status = '';
  if (nextSubmitDeadline) {
    if (nextSubmitDeadline.collected) {
      status = STATUS_COLLECTED;
    } else if (effRanksCheck.checks.ineligible) {
      status = `${STATUS_INELIGIBLE} Players`;
    } else if (effRanksCheck.checks.size && effRanksCheck.checks.positions && effRanksCheck.checks.outcomes) {
      status = STATUS_OK;
    } else if (!effRanksCheck.checks.size) {
      status = STATUS_INCOMPLETE;
    } else if (!effRanksCheck.checks.positions) {
      status = `${STATUS_MISSING} ${Object.entries(effRanksCheck.positions)
        .filter(([position, size]) => size < ((nextSubmitDeadline?.positions as any)[position] || 0))
        .reduce((acc, [pos]) => [...acc, pos], [] as string[])
        .map(pos => pos.toUpperCase())
        .join(', ')}`;
    } else {
      status = `${STATUS_MISSING} Outcomes`;
    }
  }

  return (
    <StyledRankingsChecklist>
      <StyledHeadline>
        {status === STATUS_OK && <StyledIconOK />}
        {(status === STATUS_INCOMPLETE || status.startsWith(STATUS_MISSING) || status.startsWith(STATUS_INELIGIBLE)) && (
          <StyledIconIncomplete />
        )}
        {status === STATUS_COLLECTED && <StyledIconCollected />}
        {!!nextSubmitDeadline && (
          <>
            <StyledStatus as="h2">Rankings:</StyledStatus>
            <StyledStatus
              as="h2"
              status={
                status === STATUS_INCOMPLETE || status.startsWith(STATUS_MISSING) || status.startsWith(STATUS_INELIGIBLE)
                  ? 'yellows'
                  : 'greens'
              }
            >
              {status}
            </StyledStatus>
          </>
        )}
      </StyledHeadline>
      <StyledKicker>{pickKicker(status, season, nextSubmitDeadline)}</StyledKicker>
      <StyledTable>
        <colgroup>
          <col span={1} width="16%" />
          <col span={1} width="14%" />
          <col span={1} width="14%" />
          <col span={1} width="14%" />
          <col span={1} width="14%" />
          <col span={1} width="14%" />
          <col span={1} width="14%" />
        </colgroup>
        <thead>
          <tr>
            <th />
            <th>Total</th>
            <th>PG</th>
            <th>SG</th>
            <th>SF</th>
            <th>PF</th>
            <th>C</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <StyledRowHeader>Saved:</StyledRowHeader>
            <StyledCountValue count={currentRanks.length} required={ranksSize} />
            <StyledCountValue count={currentRanksCheck.positions.pg} required={minPG} />
            <StyledCountValue count={currentRanksCheck.positions.sg} required={minSG} />
            <StyledCountValue count={currentRanksCheck.positions.sf} required={minSF} />
            <StyledCountValue count={currentRanksCheck.positions.pf} required={minPF} />
            <StyledCountValue count={currentRanksCheck.positions.c} required={minC} />
          </tr>
          <tr>
            <StyledRowHeader>Submitted:</StyledRowHeader>
            <StyledCountValue count={submittedRanks.length} required={ranksSize} />
            <StyledCountValue count={submittedRanksCheck.positions.pg} required={minPG} />
            <StyledCountValue count={submittedRanksCheck.positions.sg} required={minSG} />
            <StyledCountValue count={submittedRanksCheck.positions.sf} required={minSF} />
            <StyledCountValue count={submittedRanksCheck.positions.pf} required={minPF} />
            <StyledCountValue count={submittedRanksCheck.positions.c} required={minC} />
          </tr>
          <tr>
            <StyledRowHeader>Outcomes set:</StyledRowHeader>
            <StyledCountValue count={effRanksCheck.outcomes.total} required={nextSubmitDeadline?.size || 0} />
            <StyledCountValue count={effRanksCheck.outcomes.pg} required={minPG} />
            <StyledCountValue count={effRanksCheck.outcomes.sg} required={minSG} />
            <StyledCountValue count={effRanksCheck.outcomes.sf} required={minSF} />
            <StyledCountValue count={effRanksCheck.outcomes.pf} required={minPF} />
            <StyledCountValue count={effRanksCheck.outcomes.c} required={minC} />
          </tr>
          <tr>
            <StyledRowHeader>Required:</StyledRowHeader>
            <StyledRowValue>{nextSubmitDeadline?.size || '--'}</StyledRowValue>
            <StyledRowValue>{minPG || '--'}</StyledRowValue>
            <StyledRowValue>{minSG || '--'}</StyledRowValue>
            <StyledRowValue>{minSF || '--'}</StyledRowValue>
            <StyledRowValue>{minPF || '--'}</StyledRowValue>
            <StyledRowValue>{minC || '--'}</StyledRowValue>
          </tr>
        </tbody>
      </StyledTable>
    </StyledRankingsChecklist>
  );
}

export default RankingsChecklist;

function pickKicker(status: string, season?: scouting.Season, nextSubmitDeadline?: scouting.SeasonSubmitDeadline): string {
  if (!season?.active) {
    return 'This scouting season is completed';
  }

  if (!season || !season.submitDeadlines.length) {
    return 'No due dates are set yet but feel free to start ranking your players';
  }

  if (!nextSubmitDeadline) {
    return 'All of the composite ranks for this season have been collected';
  }

  if (nextSubmitDeadline.collected) {
    return 'Your rankings have been collected, further changes will not be considered for this period';
  }

  if (status === STATUS_INCOMPLETE) {
    return 'Make sure to fulfill the below ranking requirements';
  }

  return '';
}
