import { useId } from 'react';

import styled from '@emotion/styled';

import { StyledArg } from '../../styles';

const styleDisabled: StyledArg<{ disabled?: boolean }> = ({ theme, disabled }) =>
  disabled && {
    backgroundColor: theme.colors.builtins.text.offwhite,
    cursor: 'not-allowed',
    '&:before': {
      backgroundColor: theme.colors.builtins.text.offwhite,
      cursor: 'not-allowed',
    },
  };

const StyledToggle = styled.div<{ tight?: boolean }>`
  position: relative;
  width: 75px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  ${({ tight }) =>
    tight && {
      width: '40px',
    }}
  @media screen and (max-width: 991px) {
    transform: scale(0.9);
  }
  @media screen and (max-width: 767px) {
    transform: scale(0.825);
  }
  @media screen and (max-width: 575px) {
    transform: scale(0.75);
  }
`;

const StyledToggleDisplay = styled.label<{ disabled?: boolean }>`
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
  margin: 0;
  border: 0 solid ${({ theme, disabled }) => theme.colors.builtins.grays.base};
`;

const StyledToggleInput = styled.input`
  display: none;
`;

const StyledToggleDisplayLabel = styled.span<{
  checked?: boolean;
  disabled?: boolean;
  no?: string;
  tight?: boolean;
  yes?: string;
}>`
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;

  &:before,
  &:after {
    display: block;
    float: left;
    width: 50%;
    height: 34px;
    padding: 0;
    line-height: 34px;
    font-size: 14px;
    color: white;
    font-weight: bold;
    box-sizing: border-box;
  }

  &:before {
    content: attr(data-yes);
    text-transform: uppercase;
    padding-left: 10px;
    background-color: ${({ theme }) => theme.colors.builtins.reds.base};
    color: white;
  }

  &:after {
    content: attr(data-no);
    text-transform: uppercase;
    padding-right: 10px;
    text-align: right;
    background-color: ${({ theme, disabled }) => theme.colors.builtins.grays[disabled ? 'lighterer' : 'base']};
    color: white;
  }
  ${styleDisabled}
  ${({ checked }) => checked && { marginLeft: 0 }}
	${({ tight }) =>
    tight && {
      '&:after, &:before': {
        content: '""',
        height: '20px',
        lineHeight: '20px',
      },
    }}
`;

const StyledToggleDisplaySwitch = styled.span<{ checked?: boolean; disabled?: boolean; tight?: boolean }>`
  display: block;
  width: 24px;
  height: 24px;
  margin: 5px;
  background: white;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 40px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
  ${styleDisabled}
  ${({ tight }) =>
    tight && {
      width: '16px',
      height: '16px',
      right: '20px',
      margin: '2px',
    }}
	${({ checked }) => checked && { right: '0px' }}
	border: 0 solid ${({ theme }) => theme.colors.builtins.grays.base};
`;

const StyledToggleKicker = styled.label<{ tight?: boolean }>`
  margin-top: 0.309rem;
  font-family: ${({ theme }) => theme.fonts.body.shortForm};
  font-size: ${({ theme, tight }) => theme.fontSizes[tight ? 'base' : 'kilo']};
  font-weight: 700;
`;

interface Props {
  checked?: boolean;
  disabled?: boolean;
  kicker?: string;
  labels?: { no?: string; yes?: string };
  onChange?: (checked: boolean) => void;
  tight?: boolean;
}

function Toggle({ checked, disabled, kicker, labels = {}, onChange = () => {}, tight }: Props) {
  const id = useId();

  const handleKeyPress = (e: React.KeyboardEvent<HTMLLabelElement>) => {
    e.preventDefault();
    if (e.key !== ' ') {
      return;
    }
    onChange(!checked);
  };

  return (
    <>
      <StyledToggle tight={tight}>
        <StyledToggleInput
          id={id}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={e => onChange(e.target.checked)}
        />
        <StyledToggleDisplay htmlFor={id} tabIndex={disabled ? -1 : 1} disabled={disabled} onKeyDown={handleKeyPress}>
          <StyledToggleDisplayLabel
            tabIndex={-1}
            checked={checked}
            disabled={disabled}
            data-no={labels.no || 'NO'}
            data-yes={labels.yes || 'YES'}
            tight={tight}
          />
          <StyledToggleDisplaySwitch tabIndex={-1} checked={checked} disabled={disabled} tight={tight} />
        </StyledToggleDisplay>
      </StyledToggle>
      {kicker && <StyledToggleKicker tight={tight}>{kicker}</StyledToggleKicker>}
    </>
  );
}

export default Toggle;
