import { css } from '@emotion/react';
import styled from '@emotion/styled';

import styles from './styles';

const StyledNavAnchor = styled.a<{ center: boolean; noPadding: boolean }>(({ theme, center, noPadding }) => [
  styles.navItem(theme),
  noPadding &&
    css`
      padding: 0;
    `,
  css`
    display: block;
    text-decoration: none;
  `,
  css`
    align-self: ${center ? 'center' : 'self'};
  `,
]);

interface Props {
  center?: boolean;
  children?: React.ReactNode;
  href: string;
  noPadding?: boolean;
  sameTab?: boolean;
}

function NavAnchor({ center = false, children, href, noPadding = false, sameTab }: Props) {
  const props: Record<string, string> = {};
  if (!sameTab) {
    props['rel'] = 'nofollow noreferrer';
    props['target'] = '_blank';
  }
  return (
    <StyledNavAnchor center={center} href={href} noPadding={noPadding} {...props}>
      {children}
    </StyledNavAnchor>
  );
}

export default NavAnchor;
