import { useLocation, useNavigate } from 'react-router-dom';

import styled from '@emotion/styled';

import { OutlineButton } from '../components/buttons';
import { Text } from '../components/typography';

const StyledPage = styled.div`
  margin: 1rem auto;
  height: 100%;
  width: 777px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledButton = styled(OutlineButton)`
  margin: 1rem auto;
  width: 120px;
  height: 72px;
  font-size: ${({ theme }) => theme.fontSizes.kilo};
`;

function RestrictedPage() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <StyledPage>
      <div>
        <Text>
          you have tried to access a page that you are not permitted to view at this time. please contact your administrator if
          you think this is in error
        </Text>
        <Text>
          otherwise use the back button on your browser to return to safety. apologies for the trouble here, have a great day!
        </Text>
      </div>
      <StyledButton
        onClick={() => {
          if (location.state.from) {
            navigate(location.state.from);
          } else {
            navigate(-1);
          }
        }}
      >
        Back to Safety
      </StyledButton>
    </StyledPage>
  );
}

export default RestrictedPage;
