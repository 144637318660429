import { useCallback, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';

import { scouting } from '@atlanta-hawks/bbops-client-js';
import styled from '@emotion/styled';

import { Text } from '../../../components/typography';
import { useScouting } from '../../../contexts/scouting';
import { isDate } from '../../../core';

import Toolbar from './collect_and_publish_toolbar';
import Table from './collect_and_publish_table';
import { ScoutRanks, TodayIs } from './types';

const StyledWorkspace = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledWorkspaceEmptyState = styled.div`
  padding: 0 1rem;
`;

function CollectAndPublish() {
  const { api, currentSeason, today, season, playersById, seasonFetch } = useScouting();

  const checkToday = isDate(today);

  const [selectedDeadline, setSelectedDeadline] = useState<scouting.SeasonSubmitDeadline>();
  const [todayIs, setTodayIs] = useState<TodayIs>();

  const [allRanks, setAllRanks] = useState<ScoutRanks[]>([]);

  const refreshRanks = useCallback(
    () =>
      Promise.all([api.admin().ranks().getAll(), api.pastRanks().get()]).then(([allRanks, allPastRanks]) => {
        const pastRanks: Record<string, scouting.PastRanks> = (
          selectedDeadline ? allPastRanks.filter(ranks => ranks.period === selectedDeadline.period) : []
        ).reduce((acc, ranks) => ({ ...acc, [ranks.email]: ranks }), {});

        setAllRanks(
          allRanks.map(active => {
            const past = pastRanks[active.email];

            const prevPast = (
              selectedDeadline && selectedDeadline.period > 1
                ? allPastRanks.filter(ranks => ranks.period === selectedDeadline.period - 1)
                : []
            ).find(r => r.email === active.email);

            let prevPastSame: boolean;
            if (!past || !prevPast) {
              prevPastSame = false;
            } else {
              if (!past.players.length || past.players.length !== prevPast.players.length) {
                prevPastSame = false;
              } else {
                prevPastSame = true;
                for (let i = 0; i < past.players.length; i++) {
                  const pastRank = past.players[i];
                  const prevPastRank = prevPast.players[i];
                  if (pastRank.playerId !== prevPastRank.playerId) {
                    prevPastSame = false;
                    break;
                  }
                }
              }
            }

            return {
              active: active,
              past,
              prevPastSame,
            };
          }),
        );
      }),
    [api, setAllRanks, selectedDeadline],
  );

  useEffect(() => {
    (async () => await refreshRanks())();
  }, [refreshRanks]);

  useEffect(() => {
    if (selectedDeadline) {
      let todayIs: TodayIs = 'after';
      if (checkToday.same(selectedDeadline.date)) {
        todayIs = 'same';
      } else if (checkToday.before(selectedDeadline.date)) {
        todayIs = 'before';
      }
      setTodayIs(todayIs);
    }
  }, [checkToday, selectedDeadline]);

  useEffect(() => {
    if (season && selectedDeadline) {
      setSelectedDeadline(season.submitDeadlines.find(deadline => deadline.period === selectedDeadline.period));
    }
  }, [season, selectedDeadline]);

  if (!season) {
    return null;
  }

  return (
    <>
      <Toolbar
        patchSeason={req => api.admin().season().patch(req).then(seasonFetch)}
        season={season}
        submitDeadline={selectedDeadline}
        setSubmitDeadline={setSelectedDeadline}
        todayIs={todayIs}
      />
      <StyledWorkspace>
        <StyledWorkspaceEmptyState>
          {!selectedDeadline && <Text>Select a submission period from the dropdown above.</Text>}
          {selectedDeadline && !allRanks.length && <Text>No ranks have been collected for this period yet...</Text>}
        </StyledWorkspaceEmptyState>
        {selectedDeadline && (
          <Table
            allRanks={allRanks}
            collectPastRanks={() => api.admin().pastRanks().collect(selectedDeadline.period)}
            currentSeason={currentSeason}
            deletePastRanks={api.admin().pastRanks().delete}
            playersById={playersById}
            refreshRanks={refreshRanks}
            savePastRanks={api.admin().pastRanks().save}
            season={season.seasonYear}
            submitDeadline={selectedDeadline}
            todayIs={todayIs}
          />
        )}
      </StyledWorkspace>
      <Tooltip id="collect-and-publish-tooltip" place="right" />
    </>
  );
}

export default CollectAndPublish;
