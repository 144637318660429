import styled from '@emotion/styled';

import { LinkButton } from '../components/buttons';
import { ScoutingRole, useSession } from '../contexts/session';

import urls from '../urls';

const StyledLinks = styled.div`
  height: 25px;
  display: flex;
  align-items: baseline;
  border-bottom: 1px solid ${({ theme }) => theme.colors.builtins.reds.base};

  a {
    font-family: ${({ theme }) => theme.fonts.subheading};
  }
`;

function ScoutingLinks() {
  const { userRoles } = useSession();

  const isAdmin = userRoles.some(g => g === ScoutingRole.Admin || g === ScoutingRole.Super);
  return (
    <StyledLinks>
      <LinkButton to={urls.ranks.url} shade="reds" nomargin>
        My rankings
      </LinkButton>
      <LinkButton to={urls.results.url} shade="reds" nomargin>
        Composite rankings
      </LinkButton>
      {isAdmin && (
        <LinkButton to={urls.admin.url} shade="reds" nomargin>
          Admin Dashboard
        </LinkButton>
      )}
    </StyledLinks>
  );
}

export default ScoutingLinks;
