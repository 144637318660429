import { css } from '@emotion/react';

import { Theme } from '../../styles';

const GUTTER = 0.3;
const HEIGHT = 50;

const styles = {
  navbar: () => css`
    max-height: ${HEIGHT}px;
    max-width: 1280px;
    width: 70%; // TODO: width: 70% ... @ min-width 993px, 85% @ 601px ... maybe load "bootstrap styles" somehow?
    margin: 0 auto;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
  `,
  navFloatingItem: () => css`
    position: relative;
    font-size: 14px;
    white-space: nowrap;
    display: flex;
    align-items: stretch;

    & > a,
    & > button {
      text-align: left;
      padding: ${GUTTER}rem ${2 * GUTTER}rem;
    }
  `,

  navItem: (theme: Theme) => css`
    color: ${theme.colors.builtins.grays.base};
    font-family: ${theme.fonts.body.shortForm};
    font-size: 14px;
    padding: ${GUTTER}rem ${2 * GUTTER}rem;

    &:hover {
      background-color: #f2f2f2;
    }
  `,
  navLabel: ({ theme }: { theme: Theme }) => css`
    display: block;
    color: ${theme.colors.builtins.grays.lighter};
    font-family: ${theme.fonts.body.shortForm};
    font-size: 12px;
    line-height: 1.42857;
    white-space: nowrap;
    padding: ${GUTTER}rem ${3 * GUTTER}rem;
    margin: 0;
    cursor: not-allowed;
    pointer-events: none;
  `,
};

export default styles;
