import styled from '@emotion/styled';

import { Text } from '../../../components/typography';

const StyledText = styled(Text)<{ shade: 'grays' | 'reds' | 'blues' }>`
  color: ${({ theme, shade }) => theme.colors.builtins[shade].base};
`;

interface Props {
  value: number;
}

function RankDelta({ value }: Props) {
  if (!value) {
    return <StyledText shade="grays">–</StyledText>;
  }
  const magnitude = Math.abs(value);
  const direction = value > 0 ? 'up' : ('down' as const);
  return (
    <StyledText shade={direction === 'up' ? 'reds' : 'blues'}>
      {direction === 'up' ? '⬆' : '⬇'}&nbsp;{magnitude}
    </StyledText>
  );
}

export default RankDelta;
