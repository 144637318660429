import { useState } from 'react';

import { scouting } from '@atlanta-hawks/bbops-client-js';
import styled from '@emotion/styled';

import { TableData } from '../../../components/table';
import { playerAge } from '../../../contexts/scouting';

const StyledPlayerName = styled(TableData)`
  position: relative;
  user-select: none;
`;

const StyledPlayerId = styled.span`
  position: absolute;
  right: 0;
  user-select: all;
`;

interface Props {
  player: scouting.Player;
  seasonYear: number;
}

function ManagePlayerPool({ player, seasonYear }: Props) {
  const [hovered, setHovered] = useState(false);
  return (
    <tr onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      <TableData>{player.ineligible ? '--' : player.tier}</TableData>
      <StyledPlayerName>
        <>
          {player.name}
          {hovered && <StyledPlayerId>{player.dxPlayerId}</StyledPlayerId>}
        </>
      </StyledPlayerName>
      <TableData>{player.position}</TableData>
      <TableData>{player.team}</TableData>
      <TableData>{playerAge(seasonYear, player.dob)}</TableData>
    </tr>
  );
}

export default ManagePlayerPool;
