import { Link } from 'react-router-dom';

import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import styles from './styles';

const StyledNavLink = styled(Link, { shouldForwardProp: isPropValid })<{ active: boolean; center: boolean }>(
  ({ theme, active, center }) => [
    styles.navItem(theme),
    css`
      display: block;
      text-decoration: none;
    `,
    active &&
      css`
        background-color: ${theme.colors.builtins.grays.darker};
        color: white;
        flex: 1;

        &:hover {
          background-color: ${theme.colors.builtins.grays.darker};
          color: white;
        }
      `,
    css`
      align-self: ${center ? 'center' : 'self'};
    `,
  ],
);

interface Props {
  active?: boolean;
  center?: boolean;
  children?: React.ReactNode;
  to: string;
}

function NavLink({ active = false, center = false, children, to }: Props) {
  return (
    <StyledNavLink active={active} center={center} to={to}>
      {children}
    </StyledNavLink>
  );
}

export default NavLink;
