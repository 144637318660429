import { useEffect, useState } from 'react';

import styled from '@emotion/styled';

const StyledHeightPreservingItem = styled.div`
  &:empty {
    min-height: calc(var(--child-height));
    box-sizing: border-box;
  }
`;

const HeightPreservingItem = ({ children, ...rest }: any) => {
  const knownSize = rest['data-known-size'];

  const [size, setSize] = useState(0);
  useEffect(() => {
    setSize(prevSize => {
      return knownSize === 0 ? prevSize : knownSize;
    });
  }, [knownSize]);

  return (
    <StyledHeightPreservingItem {...rest} style={{ '--child-height': `${size}px` }}>
      {children}
    </StyledHeightPreservingItem>
  );
};

export default HeightPreservingItem;
