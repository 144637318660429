import styled from '@emotion/styled';

import { Text } from '../../../components/typography';
import { useScouting } from '../../../contexts/scouting';

import EmptyState from './landing_empty_state';
import DeadlineManager from './deadline_manager';

const StyledKicker = styled.div`
  margin: 0 auto;
  padding: 1rem;
  background: ${({ theme }) => theme.colors.builtins.grays.lightest};
  border-radius: 8px;
  margin-bottom: 1rem;

  @media only screen and (max-width: 576px) {
    width: 100%;
  }

  @media only screen and (min-width: 576px) {
    width: 576px;
  }
`;

function AdminLanding() {
  const { initialized, season } = useScouting();

  if (!initialized) {
    return null;
  }

  if (!season) {
    return <EmptyState />;
  }

  return (
    <>
      <StyledKicker>
        <Text>Use this dashboard to help manage the distribution of scouts' ranks for the current scouting season.</Text>
        <Text>
          You can add and remove submit deadlines as well as configure the submission period to have a required minimum number
          of overall ranks or by position.
        </Text>
        <Text>You can add and remove scouts' rankings or modify them as need be.</Text>
        <Text nomargin>
          On the submit deadline date, you can collect the scouts' ranks at any point in time. After collecting ranks for a
          submission period, you may publish any subset of those ranks to a named aggregate set.
        </Text>
      </StyledKicker>
      <DeadlineManager />
    </>
  );
}

export default AdminLanding;
