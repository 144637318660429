import { useRef, useState } from 'react';
import { FaCaretRight } from 'react-icons/fa';

import styled from '@emotion/styled';

import NavButton from './nav_button';
import NavPopover from './nav_popover';

const StyledNavMenu = styled.div`
  position: relative;
  flex: 1;
`;

interface Props {
  label: string;
  children?: React.ReactNode;
}

function NavMenu({ children, label }: Props) {
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  return (
    <StyledNavMenu onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
      <NavButton align="split" expanded={show} onClick={() => setShow(curr => !curr)}>
        {label}
        <FaCaretRight />
      </NavButton>
      <NavPopover show={show} ref={ref} position="on_right">
        {children}
      </NavPopover>
    </StyledNavMenu>
  );
}

export default NavMenu;
