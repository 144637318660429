import { FaTimes } from 'react-icons/fa';

import { scouting } from '@atlanta-hawks/bbops-client-js';
import styled from '@emotion/styled';

import { IconButton } from '../../../components/buttons';
import Table, { TableData, TableHeader } from '../../../components/table';
import { Text } from '../../../components/typography';
import { scoutDisplay } from '../../../contexts/scouting';

const StyledTableData = styled(TableData)`
  white-space: nowrap;
`;

interface Props {
  allRanks: scouting.Ranks[];
}

function ScoutsRanksStateTable({ allRanks }: Props) {
  return (
    <div style={{ minWidth: '480px' }}>
      <Table
        floatHeader
        header={
          <tr>
            <TableHeader fit="min" />
            <TableHeader>Scout</TableHeader>
            <TableHeader>Current</TableHeader>
            <TableHeader>Submitted</TableHeader>
            <TableHeader>Started on</TableHeader>
            <TableHeader>Last updated</TableHeader>
          </tr>
        }
      >
        {allRanks.map((ranks, i) => {
          return (
            <tr key={ranks.id} style={{ height: '40px' }}>
              <TableData fit="min">
                <IconButton tight shade="grays" onClick={() => console.log('TODO: delete scouts ranks', ranks)}>
                  <FaTimes />
                </IconButton>
              </TableData>
              <StyledTableData>
                <Text>{scoutDisplay(ranks.email)}</Text>
              </StyledTableData>
              <TableData>{ranks.current.length}</TableData>
              <TableData>{!ranks.submitHistory.length ? 'No' : `${ranks.submitHistory[0].players.length} players`}</TableData>
              <td>{ranks.createdOn.toDateString()}</td>
              <td>{ranks.updatedOn.getTime() === 0 ? '--' : ranks.updatedOn.toDateString()}</td>
            </tr>
          );
        })}
      </Table>
    </div>
  );
}

export default ScoutsRanksStateTable;
