import styled from '@emotion/styled';

import { Text } from '../components/typography';
import { useScouting } from '../contexts/scouting';

const StyledFooter = styled.footer`
  flex: none;
  background-color: ${({ theme }) => theme.colors.site.navbar.bg};
  border-color: ${({ theme }) => theme.colors.site.navbar.border};
  border-width: 0 0 1px;
  height: 50px;
  width: 100%;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
`;

const StyledRuntime = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4px;
`;

const StyledRuntimeHeader = styled(Text)`
  text-align: right;
  font-size: ${({ theme }) => theme.fontSizes.centi};
`;

const StyledRuntimeValue = styled(Text)`
  color: ${({ theme }) => theme.colors.builtins.grays.lighter};
  font-size: ${({ theme }) => theme.fontSizes.centi};
`;

function Footer() {
  const { runtime } = useScouting();
  return (
    <StyledFooter>
      <Text>{process.env.NODE_ENV !== 'production' && `Env: ${process.env.NODE_ENV}`}</Text>
      <StyledRuntime>
        <StyledRuntimeHeader nomargin>Server up since:</StyledRuntimeHeader>
        <StyledRuntimeValue nomargin>{runtime.buildTime}</StyledRuntimeValue>
        <StyledRuntimeHeader nomargin>Server version:</StyledRuntimeHeader>
        <a href={`https://github.com/atlanta-hawks/bbops/commit/${runtime.gitHash}`} target="_blank" rel="nofollow noreferrer">
          <StyledRuntimeValue nomargin>{runtime.gitHash}</StyledRuntimeValue>
        </a>
      </StyledRuntime>
    </StyledFooter>
  );
}

export default Footer;
