import { Children, forwardRef, isValidElement } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import styles from './styles';

const StyledNavList = styled.ul<{ show?: boolean }>`
  list-style: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
  flex: 1;
  display: flex;
  align-items: stretch;
`;

const NavListItem = styled.li<{ center: boolean }>(({ center }) => [
  styles.navFloatingItem,
  css`
    align-self: ${center ? 'center' : 'self'};
  `,
]);

interface DefaultProps {
  position: 'below' | 'on_right';
}

interface Props extends Partial<DefaultProps> {
  children?: React.ReactNode;
  show: boolean;
}

const NavList = forwardRef<HTMLUListElement, Props>(({ children, show }, ref) => {
  return (
    <StyledNavList show={show} ref={ref}>
      {Children.map(children, child => {
        let center = false;
        if (isValidElement(child)) {
          if (child.props['alignMiddle']) {
            center = true;
          }
        }
        return <NavListItem center={center}>{child}</NavListItem>;
      })}
    </StyledNavList>
  );
});

const defaultProps: DefaultProps = {
  position: 'below',
};
NavList.defaultProps = defaultProps;

export default NavList;
