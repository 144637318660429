import styled from '@emotion/styled';

import { OutlineButton } from '../buttons';
import { Subheading, Text } from '../typography';

const StyledPlayerEmptyState = styled.div`
  padding-top: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const StyledOr = styled(Subheading)`
  margin: 1rem 0;
`;

const StyledImportButton = styled(OutlineButton)`
  margin: 0.5rem 0;
  align-self: center;
`;

const StyledNote = styled.div`
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.builtins.grays.lightest};
  text-align: left;
  width: 62.8%;
  margin: 0 auto;
  padding: 0.5rem 1rem;
`;

const StyledNoteEmphasis = styled.span`
  font-weight: 700;
`;

interface Props {
  importRanks: () => Promise<void>;
}

function RanksImporter({ importRanks }: Props) {
  return (
    <StyledPlayerEmptyState>
      <Text nomargin>Start dragging players into this list to being your rankings.</Text>
      <StyledOr nomargin>OR</StyledOr>
      <Text nomargin>You can import your rankings from the previous season.</Text>
      <StyledImportButton shade="reds" onClick={() => importRanks().catch(e => console.error('failed to import ranks', e))}>
        Import rankings
      </StyledImportButton>
      <StyledNote>
        <Text nomargin>
          NOTE: The players that are no longer included in this current season's player pool{' '}
          <StyledNoteEmphasis>will not be carried over</StyledNoteEmphasis> from your previous rankings.
        </Text>
      </StyledNote>
    </StyledPlayerEmptyState>
  );
}

export default RanksImporter;
